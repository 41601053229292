import { Http } from 'src/api/Http';
import { UuidResponse } from 'src/types';

// TODO: padaryti abstracius read only metodus filter, get{model}, get{model}{media} interface clienta su base Read metodais
class Client {
  public http: Http;

  // TODO: get rid of this!
  public api: { [key: string]: { [key: string]: string } };

  constructor(http: Http) {
    this.http = http;

    this.api = {
      AUTH: {
        SWITCH_COMPANY: `${this.http.baseUrl}/account/company`,
      },
      USER: {
        GET_CURRENT_ACCOUNT: `${this.http.baseUrl}/user/account`,
        UPDATE_CURRENT_ACCOUNT: `${this.http.baseUrl}/user/account`,
        CHANGE_CURRENT_ACCOUNT_PASSWORD: `${this.http.baseUrl}/user/change-password`,
      },
      COMPANY: {
        GET_CURRENT_COMPANY: `${this.http.baseUrl}/company/company`,
        UPDATE_CURRENT_COMPANY: `${this.http.baseUrl}/company/company`,
        CREATE_BRAND: `${this.http.baseUrl}/company/brands/brand`,
        CREATE_TEMPORARY_BRAND: `${this.http.baseUrl}/company/brands/brand/temporary`,
        UPDATE_BRAND: `${this.http.baseUrl}/company/brands/brand/{brand_id}`,
        FETCH_BRAND_ASSET: `${this.http.baseUrl}/company/brands/brand/{brand_id}/asset/{filename}`,
        UPLOAD_BRAND_ASSET: `${this.http.baseUrl}/company/brands/brand/{brand_id}/asset`,
        COMPLETE_BRAND_ASSET_UPLOAD: `${this.http.baseUrl}/company/brands/brand/{brand_id}/asset/{filename}`,
        GET_BRAND: `${this.http.baseUrl}/company/brands/brand/{brand_id}`,
        DELETE_BRAND: `${this.http.baseUrl}/company/brands/brand/{brand_id}`,
        GET_BRANDS: `${this.http.baseUrl}/company/brands`,
      },
      REQUEST: {
        FETCH_REQUESTS: `${this.http.baseUrl}/requests`,

        CREATE_TEMPORARY_BRAND: `${this.http.baseUrl}/requests/request/temporary`,
        FETCH_REQUEST: `${this.http.baseUrl}/requests/request/{request_id}`,
        DELETE_REQUEST: `${this.http.baseUrl}/requests/request/{request_id}`,

        FETCH_REQUEST_ASSET: `${this.http.baseUrl}/requests/request/{request_id}/asset/{filename}`,
        UPLOAD_REQUEST_ASSET: `${this.http.baseUrl}/requests/request/{request_id}/asset`,
        CHANGE_REQUEST_STATUS: `${this.http.baseUrl}/requests/request/{request_id}/change_status`,
        COMPLETE_REQUEST_ASSET_UPLOAD: `${this.http.baseUrl}/requests/request/{request_id}/asset/{filename}`,

        FETCH_REQUEST_MEDIA_FOOTAGE: `${this.http.baseUrl}/requests/request/{request_id}/media/{filename}`,
        UPLOAD_REQUEST_MEDIA_FOOTAGE: `${this.http.baseUrl}/requests/request/{request_id}/media`,
        COMPLETE_REQUEST_MEDIA_FOOTAGE_UPLOAD: `${this.http.baseUrl}/requests/request/{request_id}/media/{filename}`,
      },
      USER_EVENT: {
        STORE: `${this.http.baseUrl}/user_event/store`,
      },
    };
  }

  generateUuid(): Promise<UuidResponse> {
    return this.http.get(`${this.http.baseUrl}/generate_uuid`);
  }

  buildUrl = (url: string, params: { [key: string]: string }): string => {
    for (const key in params) {
      const value = params[key];
      url = url.replace('{' + key + '}', value);
    }

    return url;
  };
}

export default Client;
