import jwt_decode from 'jwt-decode';
import { LocalStorage } from 'src/services/LocalStorage';
import { AuthJwtObject, JwtInterface, jwtStorageKey } from './Auth.types';
import { isAuthJwtObject } from './Auth.guards';

class AuthUtils {
  private static readonly defaultState: AuthJwtObject = {
    token: '',
    expires: 0,
  };

  public static getJwt = (): AuthJwtObject => {
    const jwt = LocalStorage.get(jwtStorageKey, true);

    if (isAuthJwtObject(jwt)) {
      return jwt;
    }

    return AuthUtils.defaultState;
  };

  public static setJwt = (token: string | AuthJwtObject): void => {
    if (isAuthJwtObject(token)) {
      LocalStorage.set(jwtStorageKey, token);
      return;
    }

    LocalStorage.set(jwtStorageKey, AuthUtils.make(token));
  };

  public static removeJwt = (): void => {
    LocalStorage.remove(jwtStorageKey);
  };

  public static isExpired = (jwt?: AuthJwtObject): boolean => {
    return Date.now() > (jwt?.expires ?? AuthUtils.getJwt().expires);
  };

  public static getPayload = (): null | JwtInterface => {
    if (AuthUtils.isExpired()) {
      return null;
    }

    return AuthUtils.decode(AuthUtils.getJwt().token);
  };

  public static subscriber(): string {
    return AuthUtils.getPayload()?.sub ?? '';
  }

  public static decode = (token: string): JwtInterface => jwt_decode<JwtInterface>(token);

  public static make = (token: string): AuthJwtObject => ({
    token,
    expires: AuthUtils.decode(token).exp * 1000,
  });
}

export { AuthUtils };
