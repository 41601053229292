import * as React from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'src/lib/utils';

const avatarVariants = cva(
  'tw-relative tw-flex tw-shrink-0 tw-overflow-hidden tw-rounded-full tw-font-medium',
  {
    variants: {
      size: {
        default: 'tw-h-10 tw-w-10',
        xs: 'tw-h-6 tw-w-6 tw-text-xs',
        sm: 'tw-h-7 tw-w-7',
        lg: 'tw-h-12 tw-w-12',
        xl: 'tw-h-30 tw-w-30',
        xxl: 'tw-h-[12.5rem] tw-w-[12.5rem]',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
);

type AvatarProps = React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> &
  VariantProps<typeof avatarVariants>;

const Avatar = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, AvatarProps>(
  ({ size, className, ...props }, ref) => (
    <AvatarPrimitive.Root
      ref={ref}
      className={cn(avatarVariants({ size, className }))}
      {...props}
    />
  ),
);
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn('tw-aspect-square tw-h-full tw-w-full', className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-full tw-uppercase',
      className,
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };
