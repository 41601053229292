import React from 'react';
import { GoogleTagManager } from 'src/services/GoogleTagManager';
import { ReactQueryClientProvider } from './ReactQueryClientProvider';
import { ReactCookiesProvider } from './ReactCookiesProvider';
import { ReactToastProvider } from './ReactToastProvider';
import { ThemeProvider } from './ThemeProvider';
import 'src/lib/services/meta-pixel';
import { PostHogProvider } from 'src/providers/posthog';
import { canny } from 'src/lib/services/canny';
import { stripe } from 'src/lib/services/stripe';
import { hubspot } from 'src/lib/services/hubspot';

GoogleTagManager.init();
canny.load().catch((e) => console.error('Failed to load canny sdk', e));
stripe.load().catch((e) => console.error('Failed to load stripe sdk', e));
hubspot.load().catch((e) => console.error('Failed to load hubspot sdk', e));

type Props = React.PropsWithChildren;

const Providers: React.FC<Props> = (props) => {
  return (
    <PostHogProvider>
      <ReactToastProvider />

      <ReactQueryClientProvider>
        <ReactCookiesProvider>
          <ThemeProvider>{props.children}</ThemeProvider>
        </ReactCookiesProvider>
      </ReactQueryClientProvider>
    </PostHogProvider>
  );
};

export default Providers;
