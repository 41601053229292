import { AxiosRequestConfig } from 'axios';
import { httpConfig } from 'src/lib/modules/http';
import { Api } from 'src/api';
import {
  CancelSubscriptionRequest,
  CreateStripeCustomerRequest,
  CreateStripeCustomerResponse,
  CreateSubscriptionRequest,
  PromotionCodeResponse,
  StripeIntentResponse,
  StripePaymentIntentRequest,
  StripeProductsResponse,
  SubscriptionObject,
  SubscriptionPlanChangeResponse,
  ValidatePromotionCodeRequest,
} from 'src/lib/services/api/subscription-api/subscription-client.types';
import { InterceptableAxiosRequestConfig } from 'src/api/Http/Http.types';
import { url } from 'src/lib/utils';

const baseUrl = `${httpConfig.options.baseURL}`;

const products = (config?: AxiosRequestConfig): Promise<StripeProductsResponse> =>
  Api.http.get(`${baseUrl}/stripe/products`, config);

const validatePromoCode = (
  request: ValidatePromotionCodeRequest,
  config?: InterceptableAxiosRequestConfig,
): Promise<PromotionCodeResponse> =>
  Api.http.post(`${baseUrl}/stripe/validate-promotion-code`, request, config);

const createStripeIntent = (
  request: StripePaymentIntentRequest,
  config?: AxiosRequestConfig,
): Promise<StripeIntentResponse> =>
  Api.http.post(`${baseUrl}/stripe/create-stripe-intent`, request, config);

const createPayment = (
  request: StripePaymentIntentRequest,
  config?: AxiosRequestConfig,
): Promise<StripeIntentResponse> =>
  Api.http.post(`${baseUrl}/stripe/create-payment`, request, config);

const createSubscription = (
  request: CreateSubscriptionRequest,
  config?: AxiosRequestConfig,
): Promise<SubscriptionObject> =>
  Api.http.post(`${baseUrl}/stripe/create-subscription`, request, config);

const createStripeCustomer = (
  request: CreateStripeCustomerRequest,
  config?: AxiosRequestConfig,
): Promise<CreateStripeCustomerResponse> =>
  Api.http.post(`${baseUrl}/stripe/create-stripe-customer`, request, config);

const abortPayment = (paymentId: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.post(`${baseUrl}/stripe/abort-payment`, { payment_id: paymentId }, config);

const cancel = (
  model: string,
  data: CancelSubscriptionRequest,
  config?: AxiosRequestConfig,
): Promise<SubscriptionObject> =>
  Api.http.delete(url.insertArguments(`${baseUrl}/subscriptions/{model}/cancel`, { model }), {
    data,
    ...config,
  });

const reactivate = (model: string, config?: AxiosRequestConfig): Promise<SubscriptionObject> =>
  Api.http.post(
    url.insertArguments(`${baseUrl}/subscriptions/{model}/reactivate`, { model }),
    config,
  );

const getUpgrade = (
  model: string,
  config?: AxiosRequestConfig,
): Promise<SubscriptionPlanChangeResponse> =>
  Api.http.get(`${baseUrl}/subscriptions/${model}/upgrade`, config);

const upgrade = (
  model: string,
  config?: AxiosRequestConfig,
): Promise<SubscriptionPlanChangeResponse> =>
  Api.http.post(`${baseUrl}/subscriptions/${model}/upgrade`, {}, config);

const getDowngrade = (
  model: string,
  config?: AxiosRequestConfig,
): Promise<SubscriptionPlanChangeResponse> =>
  Api.http.get(`${baseUrl}/subscriptions/${model}/downgrade`, config);

const scheduleDowngrade = (
  model: string,
  config?: AxiosRequestConfig,
): Promise<SubscriptionPlanChangeResponse> =>
  Api.http.post(`${baseUrl}/subscriptions/${model}/schedule-downgrade`, {}, config);

const cancelDowngrade = (model: string, config?: AxiosRequestConfig): Promise<SubscriptionObject> =>
  Api.http.post(`${baseUrl}/subscriptions/${model}/cancel-downgrade`, {}, config);

export {
  products,
  validatePromoCode,
  createStripeIntent,
  createPayment,
  createSubscription,
  createStripeCustomer,
  abortPayment,
  cancel,
  reactivate,
  getUpgrade,
  upgrade,
  getDowngrade,
  scheduleDowngrade,
  cancelDowngrade,
};
