import React, { useEffect, useRef } from 'react';
import {
  ChatInputContent,
  ChatInputFallback,
  ChatInputWrapper,
  ChatMessage,
  ChatMessageContentRow,
  ClockIcon,
  NavLink,
  StyledDropdownItem,
  Text,
} from '@becreatives/becreatives-ui';
import { If } from 'src/components/If';
import { getObjectKeys } from 'src/helpers/getObjectKeys';
import { MinimalMediaCard } from 'src/components/MediaCard';
import { VersionBadge } from './RevisionChatContent.styled';
import { OverridenBadge } from 'src/components/Badge';
import { RevisionChatMessageActionsDropdown } from './RevisionChatMessageActionsDropdown';
import {
  ChatInputCommentForm,
  ChatInputCommentFormikProvider,
  ChatInputCommentSelectedMedia,
} from './ChatInputCommentForm';
import { useTranslation } from 'react-i18next';
import { HrefLink } from 'src/components/Link';
import { useFormatter } from 'src/services/i18n/useFormatter';
import isNumber from 'lodash/isNumber';
import dompurify from 'dompurify';
import { useRequestCommentsContext } from 'src/features/comments/use-request-comments';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useRequestRevisionVideoPlayerGlobalStore } from 'src/features/revisions/use-request-revision-video-player-global-store';
import { shallow } from 'zustand/shallow';
import { formatTime } from 'src/features/video-player/video-player-utils';
import { Loader2 } from 'lucide-react';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { cn } from 'src/lib/utils';
import Str from 'src/helpers/Str';

type RevisionChatContentProps = React.ComponentProps<'div'>;

const { sanitize } = dompurify;

export const RevisionChatSection: React.FC<RevisionChatContentProps> = ({
  className,
  children,
  ...props
}) => (
  <div
    {...props}
    className={cn(
      'tw-relative tw-flex tw-min-w-0 tw-flex-col tw-gap-4 tw-rounded-xl tw-bg-white tw-shadow-sm',
      className,
    )}
  >
    {children}
  </div>
);

const RevisionChatContent: React.FC = () => {
  const { t } = useTranslation('pages/shared');
  const scrollRef = useRef<HTMLDivElement>(null);

  const pageStore = useRequestRevisionVideoPlayerGlobalStore(
    ({ pause, seek, timeFormat, setOnMount }) => ({ pause, seek, timeFormat, setOnMount }),
    shallow,
  );

  const request = useDecoratedRequestContext();
  const {
    revisions,
    selectedRevision,
    selectedRevisionMedia,
    selectLatest,
    setSelectedRevisionMedia,
    setSelectedRevision,
  } = useRequestRevisionsStore();

  const {
    comments,
    isLoadingMore,
    canLoadMore,
    removeCommentMutation,
    fetchNextPage,
    hasComments,
  } = useRequestCommentsContext();

  const { formatDateTime } = useFormatter();

  useEffect(() => {
    const timeout = setTimeout(() => scrollToBottom(), 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const scrollToBottom = () => {
    if (!scrollRef.current || !scrollRef.current?.scrollHeight) {
      return;
    }

    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  };

  const revisionsMap = revisions
    .sort((a, b) => new Date(b.raw.created_at).getTime() - new Date(a.raw.created_at).getTime())
    .filter(
      (revision) =>
        selectedRevision &&
        new Date(revision.raw.created_at).getTime() <=
          new Date(selectedRevision?.raw.created_at).getTime(),
    )
    .reduce(
      (acc, revision) => {
        acc[revision.id] = revision;

        return acc;
      },
      {} as Record<string, (typeof revisions)[number]>,
    );

  const groups = getObjectKeys(revisionsMap);

  const groupedByRevision = groups.reduce(
    (result, revision) => {
      if (!result[revision]) {
        result[revision] = [];
      }

      result[revision] = comments.filter((c) => c.revision === revision);

      return result;
    },
    {} as Record<string, typeof comments>,
  );

  return (
    <>
      <ScrollArea
        className={'!tw-m-0 tw-max-h-[60vh] tw-grow tw-px-4 tw-pt-4'}
        viewportProps={{
          className: '[&>div]:!tw-block',
        }}
        scrollRef={scrollRef}
        onScrollUp={(ref) => {
          if (!ref.scrollTop && canLoadMore && !isLoadingMore) {
            fetchNextPage();
          }
        }}
      >
        <If when={isLoadingMore}>
          <div className={'tw-flex tw-w-full tw-pb-4'}>
            <Loader2 className={'tw-mx-auto tw-size-8 tw-animate-spin tw-text-secondary'} />
          </div>
        </If>

        <If when={!hasComments && !isLoadingMore && !!selectedRevision}>
          <Text color={'dark'} $centeredContent={true}>
            {t('revisions.chat.notice.revision_has_no_comments')}
          </Text>
        </If>

        <If when={hasComments}>
          <div className={'tw-flex tw-flex-col-reverse tw-gap-4'}>
            {groups.map((revisionId) => (
              <React.Fragment key={revisionId}>
                <div className={'tw-flex tw-flex-col-reverse tw-gap-4'}>
                  <If
                    when={!!groupedByRevision[revisionId]?.length}
                    else={
                      <Text className={'mb-3'} color={'dark'} $centeredContent={true}>
                        {t('revisions.chat.notice.revision_has_no_comments')}
                      </Text>
                    }
                  >
                    {groupedByRevision[revisionId]?.map((comment) => {
                      const isOfCurrentRevision = selectedRevision?.id === comment.revision;
                      const isCurrentlyMounted = selectedRevisionMedia?.id === comment.media_id;

                      const referredRevision = revisions.find((r) => r.id === comment.revision);
                      const referredMedia = referredRevision?.media.find(
                        (m) => m?.id === comment.media_id,
                      );
                      const referredMediaExists = !!referredMedia;

                      const commentHasTimestamp =
                        isNumber(comment.revision_timestamp) && !isNaN(comment.revision_timestamp);

                      const formattedTime = commentHasTimestamp
                        ? formatTime(comment.revision_timestamp!, {
                            format: pageStore.timeFormat,
                            fps: referredMedia?.fps ?? 24,
                          })
                        : '';

                      const seekToTime = () => {
                        if (!commentHasTimestamp || !referredMediaExists) {
                          return;
                        }

                        if (isCurrentlyMounted) {
                          pageStore.seek(comment.revision_timestamp!);

                          return;
                        }

                        if (!isOfCurrentRevision) {
                          setSelectedRevision(referredRevision?.id);
                        }

                        setSelectedRevisionMedia(referredMedia.id);
                        pageStore.setOnMount(({ seek, pause }) => {
                          pause();
                          seek(comment.revision_timestamp!);
                        });
                      };

                      return (
                        <ChatMessage
                          className={'!tw-mb-0 tw-whitespace-pre-line'}
                          key={comment.id}
                          $shadow={false}
                          $right={comment.sender?.me}
                          color={
                            comment.sender?.me
                              ? 'light'
                              : comment.sender?.type === 'editor'
                              ? 'secondary'
                              : 'danger'
                          }
                        >
                          <RevisionChatMessageActionsDropdown>
                            <StyledDropdownItem
                              onClick={() => navigator.clipboard.writeText(comment.comment)}
                            >
                              {t('copy', { ns: 'actions' })}
                            </StyledDropdownItem>
                            <If when={comment.sender?.me && !removeCommentMutation.isLoading}>
                              <StyledDropdownItem
                                onClick={() => {
                                  removeCommentMutation.mutate(comment.id);
                                }}
                              >
                                {t('delete', { ns: 'actions' })}
                              </StyledDropdownItem>
                            </If>
                          </RevisionChatMessageActionsDropdown>

                          <div className={'tw-mb-2 tw-flex tw-flex-col tw-gap-2 md:tw-flex-row'}>
                            <Text tag={'b'} color={'dark'}>
                              {comment.sender?.full_name}
                            </Text>
                            <Text color={'gray'}>{formatDateTime(comment.created_at)}</Text>
                          </div>

                          <If when={commentHasTimestamp}>
                            <div
                              className={'tw-mb-2 tw-flex tw-items-center tw-gap-2'}
                              onClick={seekToTime}
                            >
                              <OverridenBadge
                                $disabled={!referredMediaExists}
                                color={'secondary'}
                                className={'pointer gap-2 fs-6'}
                                $centeredContent={true}
                              >
                                <ClockIcon color={'#FFFFFF'} size={'lg'} />
                                {formattedTime}
                              </OverridenBadge>
                              <If
                                when={!referredMediaExists}
                                else={
                                  <HrefLink
                                    className={'tw-line-clamp-1'}
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    {referredMedia?.file_name}
                                  </HrefLink>
                                }
                              >
                                <Text
                                  className={'tw-italic'}
                                  style={{
                                    fontSize: 12,
                                  }}
                                >
                                  File not found
                                </Text>
                              </If>
                            </div>
                          </If>

                          <If when={!!comment.comment}>
                            <ChatMessageContentRow
                              className={'!tw-block tw-break-words'}
                              dangerouslySetInnerHTML={{
                                __html: Str.wrapHrefs(
                                  sanitize(comment.comment ?? '', {
                                    USE_PROFILES: { html: true },
                                  }),
                                ),
                              }}
                            />
                          </If>

                          <If when={!!comment.media.length}>
                            <ChatMessageContentRow className={'gap-2 flex-wrap'}>
                              {comment.media.map((media) => (
                                <MinimalMediaCard
                                  key={media.id}
                                  media={media}
                                  canDownload={true}
                                  displayName={true}
                                />
                              ))}
                            </ChatMessageContentRow>
                          </If>
                        </ChatMessage>
                      );
                    })}
                  </If>
                </div>

                <VersionBadge pill>
                  <Text>{revisionsMap[revisionId]?.title ?? t('version.singular')}</Text>
                  <Text $xs={'xs'}>
                    {t('{{val, datetime}}', {
                      val: new Date(revisionsMap[revisionId]?.raw?.created_at ?? Date.now()),
                    })}
                  </Text>
                </VersionBadge>
              </React.Fragment>
            ))}
          </div>
        </If>
      </ScrollArea>

      <ChatInputWrapper
        className={'!tw-mt-auto'}
        color={'light'}
        disabled={!selectedRevision?.isLatest || request.isCompleted}
      >
        <ChatInputFallback>
          <If
            when={request.isCompleted}
            else={
              <If when={!selectedRevision?.isLatest}>
                <Text color={'gray'}>{t('revisions.chat.notice.completed_revision')}</Text>
                <Text
                  as={NavLink}
                  active={true}
                  decoration={'underline'}
                  color={'secondary'}
                  onClick={selectLatest}
                  dangerouslySetInnerHTML={{
                    __html: t('revisions.revision.action.go_to_current_version'),
                  }}
                />
              </If>
            }
          >
            <Text color={'gray'}>{t('revisions.chat.notice.completed_revision')}</Text>
          </If>
        </ChatInputFallback>

        <ChatInputCommentFormikProvider
          onSubmit={() => {
            setTimeout(() => scrollToBottom(), 1);
          }}
        >
          <>
            <ChatInputCommentSelectedMedia />

            <ChatInputContent className={'!tw-p-4'}>
              <ChatInputCommentForm />
            </ChatInputContent>
          </>
        </ChatInputCommentFormikProvider>
      </ChatInputWrapper>
    </>
  );
};

export { RevisionChatContent };
