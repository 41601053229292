import { FieldHelper, mapFieldsIntoObject } from '../';
import { default as fields } from './fields.json';

export interface IRequestFields<T> {
  type: T;
  title: T;
  brand_id: T;
  assets_to_migrate: T;

  resolutions: {
    main_size: T;
    resize: T;
  };

  description: {
    total_length: T;
    video_request_description: T;
  };

  supporting_materials: {
    assets: T;
    assets_urls: T;
    include_music: T;
    music_preference: T;
  };

  extra: {
    subtitles: T;
    end_card: T;
    examples: T;
  };
}

const RequestFieldHelper = mapFieldsIntoObject(fields) as IRequestFields<FieldHelper>;

export default RequestFieldHelper;
