import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { appRoutes, RouteObject } from './routes.types';
import { useAuthStore } from 'src/services/Auth/Auth.store';
import { usePreLoginAuthLocation } from 'src/routes/use-pre-login-auth-location';
import { useSaveHubspotUtmLocationEffect } from 'src/lib/services/hubspot/use-hubspot-saved-utm-location';

type ProtectedRouteProps = {
  route: RouteObject;
};

// const onBoardRoutes = [
//   appRoutes.verifyEmail,
//   appRoutes.authVerifyEmail,
//   appRoutes.onboardPlanSelect,
//   appRoutes.onboardCompany,
//   appRoutes.onboardSubscribe,
//   appRoutes.onboardSubscribeSuccess,
//   appRoutes.klarnaCallback,
// ] as string[];

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ route }) => {
  const location = useLocation();
  const { isLoggedIn } = useAuthStore();
  const { rememberLocation } = usePreLoginAuthLocation();

  useSaveHubspotUtmLocationEffect();

  if (route.underMaintenance) {
    return <Navigate to={appRoutes.maintenance} />;
  }

  // will save location to locale storage in case if login occurs during external login
  if (!isLoggedIn && route.restricted?.includes('guest')) {
    return <Navigate to={appRoutes.login} state={{ from: rememberLocation(location) }} replace />;
  }

  if (isLoggedIn) {
    const userType = AuthUtils.getPayload()?.user_type;

    if (
      route.restricted?.includes(userType as any)
      // || (isOnboarded && onBoardRoutes.includes(location.pathname))
    ) {
      return <Navigate to={appRoutes.dashboard} replace />;
    }

    // Disabling verified email lock
    // if (!hasVerifiedEmail && location.pathname !== appRoutes.authVerifyEmail) {
    //   return <Navigate to={appRoutes.authVerifyEmail} replace />;
    // }
  }

  return (
    <route.layout>
      <Outlet />
    </route.layout>
  );
};
