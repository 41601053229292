import { DynamicObject } from 'src/types/general';
import Str from 'src/helpers/Str';

export class FieldHelper extends String {
  public generateId(): string {
    return Str.slugify(this.toString());
  }

  public generateGroupId(): string {
    return 'form-group-' + this.generateId();
  }
}

export function mapFieldsIntoObject(
  fields: DynamicObject<any>,
  result?: DynamicObject<any>,
): DynamicObject<any> {
  const keys: string[] = Object.keys(fields);
  if (!result) {
    result = {};
  }

  keys.forEach((key) => {
    if (typeof fields[key] !== 'string') {
      (result as DynamicObject<any>)[key] = {};
      mapFieldsIntoObject(fields[key], (result as DynamicObject<any>)[key]);
      return;
    }

    (result as DynamicObject<any>)[key] = new FieldHelper(fields[key]);
  });

  return result;
}
