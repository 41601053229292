import { UserType } from 'src/lib/services/api/profile-api';
import { ValueOfObject } from 'src/types/generics';

const jwtStorageKey = 'jwt-becreatives';

type JwtStorageKey = typeof jwtStorageKey;

const authEvent = {
  login: 'login',
  refresh: 'refresh',
  logout: 'logout',
} as const;

type AuthEvent = ValueOfObject<typeof authEvent>;

interface JwtInterface {
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  prv: string;
  sub: string;
  user_type: UserType;
  impersonator: string | null;
  company_id: string;
  is_verified: boolean;
  is_subscribed: boolean;
}

type AuthJwtObject = {
  token: string;
  expires: number;
};

export type { JwtStorageKey, JwtInterface, AuthJwtObject, AuthEvent };
export { authEvent, jwtStorageKey };
