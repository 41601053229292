import React, { useEffect } from 'react';
import { generatePath, Link, useLocation, useNavigate } from 'react-router-dom';
import { ClockIcon, WarningOctagonIcon } from '@becreatives/becreatives-ui';
import { useRequestsCounts } from 'src/models';
import { RevisionsContentBottom, RevisionsContentMain, RevisionsContentTop } from './UpdatedDesign';
import { RequestResponse } from 'src/lib/services/api/request-api';
import { RevisionOutcomeContentBottom } from 'src/pages/request/revision-page/revision-outcome-content-bottom';
import { appRoutes } from 'src/routes';
import { useTranslation } from 'react-i18next';
import { If } from 'src/components/If';
import { useRequestId } from 'src/features/requests/use-request-id';
import { RequestQueryProvider } from 'src/features/requests/use-request-query-context';
import { Skeleton } from 'src/components/ui/skeleton';
import { SpaceErrorFallback } from 'src/features/fallback/space-error-fallback';
import {
  RevisionFallbackImage,
  RevisionFallbackWrapper,
} from 'src/pages/RevisionsPage/UpdatedDesign/RevisionsContentMain/RevisionsContentMain.styled';
import {
  DecoratedRequestProvider,
  useDecoratedRequestContext,
} from 'src/features/requests/use-decorated-request-context';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { cn } from 'src/lib/utils';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from 'src/components/ui/breadcrumb';
import { Button } from 'src/components/ui/button';
import { ChevronLeft, Video } from 'lucide-react';
import { PageHeading, PageHeadingTitle } from 'src/components/ui/page-heading';

const RevisionsPageFallbackContent: React.FC<{ error: any; resetErrorBoundary: () => any }> = ({
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation();
  const requestId = useRequestId();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={'tw-flex tw-flex-col tw-gap-4 md:tw-gap-10'}>
      <PageHeading>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <Button
                variant={'unset'}
                size={'iconXs'}
                onClick={() => {
                  if (location.key !== 'default') {
                    history.back();
                  } else {
                    navigate(generatePath(appRoutes.request, { requestId }));
                  }
                }}
              >
                <ChevronLeft className={'tw-size-4'} />
              </Button>
              <BreadcrumbLink asChild>
                <Link to={appRoutes.dashboard}>
                  {t('common:dashboard', {
                    defaultValue: 'Dashboard',
                  })}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to={appRoutes.requestsList}>
                  {t('common:requests', {
                    defaultValue: 'Requests',
                  })}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to={generatePath(appRoutes.request, { requestId })}>
                  {t('common:request', {
                    defaultValue: 'Request',
                  })}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbPage>
              {t('common:revisions', {
                defaultValue: 'Revisions',
              })}
            </BreadcrumbPage>
          </BreadcrumbList>
        </Breadcrumb>
        <PageHeadingTitle className={'tw-flex-nowrap'}>
          <div>
            <Video className={'tw-size-6'} />
          </div>
          <span className={'tw-line-clamp-1'}>
            {t('common:revision', { defaultValue: 'Request revision' })}
          </span>
        </PageHeadingTitle>
      </PageHeading>

      <SpaceErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
    </div>
  );
};

const RevisionsPageSkeleton = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={cn('tw-grid tw-h-full tw-gap-4 lg:tw-grid-cols-12', className)}
    >
      {children || (
        <>
          <Skeleton className={'tw-rounded-xl lg:tw-col-span-7 xl:tw-col-span-8'} />
          <Skeleton className={'tw-rounded-xl lg:tw-col-span-5 xl:tw-col-span-4'} />
        </>
      )}
    </div>
  );
});
RevisionsPageSkeleton.displayName = 'RevisionsPageSkeleton';

const RevisionContentBottomResolver: React.FC<{
  flow?: RequestResponse['flow'];
}> = ({ flow = 'default' }) => {
  switch (flow) {
    case 'outcome':
      return <RevisionOutcomeContentBottom />;
    default:
      return <RevisionsContentBottom />;
  }
};

const RevisionsPageContents: React.FC = () => {
  const { t } = useTranslation();
  const { flow, hasEditor } = useDecoratedRequestContext();
  const { initialize, reset, isEmpty, selectedRevision } = useRequestRevisionsStore();

  const requestId = useRequestId();

  const { review } = useRequestsCounts(
    {},
    {
      suspense: true,
      staleTime: 1000 * 60 * 5,
    },
  );

  useEffect(() => {
    review(requestId!);
    initialize();

    const preventKeyDownSpace = function (e: KeyboardEvent) {
      const target = e.target as HTMLElement;
      // don't scroll down when pressing 'space' to play/pause video
      if (e.key == ' ' && !['textarea', 'input'].includes(target.tagName.toLowerCase())) {
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', preventKeyDownSpace, false);

    return () => {
      reset();
      window.removeEventListener('keydown', preventKeyDownSpace, false);
    };
  }, []);

  return (
    <>
      <RevisionsContentTop />

      <If
        when={hasEditor && !isEmpty}
        else={
          <RevisionFallbackWrapper
            className={
              '!tw-my-4 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-rounded-xl tw-bg-white tw-p-4 tw-shadow-sm'
            }
          >
            <RevisionFallbackImage />
            <p className={'tw-flex tw-items-center tw-text-neutral-500'}>
              <If
                when={isEmpty}
                else={
                  <>
                    <ClockIcon className={'me-2'} />
                    {t('pages/shared:revisions.revision.notice.no_editor', {
                      defaultValue: 'Choosing an editor',
                    })}
                  </>
                }
              >
                <WarningOctagonIcon className={'me-2'} />
                {t('pages/shared:revisions.revision.notice.no_revisions', {
                  defaultValue: 'Request does not have any revisions',
                })}
              </If>
            </p>
          </RevisionFallbackWrapper>
        }
      >
        {selectedRevision ? <RevisionsContentMain /> : null}
      </If>

      <RevisionContentBottomResolver flow={flow} />
    </>
  );
};

const RevisionsPage = () => {
  const requestId = useRequestId();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }: any) => (
            <RevisionsPageFallbackContent error={error} resetErrorBoundary={resetErrorBoundary} />
          )}
          onReset={reset}
        >
          <React.Suspense fallback={<RevisionsPageSkeleton />}>
            <RequestQueryProvider id={requestId}>
              {({ data }) => (
                <DecoratedRequestProvider request={data}>
                  <RevisionsPageContents />
                </DecoratedRequestProvider>
              )}
            </RequestQueryProvider>
          </React.Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export { RevisionsPage };
