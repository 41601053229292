import { requestListType, RequestListType } from 'src/lib/services/api/request-api';
import { create } from 'zustand';

interface UseRequestsListStore {
  requestsListType: RequestListType;
  setRequestsListType: (type: RequestListType) => void;
}

const useRequestsListStore = create<UseRequestsListStore>((set) => ({
  requestsListType: requestListType.list,
  setRequestsListType: (type) => set({ requestsListType: type }),
}));

export { useRequestsListStore };
