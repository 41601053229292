import { httpConfig } from 'src/lib/modules/http';
import { AxiosRequestConfig } from 'axios';
import { PaginatedResponse } from 'src/api/Client';
import { Api } from 'src/api';
import { url } from 'src/lib/utils';
import {
  BrandResponse,
  BrandsTableResponse,
  CreateBrandRequest,
  UpdateBrandRequest,
} from 'src/lib/services/api/brand-api/brand-client.types';

const baseUrl = `${httpConfig.options.baseURL}/brands`;

const index = (config?: AxiosRequestConfig): Promise<PaginatedResponse<BrandsTableResponse>> =>
  Api.http.get(baseUrl, config);

const get = (model: string, config?: AxiosRequestConfig): Promise<BrandResponse> =>
  Api.http.get(url.insertArguments(`${baseUrl}/{model}`, { model }), config);

const create = (data: CreateBrandRequest, config?: AxiosRequestConfig): Promise<BrandResponse> =>
  Api.http.post(`${baseUrl}/create`, data, config);

const update = (
  model: string,
  data: UpdateBrandRequest,
  config?: AxiosRequestConfig,
): Promise<BrandResponse> =>
  Api.http.put(url.insertArguments(`${baseUrl}/{model}`, { model }), data, config);

const destroy = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.delete(url.insertArguments(`${baseUrl}/{model}`, { model }), config);

export { index, get, create, update, destroy };
