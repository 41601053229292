import { NODE_ENV } from 'src/env.json';

type Environment = 'staging' | 'dev' | 'sandbox' | 'prod';

const currentEnv = NODE_ENV as Environment;

const supportsMSE = () => 'MediaSource' in window;

const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MediaSource' in window);

const environment = {
  current: currentEnv,
  isLocal: () => currentEnv === 'dev',
  isStaging: () => currentEnv === 'staging',
  isSandbox: () => currentEnv === 'sandbox',
  isProduction: () => currentEnv === 'prod',
} as const;

const system = {
  supportsMSE,
  isIOS,
  env: environment,
} as const;

export { system };
