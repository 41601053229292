import React from 'react';
import { render } from 'src/lib/utils';
import { RequestOutcome } from 'src/lib/services/api/request-api/request-client';
import { requestOutcomeStatus } from 'src/lib/services/api/request-api';
import { decorateMedia } from 'src/features/media';
import { totalLengthToString } from 'src/features/requests/request.helper';

type DecoratedRequestOutcome = {
  raw: RequestOutcome;
  id: string;
  latestRevision?: RequestOutcome['latest_revision'];
  revisionPreview?: ReturnType<typeof decorateMedia>;
  canCreateFirstContact: boolean;
  revisionPreviewImage?: string;
  needsReedit: boolean;
  canArchive: boolean;
  canRestore: boolean;
  totalLength: string | undefined;
};

const decorateRequestOutcome = (outcome?: RequestOutcome): DecoratedRequestOutcome | undefined => {
  if (!outcome) {
    return outcome;
  }

  const raw = outcome;

  const id = raw.id,
    canCreateFirstContact = raw.status === requestOutcomeStatus.queued,
    latestRevision = raw.latest_revision,
    revisionPreview = latestRevision?.video ? decorateMedia(latestRevision?.video) : undefined,
    revisionPreviewImage = revisionPreview?.thumbnail || revisionPreview?.poster,
    needsReedit = !!raw.requested_reedit_at,
    canArchive = raw.status === requestOutcomeStatus.queued && !latestRevision,
    canRestore = raw.status === requestOutcomeStatus.archived,
    totalLength = totalLengthToString(raw.total_length);

  return {
    raw,
    id,
    latestRevision,
    revisionPreview,
    canCreateFirstContact,
    revisionPreviewImage,
    needsReedit,
    canArchive,
    canRestore,
    totalLength,
  };
};

const DecoratedRequestOutcomeContext = React.createContext<DecoratedRequestOutcome | undefined>(
  undefined,
);

const DecoratedRequestOutcomeContextProvider = ({
  outcome,
  children,
}: {
  outcome: RequestOutcome;
  children: React.ReactNode | ((outcome: DecoratedRequestOutcome) => React.ReactNode);
}) => {
  const value = decorateRequestOutcome(outcome);

  return (
    <DecoratedRequestOutcomeContext.Provider value={value!}>
      {render(children, value)}
    </DecoratedRequestOutcomeContext.Provider>
  );
};

const useDecoratedRequestOutcomeContext = () => {
  const context = React.useContext(DecoratedRequestOutcomeContext);

  if (!context) {
    throw new Error(
      'useDecoratedRequestOutcomeContext must be used within a RequestOutcomeContextProvider',
    );
  }

  return context;
};

export {
  decorateRequestOutcome,
  DecoratedRequestOutcomeContextProvider,
  useDecoratedRequestOutcomeContext,
};
