import TagManager from 'react-gtm-module';
import { GTMEcommerceObject, GTMPurchaseEventObject } from './GoogleTagManager.types';

import { GTM_APP_ID } from 'src/env.json';

const event = {
  purchase: 'purchase',
} as const;

class GoogleTagManager {
  public static init = () => {
    TagManager.initialize({
      gtmId: GTM_APP_ID,
    });
  };

  public static event: (
    e: keyof typeof event,
    data: Record<string, any>,
    options?: Record<string, any>,
  ) => void = (event, data, options = {}) => {
    this.push({
      event,
      eventModel: data,
      ...options,
    });
  };

  public static purchase = (modelData: GTMPurchaseEventObject, ecommerce: GTMEcommerceObject) => {
    this.event(event.purchase, modelData, {
      ecommerce,
    });
  };

  private static push = (obj: Record<string, any>) => {
    window.dataLayer.push(obj);
  };
}

export { GoogleTagManager };
