import React from 'react';
import { Blocks, LucideProps, Sparkles } from 'lucide-react';
import { Role, roles } from 'src/api/services/RoleClient';

const RequestOutcomeCreatedByIcon: React.FC<
  LucideProps & {
    createdBy: Role | string;
  }
> = ({ createdBy, ...props }) => {
  switch (createdBy) {
    case roles.customer:
      return <Blocks {...props} />;
    default:
      return <Sparkles {...props} />;
  }
};

export { RequestOutcomeCreatedByIcon };
