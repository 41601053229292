import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@tanstack/query-core';
import { UseInfiniteQueryOptions, UseInfiniteQueryResult } from '@tanstack/react-query/src/types';
import { InfiniteData } from '@tanstack/query-core/src/types';
import { Updater } from '@tanstack/query-core/src/utils';
import { scopedQueries } from 'src/api/queries';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

const useMakeInfiniteQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>,
  filters: ApiQueryObject = {},
): UseInfiniteQueryResult<TData, TError> & {
  setPageData: (page: number, data: InfiniteData<Partial<TData>>) => void;
} => {
  const queryKey = [...options.queryKey!, scopedQueries.infinite, filters] as any;

  const query = useInfiniteQuery({
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    ...options,
    queryKey,
  });

  const client = useQueryClient();

  return Object.assign(query, {
    setPageData: (
      page: number,
      updater: Updater<
        InfiniteData<Partial<TData>> | undefined,
        InfiniteData<Partial<TData>> | undefined
      >,
    ) => {
      client.setQueryData<InfiniteData<Partial<TData>>>([...options.queryKey!, filters], (prev) => {
        if (!prev) {
          return prev;
        }

        if (updater instanceof Function) {
          return updater(prev);
        }

        if (prev.pages.length >= page) {
          (prev.pages as any)[page] = updater;
        }

        return prev;
      });
    },
  });
};

// eslint-disable-next-line
type UseMakeInfiniteQueryReturnType<Response> = ReturnType<typeof useMakeInfiniteQuery>;

export type { UseMakeInfiniteQueryReturnType };
export { useMakeInfiniteQuery };
