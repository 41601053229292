import React from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from 'src/components/ui/card';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { If } from 'src/components/If';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import RevisionFileDisplayFallback from 'src/assets/images/revision-file-display-fallback.png';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { useTranslation } from 'react-i18next';
import { GitFork } from 'lucide-react';
import { RequestOutcomePriority } from 'src/features/requests/request-outcome/request-outcome-priority';
import { useDecoratedRequestOutcomeContext } from 'src/features/requests/request-outcome/use-decorated-request-outcome-context';
import { progressInStandardTime } from 'src/lib/utils/progress-in-standard-time';
import { RequestOutcomeCreatedByIcon } from 'src/features/requests/request-outcome/request-outcome-created-by-icon';
import { Badge } from 'src/components/ui/badge';

type Props = React.ComponentProps<typeof Card>;

const RequestDeliverableOutcomeCard: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    raw: outcome,
    revisionPreviewImage,
    revisionPreview,
    needsReedit,
  } = useDecoratedRequestOutcomeContext();

  return (
    <Card {...props}>
      <CardContent className={'tw-w-full tw-items-stretch tw-p-0'}>
        <AspectRatio ratio={4 / 3}>
          <div className={'tw-relative tw-h-full tw-w-full'}>
            <div className={'tw-absolute tw-inset-0'}>
              <If
                when={!!revisionPreviewImage}
                else={
                  <div
                    className={
                      'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-t-md tw-bg-neutral-linear'
                    }
                  >
                    <img
                      src={RevisionFileDisplayFallback}
                      alt={outcome.title}
                      className={'tw-h-30'}
                    />
                  </div>
                }
              >
                <img
                  className={'tw-h-full tw-w-full tw-rounded-t-lg tw-object-cover'}
                  src={revisionPreviewImage}
                  alt={outcome.title}
                />
              </If>
            </div>

            <div
              className={
                'tw-absolute tw-inset-0 tw-z-10 tw-flex tw-flex-col tw-items-stretch tw-p-4'
              }
            >
              <div className={'tw-flex tw-w-full tw-items-start'}>
                <If when={needsReedit}>
                  <Badge variant={'destructive'} className={'tw-text-base'}>
                    {t('models/request:state.requested_reedit_at', {
                      defaultValue: 'Needs re-edit',
                    })}
                  </Badge>
                </If>

                <RequestStatusBadge className={'tw-ms-auto'} variant={outcome.status}>
                  {t(`models/request:status.${outcome.status}`, {
                    defaultValue: t('common:unknown', {
                      defaultValue: 'Unknown',
                    }),
                  })}
                </RequestStatusBadge>
              </div>
              <If when={!!revisionPreview?.durationInSeconds}>
                <div
                  className={
                    'tw-ms-auto tw-mt-auto tw-rounded-lg tw-bg-white tw-px-2 tw-py-1 tw-font-medium'
                  }
                >
                  {progressInStandardTime(revisionPreview?.durationInSeconds ?? 0)}
                </div>
              </If>
            </div>
          </div>
        </AspectRatio>
      </CardContent>
      <CardHeader className={'tw-gap-2 tw-bg-neutral-50 tw-px-4 tw-pb-5 tw-pt-3'}>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <CardTitle
                className={
                  'tw-line-clamp-1 tw-w-full tw-break-all tw-text-lg tw-font-bold tw-leading-normal'
                }
              >
                {outcome.title}
              </CardTitle>
            </TooltipTrigger>
            <TooltipContent
              align={'start'}
              className={'tw-max-w-96 tw-overflow-x-auto tw-break-all'}
            >
              {outcome.title}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </CardHeader>
      <CardFooter className={'tw-rounded-lg tw-bg-neutral-50 tw-px-4 tw-pb-3 tw-pt-0'}>
        <div className={'tw-flex tw-grow tw-items-center tw-gap-2'}>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className={'tw-flex tw-items-center tw-gap-2 tw-rounded-lg tw-p-2'}>
                  <GitFork size={16} />
                  <div className={'tw-text-lg tw-font-medium'}>{`v${
                    outcome.versions_count ?? 0
                  }`}</div>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                {t('common:version', {
                  defaultValue: 'Version',
                })}
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <div className={'tw-flex tw-items-center tw-rounded-lg tw-p-2'}>
                  <RequestOutcomeCreatedByIcon size={16} createdBy={outcome.created_by} />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                {t(`models/request:outcome.created_by.${outcome.created_by}`, {
                  defaultValue: 'Created by unknown',
                })}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <RequestOutcomePriority
            size={'sm'}
            variant={outcome.is_default ? 'default' : 'additional'}
          >
            {outcome.sort}
          </RequestOutcomePriority>
        </div>
      </CardFooter>
    </Card>
  );
};

export { RequestDeliverableOutcomeCard };
