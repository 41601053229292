type ValidationRule<V, M = string> = {
  message: M;
  value: V;
};

type UploadValidationRulesObject = {
  maxSize?: number | ValidationRule<number>;
  minSize?: number | ValidationRule<number>;
  maxItems?: number | ValidationRule<number>;
};

const isValidationRule = <T, M = string>(arg: any): arg is ValidationRule<T, M> => {
  return !!arg && typeof arg === 'object' && 'value' in arg && 'message' in arg;
};

export type { ValidationRule, UploadValidationRulesObject };
export { isValidationRule };
