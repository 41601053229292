import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { UploadedChunkObject } from 'src/api/services/MediaClient';
import { AxiosHttpError } from 'src/api/Http/Http.types';

const MAX_PARTS = 1000; // 1000 is the max number of parts allowed by S3
const MIN_PART_SIZE = 6_000_000;

const calculateChunks = (fileSize: number): number => {
  const partSize = calculatePartSize(fileSize);
  return Math.min(Math.ceil(fileSize / partSize), MAX_PARTS);
};

const calculatePartSize = (fileSize: number): number => {
  const minPartSize = Math.ceil(fileSize / MAX_PARTS);
  return Math.max(minPartSize, MIN_PART_SIZE);
};

class S3Uploader {
  public readonly concurrentUploads = 4; // Concurrent uploads limit
  public readonly maxUploadAttempts = 3; // Max upload retries per chunk

  private axios: AxiosInstance;
  private uploadQueue: number[]; // Part numbers queue
  private activeUploads: number; // Number of active upload requests
  private uploadedParts: UploadedChunkObject[];
  private uploadPartAttempts: { [key: number]: number }; // Number of upload attempts per chunk

  constructor(controller?: AbortController) {
    this.axios = axios.create({
      signal: controller?.signal,
    });

    this.uploadQueue = [];
    this.activeUploads = 0;
    this.uploadedParts = [];
    this.uploadPartAttempts = {};
  }

  getChunkBlob = (file: File, start: number, end: number): Blob => file.slice(start, end);

  async upload(
    signedUrls: string[],
    file: File,
    onUploadProgress: (event: any, chunkIndex: number) => void,
    onFail?: (error: AxiosHttpError) => void,
  ): Promise<UploadedChunkObject[]> {
    const fileSize = file.size;
    const numParts = signedUrls.length;
    const partSize = calculatePartSize(fileSize);

    // Initialize the queue with all part numbers
    this.uploadQueue = Array.from({ length: numParts }, (_, i) => i);
    this.uploadedParts = new Array(numParts);

    // Start the initial batch of uploads
    this.processQueue(signedUrls, file, partSize, onUploadProgress, onFail);

    // Wait until all parts have been uploaded
    await new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (this.activeUploads === 0 && this.uploadQueue.length === 0) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });

    return this.uploadedParts.filter((part) => part !== undefined); // Filter out any undefined parts
  }

  private async processQueue(
    signedUrls: string[],
    file: File,
    partSize: number,
    onUploadProgress: (event: any, chunkIndex: number) => void,
    onFail?: (error: AxiosHttpError) => void,
  ) {
    while (this.activeUploads < this.concurrentUploads && this.uploadQueue.length > 0) {
      const part = this.uploadQueue.shift();
      if (part !== undefined) {
        this.activeUploads++;
        this.uploadPartAttempts[part] = (this.uploadPartAttempts[part] ?? 0) + 1;

        this.uploadChunk(signedUrls, file, part, partSize, onUploadProgress)
          .then(() => {
            this.activeUploads--;
            // Continue processing the queue
            this.processQueue(signedUrls, file, partSize, onUploadProgress, onFail);
          })
          .catch((error: any) => {
            onFail?.(error);
          });
      }
    }
  }

  private async uploadChunk(
    signedUrls: string[],
    file: File,
    part: number,
    partSize: number,
    onUploadProgress: (event: any, chunkIndex: number) => void,
  ) {
    const signedUrl = signedUrls[part];
    const start = part * partSize;
    const end = Math.min((part + 1) * partSize, file.size);

    const options: AxiosRequestConfig = {
      onUploadProgress: (event: any) => onUploadProgress(event, part),
    };

    const blob = this.getChunkBlob(file, start, end);
    try {
      const response = await this.axios.put(signedUrl, blob, options);
      this.uploadedParts[part] = {
        ETag: response.headers.etag,
        PartNumber: part + 1,
      };
    } catch (error: any) {
      if (axios.isCancel(error)) {
        throw error;
      }

      // retry chunk upload
      if (this.uploadPartAttempts[part] <= this.maxUploadAttempts) {
        this.uploadQueue.push(part);
        return;
      }

      throw error;
    }
  }
}

export { S3Uploader, calculateChunks, calculatePartSize };
