import React, { forwardRef, HTMLAttributes, useCallback, useLayoutEffect, useState } from 'react';
import { CallableChildren, cn, float, render } from 'src/lib/utils';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { Loader2, LucideIcon } from 'lucide-react';
import {
  isTimeFormat,
  TimeFormat,
  timeFormat as timeFormatOption,
} from 'src/features/video-player/video-player-utils';
import { default as VideoPlayer, ReactPlayerProps } from 'react-player';
import { useVideoProgressActions } from 'src/features/video-player/use-video-progress-store';
import { Skeleton } from 'src/components/ui/skeleton';
import debounce from 'lodash/debounce';

export const makeVideoPlayerConfig = (
  videoElementProps?: React.ComponentProps<'video'>,
  { file = {}, ...props }: ReactPlayerProps = {},
) => {
  const fileAttributes = { ...(file.attributes ?? {}) };

  const { className, ...rest } = videoElementProps ?? {};

  return {
    dashVersion: '4.7.2',
    hlsVersion: '1.4.12',
    ...props,
    file: {
      ...file,
      attributes: {
        style: {},
        ...fileAttributes,
        className: cn(
          'tw-mx-auto tw-h-full tw-w-full tw-rounded-md tw-object-contain tw-object-center',
          fileAttributes.className,
          className,
        ),
        ...rest,
      },
    },
  };
};

type VideoPlayerContextProviderMeta = {
  fps: number;
  durationInSeconds: number;
};

type VideoPlayerContextValue = {
  src: string;
  setSrc: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  initialLoaded: boolean;
  setInitialLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  srcOptions: { label: React.ReactNode; value: string }[];
  controlsVisible: boolean;
  setControlsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  fullscreen: boolean;
  setFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  timeFormat: TimeFormat;
  setTimeFormat: React.Dispatch<React.SetStateAction<TimeFormat>>;
  volume: number;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
  playbackRate: number;
  setPlaybackRate: React.Dispatch<React.SetStateAction<number>>;
  overlayRef: React.RefObject<HTMLDivElement>;
  videoDriverRef: React.RefObject<VideoPlayer>;
  containerRef: React.RefObject<HTMLDivElement>;
  playsinline: boolean;
  setPlaysinline: React.Dispatch<React.SetStateAction<boolean>>;
  isPreview: boolean;
  playerReady: boolean;
  setPlayerReady: React.Dispatch<React.SetStateAction<boolean>>;
  setPreview: React.Dispatch<React.SetStateAction<boolean>>;
  muted: boolean;
  setMuted: React.Dispatch<React.SetStateAction<boolean>>;
  error: any;
  setError: React.Dispatch<React.SetStateAction<any>>;
  errorCount: number;
  setErrorCount: React.Dispatch<React.SetStateAction<number>>;
  onPlayerReady?: (player: VideoPlayer) => void;
  setOnPlayerReady: React.Dispatch<
    React.SetStateAction<((player: VideoPlayer) => void) | undefined>
  >;

  // utils
  isError: boolean;
  canPlay: boolean;
  progressStoreActions: ReturnType<typeof useVideoProgressActions>;
  getInternalPlayer: () => HTMLVideoElement | null;
  handleSrcChange: (
    src: string,
    options?: {
      force?: boolean;
      playAfterChange?: boolean;
    },
  ) => void;
  debouncedSetError: (err: any) => void;
  canRender: boolean;

  // deps
  meta: VideoPlayerContextProviderMeta;
};

const VideoPlayerContext = React.createContext<undefined | VideoPlayerContextValue>(undefined);

export const VideoPlayerContextProvider: React.FC<{
  children?: CallableChildren<VideoPlayerContextValue>;
  meta?: Partial<VideoPlayerContextProviderMeta>;
  enablePreview?: boolean;
  defaultMuted?: boolean;
  defaultSrc: string;
  srcOptions?: { label: React.ReactNode; value: string }[];
}> = ({
  children,
  meta = {},
  defaultSrc,
  srcOptions = [],
  enablePreview = true,
  defaultMuted = false,
}) => {
  const [src, setSrc] = useState(defaultSrc);
  const [canRender, setCanRender] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  const [initialLoaded, setInitialLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(!enablePreview); // if preview is enabled, we don't need to load
  const [fullscreen, setFullscreen] = React.useState(false);
  const [playsinline, setPlaysinline] = React.useState(true);
  const [playerReady, setPlayerReady] = React.useState(false);
  const [controlsVisible, setControlsVisible] = React.useState(true);
  const [muted, setMuted] = React.useState(defaultMuted);
  const [isPreview, setPreview] = useState(enablePreview);
  const [volume, setVolume] = React.useState<number>(1);
  const [playbackRate, setPlaybackRate] = React.useState(1);
  const [timeFormat, setTimeFormat] = React.useState<TimeFormat>(timeFormatOption.standard);
  const [error, setError] = useState<any>();
  const [errorCount, setErrorCount] = useState<number>(0);
  const [onPlayerReady, setOnPlayerReady] = useState<(player: VideoPlayer) => void>();
  const progressStoreActions = useVideoProgressActions();

  const overlayRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const videoDriverRef = React.useRef<VideoPlayer>(null);

  const debouncedSetError = useCallback(debounce(setError, 1000), []);

  useLayoutEffect(() => {
    if (isPreview) {
      return;
    }

    handleSrcChange(defaultSrc, {
      force: true,
      playAfterChange: true,
    });

    return () => {
      abortVideoLoading();
    };
  }, [defaultSrc, isPreview]);

  const isError = !!error;
  const canPlay = playerReady && !isError;

  const parsedMeta = {
    ...meta,
    fps: float.toFinite(meta.fps) || 24,
    durationInSeconds: float.toFinite(
      meta.durationInSeconds || videoDriverRef.current?.getDuration(),
    ),
  };

  const getInternalPlayer = () =>
    (videoDriverRef.current?.getInternalPlayer() as HTMLVideoElement) || null;

  const abortVideoLoading = () => {
    const player = videoDriverRef.current?.getInternalPlayer();
    if (player) {
      player.pause();
      player.src = '';
      player.load();
    }
  };

  const handleSrcChange = (
    next: string,
    options?: {
      force?: boolean;
      playAfterChange?: boolean;
    },
  ) => {
    if (next === src && !options?.force) {
      return;
    }

    const wasPlaying = playing;

    // Abort the previous video
    abortVideoLoading();

    setPlaying(false);
    setInitialLoaded(false);
    setPlayerReady(false);

    setCanRender(false);
    setSrc(next);
    setLoading(true);
    progressStoreActions.reset();
    setError(undefined);
    setErrorCount(0);
    setOnPlayerReady(() => () => {
      setPlaying(wasPlaying || !!options?.playAfterChange);
      setOnPlayerReady(undefined);
    });
    // forcing a new player instance to be created to stop loading the previous video
    setTimeout(() => {
      setCanRender(true);
    }, 300);
  };

  const contextValue = {
    src,
    setSrc,
    setLoading,
    loading,
    initialLoaded,
    setInitialLoaded,
    controlsVisible,
    setControlsVisible,
    fullscreen,
    setFullscreen,
    playing,
    setPlaying,
    volume,
    setVolume,
    overlayRef,
    containerRef,
    videoDriverRef,
    playbackRate,
    setPlaybackRate,
    playsinline,
    setPlaysinline,
    isPreview,
    setPreview,
    playerReady,
    setPlayerReady,
    timeFormat,
    setTimeFormat,
    muted,
    setMuted,
    error,
    setError,
    errorCount,
    setErrorCount,
    onPlayerReady,
    setOnPlayerReady,
    meta: parsedMeta,
    isError,
    canPlay,
    progressStoreActions,
    getInternalPlayer,
    handleSrcChange,
    srcOptions,
    debouncedSetError,
    canRender,
  };

  return (
    <VideoPlayerContext.Provider value={contextValue}>
      {render(children, contextValue)}
    </VideoPlayerContext.Provider>
  );
};

export const useVideoPlayerV2 = (): VideoPlayerContextValue => {
  const context = React.useContext(VideoPlayerContext);
  if (!context) {
    throw new Error('useVideoPlayerV2 must be used within a VideoPlayerContextProvider');
  }

  return context;
};

type VideoPlayerContainerProps = React.ComponentPropsWithoutRef<typeof AspectRatio>;

export const VideoPlayerSkeleton: React.FC<React.ComponentProps<typeof AspectRatio>> = ({
  ratio = 16 / 9,
  className,
  children,
  ...props
}) => {
  return (
    <AspectRatio
      ratio={ratio}
      className={cn('tw-relative tw-h-full tw-w-full', className)}
      {...props}
    >
      {children || <Skeleton className={'tw-absolute tw-inset-0'} />}
    </AspectRatio>
  );
};

export const RootVideoPlayerWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <React.Fragment>{children}</React.Fragment>
);

export const VideoPlayerContainer = React.forwardRef<HTMLDivElement, VideoPlayerContainerProps>(
  ({ className, ratio = 16 / 9, children, ...props }, ref) => {
    return (
      <AspectRatio {...props} ratio={ratio} ref={ref}>
        <div className={cn('tw-relative tw-h-full tw-w-full', className)}>{children}</div>
      </AspectRatio>
    );
  },
);
VideoPlayerContainer.displayName = 'VideoPlayerContainer';

export const VideoPlayerWrapper: React.FC<React.ComponentProps<'div'>> = ({
  className,
  ...props
}) => {
  return <div {...props} className={cn('tw-absolute tw-inset-0 tw-rounded-md', className)} />;
};

VideoPlayerContainer.displayName = 'VideoPlayerContainer';

type VideoOverlayProps = React.ComponentPropsWithoutRef<'div'> & {
  outer?: Omit<React.ComponentPropsWithoutRef<'div'>, 'children'>;
};

export const VideoOverlay = forwardRef<HTMLDivElement, VideoOverlayProps>(
  ({ children, outer = {}, className, ...props }, ref) => {
    return (
      <div {...outer} className={cn('tw-absolute tw-inset-0 tw-flex', outer?.className)}>
        <div
          ref={ref}
          {...props}
          className={cn(
            'tw-group/overlay tw-relative tw-z-20 tw-mx-auto tw-flex tw-w-full tw-flex-col tw-rounded-lg',
            className,
          )}
        >
          {children}
        </div>
      </div>
    );
  },
);
VideoOverlay.displayName = 'VideoOverlay';

export const VideoPlayerPlaybackToggleBox = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, onClick, ...props }, ref) => {
  const { togglePlay } = useVideoPlayToggle();

  return (
    <div
      ref={ref}
      className={cn('tw-grow tw-cursor-pointer', className)}
      onClick={(e) => {
        togglePlay();
        onClick?.(e);
      }}
      {...props}
    />
  );
});
VideoPlayerPlaybackToggleBox.displayName = 'VideoPlayerPlaybackToggleBox';

export const VideoPlayerLoadingIndicator = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn('tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center', className)}
      {...props}
    >
      {children || (
        <Loader2 className={'tw-size-16 tw-animate-spin tw-text-primary sm:tw-size-20'} />
      )}
    </div>
  );
});
VideoPlayerLoadingIndicator.displayName = 'VideoPlayerLoadingIndicator';

export const VideoPlayerControlsBottomWrapper = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'tw-z-10 tw-w-full tw-rounded-b-md tw-bg-gradient-to-t tw-from-neutral-700 tw-to-transparent tw-px-2',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
});
VideoPlayerControlsBottomWrapper.displayName = 'VideoPlayerControlsBottomWrapper';

export const OverlayIcon: React.FC<
  React.ComponentProps<LucideIcon> & {
    icon: React.ComponentType<React.ComponentProps<LucideIcon>>;
  }
> = ({ className, icon: Icon, ...props }) => (
  <Icon
    className={cn(
      'tw-size-5 tw-fill-gray-100 tw-stroke-gray-100 hover:tw-fill-gray-50 hover:tw-stroke-gray-50',
      className,
    )}
    {...props}
  />
);

export const useVideoPlayerProgress = () => {
  const { videoDriverRef, meta, canPlay } = useVideoPlayerV2();

  const { fps, durationInSeconds } = meta;

  const seek = (amount: number, type?: 'seconds' | 'fraction') => {
    if (!canPlay) {
      return;
    }

    return videoDriverRef.current?.seekTo(amount, type);
  };

  const seekSeconds = (seconds: number) => videoDriverRef.current?.seekTo(seconds, 'seconds');

  const seekFraction = (fraction: number) => videoDriverRef.current?.seekTo(fraction, 'fraction');

  const seekNextFrame = () => {
    const currentTimeSeconds = float.toFinite(videoDriverRef.current?.getCurrentTime() ?? 0);

    seekSeconds(Math.min(durationInSeconds, currentTimeSeconds + 1 / fps));
  };

  const seekPreviousFrame = () => {
    const currentTimeSeconds = float.toFinite(videoDriverRef.current?.getCurrentTime() ?? 0);

    seekSeconds(Math.max(0, currentTimeSeconds - 1 / fps));
  };

  return {
    seek,
    seekSeconds,
    seekFraction,
    seekNextFrame,
    seekPreviousFrame,
  };
};

export const useVideoPlaybackRate = () => {
  const { playbackRate, setPlaybackRate } = useVideoPlayerV2();

  const handlePlaybackRateChange = (value: string | number) => {
    const parsed = typeof value === 'string' ? parseFloat(value) : value;
    if (!Number.isNaN(parsed) && parsed) {
      setPlaybackRate(parsed || 1);
    }
  };

  return [playbackRate, handlePlaybackRateChange] as const;
};

export const useVideoTimeFormat = () => {
  const { timeFormat, setTimeFormat } = useVideoPlayerV2();

  const handleTimeFormatChange = (value: string) => {
    if (!isTimeFormat(value)) {
      return;
    }

    setTimeFormat(value);
  };

  return [timeFormat, handleTimeFormatChange] as const;
};

export const useVideoPlaysInlineToggle = () => {
  const { playsinline, setPlaysinline } = useVideoPlayerV2();

  const togglePlaysInline = () => {
    setPlaysinline((prev) => {
      return !prev;
    });
  };

  return {
    playsinline,
    setPlaysinline,
    togglePlaysInline,
  };
};

export const useVideoPlayToggle = () => {
  const { playing, setPlaying } = useVideoPlayerV2();

  const play = () => setPlaying(true);

  const pause = () => {
    setPlaying(false);
  };

  const togglePlay = () => {
    setPlaying((prev) => !prev);
  };

  return {
    playing,
    setPlaying,
    togglePlay,
    play,
    pause,
  };
};

export { VideoPlayer };
