import {
  AuthLoginRequest,
  AuthLoginResponse,
  AuthSignUpRequest,
  AuthSignUpResponse,
  AuthSocialLoginRequest,
  ForgotPasswordConfirmRequest,
} from './AuthClient.types';
import { Client } from 'src/api/Client';
import { RefreshTokenAbortController } from './AuthHttpAbortController';
import { AxiosRequestConfig } from 'axios';
import { InterceptableAxiosRequestConfig } from 'src/api/Http/Http.types';

class AuthClient extends Client {
  public readonly route = {
    login: `${this.http.baseUrl}/auth/login`,
    socialLogin: `${this.http.baseUrl}/auth/social-login`,
    logout: `${this.http.baseUrl}/auth/logout`,
    refresh: `${this.http.baseUrl}/auth/refresh`,
    signUp: `${this.http.baseUrl}/auth/register`,
    passwordReset: `${this.http.baseUrl}/auth/password-reset`,
    verify: `${this.http.baseUrl}/auth/email/verify/{id}/{hash}`,
    resendVerificationEmail: `${this.http.baseUrl}/auth/email/verification-notification`,
  };

  login = (data: AuthLoginRequest): Promise<AuthLoginResponse> => {
    return this.http.post(this.route.login, data);
  };

  socialLogin = (
    data: AuthSocialLoginRequest,
    config?: AxiosRequestConfig,
  ): Promise<AuthLoginResponse> => {
    return this.http.post(this.route.socialLogin, data, config);
  };

  logout = (): Promise<unknown> => {
    return this.http.post(this.route.logout);
  };

  refresh = (): Promise<AuthLoginResponse> => {
    return this.http.post(this.route.refresh, {}, { signal: RefreshTokenAbortController.signal });
  };

  initPasswordReset = (data: { email: string }): Promise<void> => {
    return this.http.post(this.route.passwordReset, data);
  };

  confirmPasswordReset = (data: ForgotPasswordConfirmRequest): Promise<void> => {
    return this.http.put(this.route.passwordReset, data);
  };

  signUp = (data: AuthSignUpRequest): Promise<AuthSignUpResponse> => {
    return this.http.post(this.route.signUp, data);
  };

  verifyEmail = (
    {
      id,
      hash,
      params,
    }: {
      id: string;
      hash: string;
      params: {
        expires: string;
        signature: string;
      };
    },
    config?: InterceptableAxiosRequestConfig,
  ): Promise<AuthLoginResponse> => {
    return this.http.get(this.buildUrl(this.route.verify, { id, hash }), { params, ...config });
  };

  resendVerificationEmail = (): Promise<void> => {
    return this.http.post(this.route.resendVerificationEmail);
  };
}

export { AuthClient };
