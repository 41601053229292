import React, { useMemo } from 'react';
import { ConfirmState } from './ConfirmModal.types';
import { useConfirm } from './useConfirm';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'src/components/ui/alert-dialog';

const ConfirmDialog: React.FC = (): JSX.Element | null => {
  const { onConfirm, onCancel, state } = useConfirm();

  const { text, options } = useMemo<ConfirmState>(() => state, [state]);

  return (
    <AlertDialog open={state.show}>
      <AlertDialogContent>
        <AlertDialogHeader className={options?.modal_header?.className}>
          <AlertDialogTitle>{options?.modal_header?.content ?? 'Are you sure?'}</AlertDialogTitle>
          {text ? (
            <AlertDialogDescription className={options?.modal_body?.className}>
              {options?.modal_body?.content ?? text}
            </AlertDialogDescription>
          ) : (
            options?.modal_body?.content
          )}
        </AlertDialogHeader>
        <AlertDialogFooter className={options?.modal_footer?.className}>
          <AlertDialogCancel onClick={onCancel} className={options?.cancel?.className}>
            {options?.cancel?.text ?? 'Cancel'}
          </AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm} className={options?.confirm?.className}>
            {options?.confirm?.text ?? 'Continue'}
          </AlertDialogAction>
        </AlertDialogFooter>
        {options?.modal_footer?.info && (
          <div className={'tw-pt-2'}>{options.modal_footer.info}</div>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { ConfirmDialog };
