import React, { useEffect, useState } from 'react';
import { CardProps } from 'reactstrap';
import {
  FormMediaObject,
  useRemoveMedia,
  useUploadPresignedUrls,
} from 'src/components/Form/S3UploadInput';
import {
  MinimalMediaCardUploadProgress,
  StyledMinimalMediaViewer,
  StyledMiniMediaCard,
  StyledMiniMediaCardBody,
  StyledMiniMediaCardDownloadLink,
  StyledMiniMediaCardRemoveButton,
} from 'src/components/MediaCard/MinimalMediaCard/MinimalMediaCard.styled';
import { If } from 'src/components/If';
import { MediaModal } from 'src/components/MediaCard/MediaModal';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';
import { FileName } from './file-name';
import { useTranslation } from 'react-i18next';
import { AxiosHttpError, isCancelError } from 'src/api/Http/Http.types';
import { HttpError } from 'src/api/Http/HttpError';
import { clsx } from 'clsx';

type Props = CardProps & {
  media: FormMediaObject;
  canView?: boolean;
  canDownload?: boolean;
  displayName?: boolean;
  onDelete?: (media: FormMediaObject) => Promise<void> | void;
  beforeDelete?: (e: MouseEvent) => boolean;
};

const MinimalMediaCardUpload: React.FC<Props> = ({
  media,
  canView = true,
  canDownload = true,
  displayName = false,
  onDelete,
  beforeDelete,
  ...props
}) => {
  const { t } = useTranslation('actions');
  const { uploadProgress, isUploading, upload } = useUploadPresignedUrls(media);
  const removeMedia = useRemoveMedia(media);
  const { removeUpload, setFailed, isFailed } = useMediaUploadStore();

  const [viewMode, setViewMode] = useState<boolean>(false);
  const toggleViewMode = () => setViewMode((prev) => !prev);

  const [objectUrl, setObjectUrl] = useState<string>();

  useEffect(() => {
    let blob: string | undefined = undefined;
    if (media?.file) {
      blob = URL.createObjectURL(media.file);
      setObjectUrl(blob);
    }

    let abortController = new AbortController();
    const onFail = (e: AxiosHttpError) => {
      const httpError = new HttpError(e);

      if (!isCancelError(httpError)) {
        setFailed(media.id);
        throw e;
      }
    };

    upload(abortController, onFail).catch((e: AxiosHttpError) => {
      onFail(e);
    });

    return () => {
      abortController.abort();
      abortController = new AbortController();
      removeUpload(media.id);
      if (blob) {
        URL.revokeObjectURL(blob);
        setObjectUrl(undefined);
      }
    };
  }, []);

  return (
    <StyledMiniMediaCard {...props}>
      <StyledMiniMediaCardBody
        className={clsx({ 'border border-2 border-danger': isFailed(media.id) })}
        onClick={() => {
          if (isUploading) {
            return;
          }

          toggleViewMode();
        }}
      >
        <If
          when={!isUploading}
          else={<MinimalMediaCardUploadProgress value={uploadProgress} max={100} animated={true} />}
        >
          <StyledMinimalMediaViewer media={{ ...media, index_url: objectUrl || media.index_url }} />

          <StyledMiniMediaCardRemoveButton
            $centeredContent={true}
            color={'light'}
            disabled={isUploading}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              if (beforeDelete && !beforeDelete(e)) {
                return;
              }

              removeMedia();
              onDelete?.(media);
            }}
          >
            <i className={'bx bxs-x-circle'} />
          </StyledMiniMediaCardRemoveButton>
        </If>
      </StyledMiniMediaCardBody>

      <If when={displayName && !canDownload}>{<FileName fileName={media.file_name} />}</If>

      <If when={canDownload}>
        <StyledMiniMediaCardDownloadLink
          onClick={(e) => e.stopPropagation()}
          href={media?.index_url}
          target={'_blank'}
          rel={'noreferrer'}
          className={clsx(!media?.index_url && 'disabled')}
        >
          {displayName ? <FileName fileName={media.file_name} /> : t('download')}
        </StyledMiniMediaCardDownloadLink>
      </If>

      <If when={canView}>
        <MediaModal media={media} size={'lg'} toggle={toggleViewMode} isOpen={viewMode} />
      </If>
    </StyledMiniMediaCard>
  );
};

export { MinimalMediaCardUpload };
