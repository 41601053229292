import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'src/lib/utils';
import { Badge } from 'src/components/ui/badge';
import { useTranslation } from 'react-i18next';
import { requestPriority } from 'src/lib/services/api/request-api';

const priorityBadgeVariants = cva(
  'tw-rounded-lg tw-border-transparent tw-font-medium tw-transition-none',
  {
    variants: {
      variant: {
        [requestPriority.low]: 'tw-bg-blue-400 tw-text-text',
        [requestPriority.medium]: 'tw-bg-yellow-500 tw-text-text',
        [requestPriority.high]: 'tw-bg-orange-400 tw-text-white',
        [requestPriority.critical]: 'tw-bg-red-500 tw-text-white',
        unknown: 'tw-bg-purple-200 tw-text-brand',
      },
      size: {
        sm: 'tw-px-2 tw-py-0.5 tw-text-sm',
        default: 'tw-px-4 tw-py-1 tw-text-base',
      },
    },
    defaultVariants: {
      variant: 'unknown',
      size: 'default',
    },
  },
);

type RequestPriorityBadgeVariantProps = VariantProps<typeof priorityBadgeVariants>;

export type RequestPriorityBadgeProps = React.HTMLAttributes<HTMLDivElement> &
  RequestPriorityBadgeVariantProps;

function RequestPriorityBadge({
  className,
  size,
  variant,
  children,
  ...props
}: RequestPriorityBadgeProps) {
  const { t } = useTranslation();

  return (
    <Badge
      variant={'unset'}
      className={cn(
        priorityBadgeVariants({
          size,
          variant,
        }),
        className,
      )}
      {...props}
    >
      {children ??
        t(`models/request:priority.${variant}`, {
          defaultValue: t('common:unknown', {
            defaultValue: 'Unknown',
          }),
        })}
    </Badge>
  );
}
export { RequestPriorityBadge };
