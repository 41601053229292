import { authQueries, UseQueryOptionsObject } from 'src/api/queries/queries';
import { defaultOptions } from 'src/api/queries/queries.utils';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { ApiQueryObject } from 'src/lib/services/api-query-params';
import { requestClient, RequestsCountsResponse } from 'src/lib/services/api/request-api';

const useRequestsCountsQuery = (
  params?: ApiQueryObject,
  options?: UseQueryOptionsObject<RequestsCountsResponse>,
) => {
  return useMakeQuery<RequestsCountsResponse>(
    [authQueries.requestCounts, params],
    () => requestClient.counts(params),
    {
      staleTime: 60 * 1000,
      ...defaultOptions({
        auth: true,
        ...(options ?? {}),
      }),
    },
  );
};

export { useRequestsCountsQuery };
