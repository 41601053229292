import { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import { Api } from 'src/api';
import { FormMediaObject, FormMediaValue } from './S3UploadInput.types';
import { isMediaArray, isMediaCollection } from './S3UploadInput.utils';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';
import { S3Uploader } from 'src/services/s3-uploader';
import { useShallow } from 'zustand/react/shallow';

const THROTTLE_PROGRESS_UPDATE = 100;

/**
 * @deprecated
 */
const useUploadPresignedUrls = (media: FormMediaObject) => {
  const [uploadSpeed, setUploadSpeed] = useState(0);
  const { setValues } = useFormikContext<{ media: FormMediaValue<string | never> }>();

  const { isUploading, addUpload, updateProgress, finishUpload, uploadDetails } =
    useMediaUploadStore(
      useShallow(({ addUpload, updateProgress, finishUpload, status, details }) => ({
        addUpload,
        updateProgress,
        finishUpload,
        isUploading: !!status[media.id]?.isUploading,
        uploadDetails: details[media.id],
      })),
    );

  const upload = useCallback(
    async (controller?: AbortController, onFail?: (error: any) => void) => {
      if (!media?.upload_id) {
        return;
      }

      if (isUploading) {
        return;
      }

      addUpload({ id: media.id, name: media.file_name, uploadId: media.upload_id });

      const S3 = new S3Uploader(controller);
      const uploadStartTime = Date.now();
      let lastUpdate = uploadStartTime;

      try {
        const uploadedData: { [chunk: number]: number } = {};

        const parts = await S3.upload(
          media.presigned_urls!,
          media.file!,
          (data: ProgressEvent, chunkIndex) => {
            uploadedData[chunkIndex] = data.loaded;

            // throttle progress update to avoid massive amount of store updates and re-renders at once.
            if (Date.now() - lastUpdate < THROTTLE_PROGRESS_UPDATE) {
              return;
            }

            const sum = Object.values(uploadedData).reduce((a, b) => a + b, 0);
            const percent = Math.round((sum / media.file!.size) * 100);

            // Calculate elapsed time
            const currentTime = Date.now();
            const elapsedTime = currentTime - uploadStartTime;

            // Calculate upload speed (bytes per millisecond)
            const uploadSpeed = sum / (elapsedTime / 1000);
            setUploadSpeed(uploadSpeed);

            // Calculate remaining time in milliseconds
            const remainingBytes = media.file!.size - sum;
            const timeLeft = remainingBytes / uploadSpeed;

            updateProgress(media.id, percent, timeLeft);
            lastUpdate = Date.now();
          },
          onFail,
        );

        await Api.media.completeMultipartUpload(
          media.id,
          {
            upload_id: media.upload_id!,
            parts: parts,
          },
          controller,
        );

        const mapFn = (m: FormMediaObject) =>
          m.id !== media.id
            ? m
            : {
                id: m.id,
                file_name: m.file_name,
                mime_type: m.mime_type,
                collection_name: m.collection_name,
                representations: m?.representations ?? [],
              };

        setValues((prev) => {
          if (isMediaArray(prev.media)) {
            return {
              ...prev,
              media: prev.media.map(mapFn),
            };
          }

          if (isMediaCollection(prev.media)) {
            return {
              ...prev,
              media: Object.keys(prev.media).reduce((value, collectionKey) => {
                value[collectionKey] = prev.media[collectionKey].map(mapFn);
                return value;
              }, {} as any),
            };
          }

          return prev;
        });
      } catch (e) {
        throw e;
      } finally {
        finishUpload(media.id);
        setUploadSpeed(0);
      }
    },
    [],
  );

  const remainingTime = uploadDetails?.remainingTime ?? 0;

  const minutesLeft = Math.floor(remainingTime / 60),
    secondsLeft = Math.floor(remainingTime % 60);

  const speedKBs = uploadSpeed / 1024;
  const speedMBs = speedKBs / 1024;

  return {
    isUploading,
    uploadProgress: uploadDetails?.progress ?? 0,
    upload,
    remainingTime: {
      mm: minutesLeft,
      ss: secondsLeft,
      ms: remainingTime,
      text: `Remaining Time: ${minutesLeft} minutes ${secondsLeft} seconds`,
    },
    uploadSpeed: {
      kbs: speedKBs.toFixed(2),
      mbs: speedMBs.toFixed(2),
      bs: uploadSpeed,
    },
  };
};

export { useUploadPresignedUrls };
