import React, { useEffect, useState } from 'react';
import { useRequestOutcomesQueryContext } from 'src/features/requests/request-outcome/use-request-outcomes-query-context';
import { cn } from 'src/lib/utils';
import { DecoratedRequestOutcomeContextProvider } from 'src/features/requests/request-outcome/use-decorated-request-outcome-context';
import { RequestPendingOutcomeCard } from 'src/features/requests/request-page/multi-outcome';

const RequestArchivedOutcomesList: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  const [cardControls, setCardControls] = useState<{ [key: string]: boolean }>({});
  const { data: outcomes, dataUpdatedAt } = useRequestOutcomesQueryContext();

  const openCard = (id: string, isOpen: boolean) => {
    setCardControls((prev) => ({
      ...prev,
      [id]: isOpen,
    }));
  };

  useEffect(() => {
    // if list length did not change, do not update state
    if (outcomes?.items.length === Object.keys(cardControls).length) {
      return;
    }

    // update collapsible card state
    const newControls: typeof cardControls = {};
    outcomes?.items.forEach((outcome) => {
      newControls[outcome.id] = true; // all cards open by default
    });

    setCardControls(newControls);
  }, [outcomes, dataUpdatedAt]);

  return (
    <div
      className={cn('tw-relative tw-flex tw-grow tw-flex-col tw-gap-4 tw-p-4 tw-pb-0', className)}
      {...props}
    >
      <div className={'tw-flex tw-h-full tw-flex-col tw-gap-4'}>
        {outcomes?.items.map((outcome) => (
          <DecoratedRequestOutcomeContextProvider
            key={`outcome-archived-${outcome.id}`}
            outcome={outcome}
          >
            <RequestPendingOutcomeCard
              open={cardControls[outcome.id]}
              onOpenChange={(isOpen) => openCard(outcome.id, isOpen)}
            />
          </DecoratedRequestOutcomeContextProvider>
        ))}
      </div>
    </div>
  );
};

export { RequestArchivedOutcomesList };
