import { AxiosRequestConfig } from 'axios';
import { httpConfig } from 'src/lib/modules/http';
import { Api } from 'src/api';
import { url } from 'src/lib/utils';
import { RateRevisionRequest } from 'src/lib/services/api/revision-api/revision-client.types';

const baseUrl = `${httpConfig.options.baseURL}/revisions`;

const rate = (
  model: string,
  data: RateRevisionRequest,
  config?: AxiosRequestConfig,
): Promise<void> =>
  Api.http.post(url.insertArguments(`${baseUrl}/{model}/rate`, { model }), data, config);

export { rate };
