import React, { useState } from 'react';
import { TabContent } from 'reactstrap';
import {
  Card,
  FlexboxContainer,
  NavItem,
  NavLink,
  Text,
  OrderInfoTabPane,
  OrderInfoTabInfoPaneContent,
  OrderInfoTabPaneGroupItem,
  OrderInfoNavLink,
} from '@becreatives/becreatives-ui';
import { OrderInfoNav, OrderInfoStatusCardGrid } from './RevisionsContentBottom.styled';
import { generatePath, Link } from 'react-router-dom';
import { appRoutes } from 'src/routes/routes.types';
import { OrderInfoMediaNavLink } from './OrderInfoMediaNavLink';
import { If } from 'src/components/If';
import { useTranslation } from 'react-i18next';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { totalLengthToString } from 'src/features/requests/request.helper';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';

const RevisionsContentBottom: React.FC = () => {
  const { t } = useTranslation('attributes/request');

  const [activeTab, setActiveTab] = useState('general');
  const { status = 'draft', editor_note, ...request } = useDecoratedRequestContext();

  const contentFallback = t('not_available', { ns: 'common' })!;

  const tabs = [
    {
      title: t('revisions.order.tab.general', { ns: 'pages/shared' }),
      id: 'general',
    },
    {
      title: t('revisions.order.tab.resolutions', { ns: 'pages/shared' }),
      id: 'resolutions',
    },
    {
      title: t('revisions.order.tab.description', { ns: 'pages/shared' }),
      id: 'description',
    },
    {
      title: t('revisions.order.tab.supporting_materials', { ns: 'pages/shared' }),
      id: 'supporting_materials',
    },
    {
      title: t('revisions.order.tab.extra', { ns: 'pages/shared' }),
      id: 'extra',
    },
  ];

  const tabContent: Record<string, Record<'title' | 'content', any>[]> = {
    general: [
      {
        title: t('type'),
        content: request?.type
          ? t(`type.${request.type}`, { ns: 'models/request' })
          : contentFallback,
      },
      {
        title: t('title'),
        content: request?.title || contentFallback,
      },
      {
        title: t('brand_id'),
        content: !request?.brand ? (
          contentFallback
        ) : (
          <OrderInfoNavLink
            as={Link as any}
            to={generatePath(appRoutes.brand, { brandId: request.brand.id })}
            className={'text-decoration-underline'}
          >
            {request.brand.name}
          </OrderInfoNavLink>
        ),
      },
    ],
    resolutions: [
      {
        title: t('resolutions.main_size'),
        content: request?.resolutions?.main_size || contentFallback,
      },
      {
        title: t('resolutions.resize'),
        content: request?.resolutions?.resize?.join(', ') || contentFallback,
      },
    ],
    description: [
      {
        title: t('description.total_length'),
        content:
          totalLengthToString(request?.total_length) ||
          request?.description?.total_length ||
          contentFallback,
      },
      {
        title: t('description.video_request_description'),
        content: request?.description?.video_request_description || contentFallback,
      },
    ],
    supporting_materials: [
      {
        title: t('media.footage'),
        content: !request?.media?.length ? (
          contentFallback
        ) : (
          <>
            {request.media.map((footage, i) => (
              <OrderInfoMediaNavLink key={i} media={footage}>
                <If when={request!.media!.length - 1 > i}>{','}&nbsp;</If>
              </OrderInfoMediaNavLink>
            ))}
          </>
        ),
      },
      {
        title: t('common:links', {
          defaultValue: 'Links',
        }),
        content: !request?.assets?.length ? (
          contentFallback
        ) : (
          <>
            {request.assets.map((asset, i) => (
              <OrderInfoNavLink
                key={i}
                href={asset.url}
                target={'_blank'}
                rel={'noreferrer'}
                className={'text-truncate'}
                title={asset.url}
              >
                {asset.url}
                <If when={request!.assets!.length - 1 > i}>{','}&nbsp;</If>
              </OrderInfoNavLink>
            ))}
          </>
        ),
      },
      {
        title: t('supporting_materials.music_preference'),
        content: request?.supporting_materials?.music_preference || contentFallback,
      },
    ],
    extra: [
      {
        title: t('extra.subtitles'),
        content: request?.extra?.subtitles || contentFallback,
      },
      {
        title: t('extra.examples'),
        content: request?.extra?.examples || contentFallback,
      },
    ],
  };

  return (
    <FlexboxContainer $direction={'column'}>
      <h3 className="tw-pb-2 tw-text-2xl tw-font-medium md:tw-pb-4">Request info</h3>

      <Card color={'white'}>
        <OrderInfoStatusCardGrid>
          <Text tag={'b'}>Status</Text>
          <div>
            <RequestStatusBadge variant={status}>
              {t(`models/request:status.${status}`, {
                defaultValue: t('common:unknown', {
                  defaultValue: 'Unknown',
                }),
              })}
            </RequestStatusBadge>
          </div>

          <Text tag={'b'}>Editors note:</Text>
          <Text>{editor_note || t('common:unset')}</Text>
        </OrderInfoStatusCardGrid>
      </Card>

      <Card color={'white'}>
        <OrderInfoNav>
          {tabs.map((tab, i) => (
            <NavItem key={i}>
              <NavLink
                active={tab.id === activeTab}
                onClick={() => setActiveTab(tab.id)}
                color={'primary'}
                className={'py-2 py-md-3'}
              >
                {tab.title}
              </NavLink>
            </NavItem>
          ))}
        </OrderInfoNav>
        <TabContent activeTab={activeTab}>
          {tabs.map((tab, i) => (
            <OrderInfoTabPane key={i} tabId={tab.id}>
              <OrderInfoTabInfoPaneContent>
                {tabContent[tab.id].map((content, j) => (
                  <OrderInfoTabPaneGroupItem key={j}>
                    <Text tag={'b'}>{content.title}</Text>
                    <Text tag={'span'}>{content.content}</Text>
                  </OrderInfoTabPaneGroupItem>
                ))}
              </OrderInfoTabInfoPaneContent>
            </OrderInfoTabPane>
          ))}
        </TabContent>
      </Card>
    </FlexboxContainer>
  );
};

export { RevisionsContentBottom };
