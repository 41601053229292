import { useParams } from 'react-router-dom';
import { authQueries, useRequestQuery } from 'src/api/queries';
import { notify } from 'src/components/Toast';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { ExtraSubtitlesOptions } from 'src/helpers/request_types';
import { requestClient, requestStatus, RequestStatus } from 'src/lib/services/api/request-api';

let initialized = false;

const useRequest = (requestId: string) => {
  const { t } = useTranslation('alerts');
  const client = useQueryClient();

  const [enabled, setEnabled] = useState(false);
  const { data: request, ...query } = useRequestQuery(requestId, {
    enabled,
  });

  const isOfStatus = (status: RequestStatus | RequestStatus[]): boolean => {
    if (Array.isArray(status)) {
      return status.includes(request?.status as any);
    }

    return status === request?.status;
  };

  const hasRevisions = !!request?.revisions.length,
    hasAssignedEditor = !!request?.assigned_editor?.id,
    hasVideoRevisions = !!request?.revisions?.find((r) => r.type === 'revision'),
    hasFinalFiles = !!request?.revisions?.find((r) => r.type === 'final_files'),
    isDraft = isOfStatus(requestStatus.draft),
    isQueued = isOfStatus(requestStatus.queued),
    isEditing = isOfStatus(requestStatus.currentlyEditing),
    isDelivered = isOfStatus(requestStatus.delivered),
    isCompleted = isOfStatus(requestStatus.complete),
    needsReediting = !!request?.requested_reedit_at,
    needsAttention = !!request?.requested_attention_at,
    needsResizes = !!request?.resolutions?.resize?.length,
    needsCaptionsFile = request?.extra?.subtitles === ExtraSubtitlesOptions.separateSrt,
    isApproved = !!request?.approved_at,
    isEditable = isDraft || isQueued || needsAttention,
    isDeletable = !hasAssignedEditor && (isDraft || isQueued),
    canRequestReEdit = isDelivered && !needsReediting && !isApproved,
    canComplete =
      isDelivered && (isApproved || (!needsReediting && !needsResizes && !needsCaptionsFile)),
    canApprove = isDelivered && !isApproved && !needsReediting && !canComplete,
    exists = !!request,
    canRateRevision = !request?.rated_revisions_count,
    editor = request?.assigned_editor;

  const changeStatus = async (status: RequestStatus): Promise<void> => {
    await requestClient
      .changeStatus(request!.id, {
        status,
      })
      .then(() => notify(t('success.request_status_change')))
      .then(() => {
        query.setQueryData({ status });
      })
      .catch(() => ({}));
  };

  const approve = async (): Promise<void> => {
    await requestClient
      .approve(request!.id)
      .then(() => {
        query.setQueryData({ approved_at: new Date().toISOString() });
        client.invalidateQueries({ queryKey: [authQueries.requests, request?.status] });
      })
      .catch(() => ({}));
  };

  const requestReedit = async (): Promise<void> => {
    await requestClient.requestReedit(request!.id).then(() => {
      query.setQueryData({ requested_reedit_at: new Date().toISOString() });
      client.invalidateQueries({ queryKey: [authQueries.requests, request?.status] });
      if (request?.parent_id) {
        client.invalidateQueries({ queryKey: [authQueries.requestOutcomes, request.parent_id] });
      }
    });
  };

  const complete = async (): Promise<void> => {
    await requestClient
      .complete(request!.id)
      .then(() => notify(t('success.request_status_change')))
      .then(() => {
        query.setQueryData({ status: requestStatus.complete });
        client.invalidateQueries({
          queryKey: [authQueries.requests, requestStatus.delivered],
        });
        client.invalidateQueries({ queryKey: [authQueries.requests, requestStatus.complete] });
        if (request?.parent_id) {
          client.invalidateQueries({ queryKey: [authQueries.requestOutcomes, request.parent_id] });
        }
      });
  };

  const init = async (): Promise<void> => {
    if (initialized) {
      return;
    }

    await query.refetch();
    initialized = true;
    setEnabled(true);
  };

  const reset = (): void => {
    query.invalidate();
    initialized = false;
    setEnabled(false);
  };

  return {
    ...(request ?? {}),
    isDraft,
    isQueued,
    isEditing,
    isDelivered,
    needsReediting,
    needsAttention,
    needsResizes,
    isApproved,
    isCompleted,
    isEditable,
    isDeletable,
    canRequestReEdit,
    canComplete,
    canApprove,
    hasRevisions,
    hasVideoRevisions,
    hasFinalFiles,
    hasAssignedEditor,
    exists,
    canRateRevision,
    editor,
    query,
    isOfStatus,
    changeStatus,
    init,
    reset,
    approve,
    requestReedit,
    complete,
  };
};

const useCurrentRequest = () => {
  const { requestId } = useParams<{ requestId: string }>();

  return useRequest(requestId!);
};

export { useRequest, useCurrentRequest };
