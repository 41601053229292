import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { initSentry } from 'src/startup';
import { Providers } from './providers';
import { FullPageSpinner } from './components/Spinner';
import App from './App';
import './index.css';
import 'src/services/i18n/i18n';

initSentry();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Providers>
      <Suspense fallback={<FullPageSpinner />}>
        <App />
      </Suspense>
    </Providers>
  </React.StrictMode>,
);

