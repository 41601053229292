import { getObjectKeys } from 'src/helpers/getObjectKeys';
import { TotalLengthResponse } from 'src/lib/services/api/request-api';
import { ValueOfObject } from 'src/types/generics';

const requestTypes = {
  Unknown: '',
  Ad: 'ad',
  CourseVideo: 'course_video',
  MotionGraphics: 'motion_graphics',
  Podcast: 'podcast',
  Reel: 'reel',
  Story: 'story',
  TalkingHead: 'talking_head',
  Tiktok: 'tiktok',
  Tutorial: 'tutorial',
  Vlog: 'vlog',
  YtVideo: 'yt_video',
  YtShorts: 'yt_shorts',
  Other: 'other',
} as const;

type RequestTypes = ValueOfObject<typeof requestTypes>;

export interface RequestInput<T> {
  attributes: T;
  types: RequestTypes[];
}

interface FootageField
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  required?: boolean;
}

const buildSelectOption = (label: string, value?: string | number | null): SelectInputOption => ({
  label: label,
  value: value ?? label,
});

export interface SelectInputOption {
  label: string;
  value?: string | number | null | undefined;
}

export type SelectRequestInput = RequestInput<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
    error?: string | undefined;
    label?: string;
    options?: SelectInputOption[];
    informativeText?: string | JSX.Element | any;
  }
>;
export type CommonRequestInput = RequestInput<FootageField>;

const includeTypes = (types: RequestTypes[]) => {
  const available: RequestTypes[] = [];
  Object.values(requestTypes).forEach((type) => {
    if (types.includes(type)) {
      available.push(type);
    }
  });

  return available;
};

export const ExtraSubtitlesOptions = {
  burnedIn: 'Yes, burned in captions',
  separateSrt: 'Yes, separate .srt file for Youtube',
  no: 'No',
  idk: "I don't know",
};

// Description.total_length utils
const buildLength = (time: number, units: string): string => `up to ${time} ${units}`;
const possibleLengths = {
  s10: buildSelectOption(buildLength(10, 's')),
  s15: buildSelectOption(buildLength(15, 's')),
  s30: buildSelectOption(buildLength(30, 's')),
  s60: buildSelectOption(buildLength(60, 's')),
  min3: buildSelectOption(buildLength(3, 'min')),
  min5: buildSelectOption(buildLength(5, 'min')),
  min10: buildSelectOption(buildLength(10, 'min')),
  min15: buildSelectOption(buildLength(15, 'min')),
  min20: buildSelectOption(buildLength(20, 'min')),
};

const lengthKeyToValue = {
  s10: {
    value: 10,
    unit: 'sec',
  },
  s15: {
    value: 15,
    unit: 'sec',
  },
  s30: {
    value: 30,
    unit: 'sec',
  },
  s60: {
    value: 60,
    unit: 'sec',
  },
  min3: {
    value: 3,
    unit: 'min',
  },
  min5: {
    value: 5,
    unit: 'min',
  },
  min10: {
    value: 10,
    unit: 'min',
  },
  min15: {
    value: 15,
    unit: 'min',
  },
  min20: {
    value: 20,
    unit: 'min',
  },
};

export const lengthStringToObject = (value: string) => {
  const key = getObjectKeys(possibleLengths).find((key) => possibleLengths[key].value === value);

  if (key ?? '' in lengthKeyToValue) {
    return lengthKeyToValue[key!];
  }

  return undefined;
};

export const lengthObjectToString = (value?: TotalLengthResponse) => {
  if (!value) {
    return undefined;
  }

  const key = getObjectKeys(lengthKeyToValue).find(
    (key) =>
      lengthKeyToValue[key].value === value.value && lengthKeyToValue[key].unit === value.unit,
  );

  if (!key) {
    return undefined;
  }

  return possibleLengths[key].value;
};

export const DescriptionTotalLength: SelectRequestInput[] = [
  {
    attributes: {
      required: true,
      options: [possibleLengths.s10, possibleLengths.s15, possibleLengths.s30, possibleLengths.s60],
    },
    types: includeTypes([requestTypes.Ad]),
  },
  {
    attributes: {
      required: true,
      options: [
        possibleLengths.s30,
        possibleLengths.s60,
        possibleLengths.min3,
        possibleLengths.min5,
        possibleLengths.min10,
      ],
    },
    types: includeTypes([requestTypes.TalkingHead]),
  },
  {
    attributes: {
      required: true,
      options: [
        possibleLengths.min5,
        possibleLengths.min10,
        possibleLengths.min15,
        possibleLengths.min20,
      ],
    },
    types: includeTypes([
      requestTypes.YtVideo,
      requestTypes.Podcast,
      requestTypes.Vlog,
      requestTypes.CourseVideo,
      requestTypes.Tutorial,
      requestTypes.MotionGraphics,
    ]),
  },
  {
    attributes: {
      required: true,
      options: [
        possibleLengths.s10,
        possibleLengths.s15,
        possibleLengths.s30,
        possibleLengths.s60,
        possibleLengths.min3,
        possibleLengths.min5,
      ],
    },
    types: includeTypes([
      requestTypes.Reel,
      requestTypes.Tiktok,
      requestTypes.YtShorts,
      requestTypes.Story,
    ]),
  },
  {
    attributes: {
      required: true,
      options: [
        possibleLengths.s10,
        possibleLengths.s15,
        possibleLengths.s30,
        possibleLengths.s60,
        possibleLengths.min3,
        possibleLengths.min5,
      ],
    },
    types: includeTypes([requestTypes.Other]),
  },
];
