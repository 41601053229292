import React, { CSSProperties, MouseEvent, useLayoutEffect, useRef, useState } from 'react';
import { cn } from 'src/lib/utils';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { useVideoProgressState } from 'src/features/video-player/use-video-progress-store';
import { useVideoPlayerV2 } from 'src/features/video-player/video-player';
import { useVideoPlayerProgress } from 'src/features/video-player/use-video-player-progress';
import {
  formatTime,
  getVideoProgressbarCursorPosition,
  secondsToFraction,
} from 'src/features/video-player/video-player-utils';
import { MediaSpriteResponse } from 'src/api/services/MediaClient';

const VideoProgressSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
    sprite?: MediaSpriteResponse | null;
  }
>(({ className, sprite, ...props }, ref) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const store = useVideoProgressState();
  const { seekSeconds } = useVideoPlayerProgress();
  const { meta, timeFormat } = useVideoPlayerV2();
  const [previewStyles, setPreviewStyles] = useState<CSSProperties>({});
  const [seekTarget, setSeekTarget] = useState(0);

  const previewContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!sprite) {
      return;
    }

    setPreviewStyles({
      width: `${sprite.width}px`,
      height: `${sprite.height}px`,
      backgroundRepeat: 'no-repeat',
    });
  }, [sprite]);

  const { durationInSeconds, fps } = meta;

  const setSpritePreviewPosition = (seconds: number) => {
    if (!sprite) {
      return;
    }

    // Calculate which image to show in the sprite based on the hovered seconds
    const frameNumber = Math.floor((seconds / sprite.interval) % sprite.grid_size ** 2);
    // Calculate which sprite file to use based on the hovered seconds
    const spriteFile = Math.floor(seconds / sprite.interval / sprite.grid_size ** 2);

    // Find the x and y positions in the sprite
    const xPosition = frameNumber % sprite.grid_size;
    const yPosition = Math.floor(frameNumber / sprite.grid_size);

    // Calculate the offset for the sprite
    const xOffset = xPosition * sprite.width;
    const yOffset = yPosition * sprite.height;

    setPreviewStyles((prev) => ({
      ...prev,
      backgroundImage: `url(${sprite.files[spriteFile]})`,
      backgroundPosition: `-${xOffset}px -${yOffset}px`,
    }));
  };

  const handlePreviewContainerPosition = (seconds: number) => {
    if (!previewContainerRef.current) {
      return;
    }

    const target = secondsToFraction(seconds, durationInSeconds);

    const { left: start, right: end } = sliderRef.current?.getBoundingClientRect() ?? {
      left: 0,
      right: 1,
    };
    const imgWidth = previewContainerRef.current.clientWidth;
    const margin = target * (end - start) - imgWidth / 2;

    if (margin <= 0) {
      previewContainerRef.current.style.marginLeft = `0px`;
      return;
    }

    const max = end - start - imgWidth;
    if (max < margin) {
      previewContainerRef.current.style.marginLeft = `${max}px`;
      return;
    }

    previewContainerRef.current.style.marginLeft = `${margin}px`;
  };

  const cursorInfoBoxStyles = sprite
    ? {
        width: `${previewStyles?.width ?? '160px'}`,
        height: `calc(${previewStyles?.height ?? '90px'} + 40px)`,
        top: `calc((${previewStyles?.height ?? '90px'} + 40px) * -1)`,
      }
    : {};

  const formattedSeekTarget = formatTime(seekTarget, {
    format: timeFormat,
    fps: fps,
  });

  const handleValueChange = ([seconds]: [number]) => {
    seekSeconds(seconds);
    setSeekTarget(seconds);
    setSpritePreviewPosition(seconds);
    handlePreviewContainerPosition(seconds);
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    const duration = getVideoProgressbarCursorPosition(e, durationInSeconds);
    setSeekTarget(duration);
    setSpritePreviewPosition(duration);
    handlePreviewContainerPosition(duration);
  };

  return (
    <SliderPrimitive.Root
      ref={sliderRef}
      className={cn(
        'tw-group tw-relative tw-flex tw-w-full tw-cursor-pointer tw-touch-none tw-select-none tw-items-center',
        className,
      )}
      onValueChange={handleValueChange}
      value={[store.playedSeconds]}
      min={0}
      max={meta.durationInSeconds}
      step={1 / meta.fps}
      onMouseMove={handleMouseMove}
      {...props}
    >
      <SliderPrimitive.Track className="tw-relative tw-h-1 tw-w-full tw-grow tw-overflow-hidden tw-rounded-sm tw-bg-stone-100 tw-transition-[height] tw-duration-150 group-hover:tw-h-2">
        {/*// Loaded range*/}
        <div
          className={'tw-absolute tw-h-full tw-w-full tw-rounded-e tw-bg-stone-400'}
          style={{
            width: `${store.loaded * 100}%`,
          }}
        />
        <SliderPrimitive.Range className="tw-absolute tw-h-full tw-bg-primary" />
      </SliderPrimitive.Track>

      {props.children}

      <div
        className={cn(
          'tw-pointer-events-none tw-absolute tw-bottom-5 tw-z-40 tw-hidden tw-h-[80px] tw-w-[130px] tw-flex-col tw-gap-1 group-hover:md:tw-flex',
        )}
        ref={previewContainerRef}
        style={cursorInfoBoxStyles}
      >
        {!!sprite && (
          <div
            className={'tw-rounded-md tw-border-2 tw-border-light'}
            style={{
              ...previewStyles,
            }}
          />
        )}
        <span
          className={
            'tw-mb-1 tw-mt-auto tw-self-center tw-rounded-md tw-bg-dark tw-px-2 tw-py-1 tw-text-light'
          }
        >
          {formattedSeekTarget}
        </span>
      </div>

      <SliderPrimitive.Thumb className="tw-block tw-size-3 tw-rounded-full tw-border-2 tw-border-primary tw-bg-background tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-1 disabled:tw-pointer-events-none disabled:tw-opacity-50 md:tw-hidden" />
    </SliderPrimitive.Root>
  );
});
VideoProgressSlider.displayName = 'VideoProgressSlider';

export { VideoProgressSlider };
