import React from 'react';
import { FormikProps, useFormikContext } from 'formik';
import { DomUtils } from 'src/helpers/DomUtils';

const extractFormErrors = (error: any): Record<string, any> | undefined => error.errors?.form;

// react-hook-form supports nested field names as dots, use this function with the new form
const extractHookFormErrors = (error: any): Record<string, any> | undefined =>
  error.errors?.['form_v2'];

const phoneNumberRegex =
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

const passwordRegex =
  /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;

const handleTextareaResize = ({ currentTarget }: React.KeyboardEvent<HTMLTextAreaElement>) => {
  const getPropertyInt = (prop: string) =>
    parseInt(window.getComputedStyle(currentTarget).getPropertyValue(prop), 10);

  currentTarget.style.height = '1px';

  currentTarget.style.height = `${currentTarget.scrollHeight}px`;

  currentTarget.style.overflowY = getPropertyInt('height') >= 170 ? 'auto' : 'hidden';
};

const scrollIntoInvalidFeedback = () => {
  const invalidFeedbackDOM = DomUtils.getFirstOfClass('invalid-feedback');

  if (!invalidFeedbackDOM) {
    return;
  }

  DomUtils.scrollToElement(invalidFeedbackDOM, -150);
};

const scrollIntoValidationError = () => {
  const invalidFeedbackDOM = DomUtils.getFirstOfClass('validation-error');

  if (!invalidFeedbackDOM) {
    return;
  }

  DomUtils.scrollToElement(invalidFeedbackDOM, -150);
};

const touchFormFields = (errors: Record<string, any>, touched: Record<string, any>) => {
  Object.keys(errors).forEach((errorKey) => {
    const value = errors[errorKey];
    const isObject = typeof value === 'object' && value !== null && !Array.isArray(value);
    if (isObject) {
      touched[errorKey] = {};
      touchFormFields(value, touched[errorKey]);
    } else {
      touched[errorKey] = true;
    }
  });
};

const handleFormikSubmit = async <Values extends Record<string, any>>(
  props: FormikProps<Values>,
): Promise<boolean> => {
  const errors = await props.validateForm!();
  const keys = Object.keys(errors);

  if (keys.length) {
    const touched = {};
    touchFormFields(errors, touched);
    props.setTouched(touched);
    scrollIntoInvalidFeedback();

    return false;
  }

  const success = await props.submitForm();
  if (typeof success === 'boolean') {
    return success;
  } else {
    return true;
  }
};

const useFormikSubmit = <Values extends Record<string, any>>() => {
  const props = useFormikContext<Values>();

  return {
    handleFormikSubmit: () => {
      return handleFormikSubmit(props);
    },
  };
};

export {
  extractFormErrors,
  extractHookFormErrors,
  phoneNumberRegex,
  passwordRegex,
  handleTextareaResize,
  handleFormikSubmit,
  scrollIntoInvalidFeedback,
  scrollIntoValidationError,
  useFormikSubmit,
  touchFormFields,
};
