import React from 'react';
import { cn } from 'src/lib/utils';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>>;

const FullPageWrapper: React.FC<Props> = ({ className, children, ...props }) => {
  return (
    <div
      className={cn('tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-overflow-auto', className)}
      {...props}
    >
      {children}
    </div>
  );
};

export default FullPageWrapper;
