import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertDescriptionStrong,
  AlertIcon,
  AlertProps,
  AlertTitle,
} from 'src/components/ui/alert';
import { X } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { cva } from 'class-variance-authority';
import { cn } from 'src/lib/utils';
import { If } from 'src/components/If';

export const alertButtonVariants = cva('tw-rounded-lg tw-p-1 tw-font-medium', {
  variants: {
    variant: {
      default: 'hover:tw-bg-foreground/20',
      destructive: 'hover:tw-bg-orange-300',
      warning: 'hover:tw-bg-yellow-300',
      success: 'hover:tw-bg-green-300',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type AlertDismissibleProps = AlertProps & {
  title?: string;
  message: string;
  strong?: boolean;
  dismissible?: boolean;
  onDismiss?: () => void;
};

const AlertDismissible = React.forwardRef<HTMLDivElement, AlertDismissibleProps>(
  (
    {
      className,
      variant,
      children,
      strong,
      title,
      message,
      dismissible = true,
      onDismiss,
      ...props
    },
    ref,
  ) => {
    const [show, setShow] = useState(true);
    const [currentState, setCurrentState] = useState({
      isExpanded: false,
      isClamped: false,
    });
    const descriptionRef = React.useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (!descriptionRef.current) {
        return;
      }

      // observe the description element for resize changes
      const observer = new ResizeObserver((e) => {
        setCurrentState((prev) => ({
          ...prev,
          isClamped: e[0].target.scrollHeight > e[0].target.clientHeight, // way to check if the text is clamped
        }));
      });

      observer.observe(descriptionRef.current);

      return () => {
        observer.disconnect();
      };
    }, [descriptionRef]);

    if (!show) {
      return null;
    }

    return (
      <Alert {...props} ref={ref} variant={variant} className={cn('tw-flex tw-gap-3', className)}>
        <div>
          <AlertIcon variant={variant} />
        </div>
        <div className={'tw-flex tw-min-w-0 tw-flex-col tw-gap-3'}>
          <div>
            <If
              when={!strong}
              else={
                <AlertDescriptionStrong
                  ref={descriptionRef}
                  className={cn('tw-break-words tw-leading-5', {
                    'tw-line-clamp-5': !currentState.isExpanded,
                  })}
                >
                  {message}
                </AlertDescriptionStrong>
              }
            >
              {title && <AlertTitle className={'tw-text-lg tw-font-bold'}>{title}</AlertTitle>}
              <AlertDescription
                ref={descriptionRef}
                className={cn('tw-break-words tw-leading-5', {
                  'tw-line-clamp-5': !currentState.isExpanded,
                })}
              >
                {message}
              </AlertDescription>
            </If>
            <div>
              {currentState.isClamped && !currentState.isExpanded && (
                <div className={'tw-flex tw-pt-1'}>
                  <Button
                    type={'button'}
                    variant={'unset'}
                    size={'unset'}
                    className={cn(alertButtonVariants({ variant }))}
                    onClick={() =>
                      setCurrentState((prev) => ({
                        ...prev,
                        isExpanded: true,
                      }))
                    }
                  >
                    Show more
                  </Button>
                </div>
              )}
              {currentState.isExpanded && (
                <div className={'tw-flex tw-pt-1'}>
                  <Button
                    type={'button'}
                    variant={'unset'}
                    size={'unset'}
                    className={cn(alertButtonVariants({ variant }))}
                    onClick={() =>
                      setCurrentState((prev) => ({
                        ...prev,
                        isExpanded: false,
                      }))
                    }
                  >
                    Show less
                  </Button>
                </div>
              )}
            </div>
          </div>
          {children}
        </div>
        {dismissible && (
          <div className={'tw-ms-auto'}>
            <Button
              type={'button'}
              variant={'unset'}
              size={'unset'}
              className={cn(alertButtonVariants({ variant }))}
              onClick={() => {
                setShow(false);
                onDismiss?.();
              }}
            >
              <X size={20} />
            </Button>
          </div>
        )}
      </Alert>
    );
  },
);
AlertDismissible.displayName = 'AlertDismissible';

export { AlertDismissible };
