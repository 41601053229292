import { useCallback, useState } from 'react';
import { Api } from 'src/api';
import { useFormikContext } from 'formik';
import { FormMediaValue } from './S3UploadInput.types';
import { isMediaArray, isMediaCollection } from './S3UploadInput.utils';
import { calculateChunks } from 'src/services/s3-uploader';

const useGenerateMultipartUpload = () => {
  const [isGenerating, setGenerating] = useState<boolean>(false);
  const { setValues } = useFormikContext<{ media: FormMediaValue<any> }>();

  const generateUpload = useCallback(
    async (model: string, files: FileList | File[], collection_name?: string) => {
      setGenerating(true);

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const data = {
          mime_type: file.type,
          name: file.name,
          size: file.size,
          chunks: calculateChunks(file.size),
          collection_name: collection_name,
        };

        try {
          const { upload_id, presigned_urls, media } = await Api.media.generateMultipartUpload(
            model,
            data,
          );

          const formMediaObj = { ...media, upload_id, presigned_urls, file };

          setValues((prev) => {
            if (isMediaArray(prev.media)) {
              return {
                ...prev,
                media: [...prev.media, formMediaObj],
              };
            }

            if (isMediaCollection(prev.media)) {
              return {
                ...prev,
                media: {
                  ...prev.media,
                  [collection_name!]: [...prev.media[collection_name!], formMediaObj],
                },
              };
            }

            return prev;
          });
        } catch (e: any) {
          setGenerating(false);
          throw e;
        }
      }

      setGenerating(false);
    },
    [],
  );

  return {
    generateUpload,
    isGenerating,
  };
};

export { useGenerateMultipartUpload };
