import { CANNY_APP_ID } from 'src/env.json';
import * as canny from './canny';

export const useCanny = () => {
  const identify = (user: Parameters<typeof canny.identify>[0]['user']) => {
    canny.identify({
      appID: CANNY_APP_ID,
      user,
    });
  };

  return {
    ...canny,
    identify,
  };
};
