import { Client, SuccessResponse } from 'src/api/Client';
import { NotificationResponse, UnreadNotificationCountResponse } from './NotificationClient.types';
import { PaginationData } from 'src/types';
import { AxiosRequestConfig } from 'axios';

class NotificationClient extends Client {
  private readonly Route = {
    FETCH_NOTIFICATIONS: `${this.http.baseUrl}/notifications`,
    FETCH_UNREAD_NOTIFICATIONS_COUNT: `${this.http.baseUrl}/notifications/unread_count`,
    MARK_NOTIFICATION_AS_READ: `${this.http.baseUrl}/notifications/{notification}/read`,
    MARK_ALL_NOTIFICATION_AS_READ: `${this.http.baseUrl}/notifications/read_all`,
  };

  public fetchNotifications(
    config?: AxiosRequestConfig,
  ): Promise<PaginationData<NotificationResponse>> {
    return this.http.get(this.Route.FETCH_NOTIFICATIONS, config);
  }

  public fetchUnreadNotificationsCount(
    config?: AxiosRequestConfig,
  ): Promise<UnreadNotificationCountResponse> {
    return this.http.get(this.Route.FETCH_UNREAD_NOTIFICATIONS_COUNT, config);
  }

  public markNotificationAsRead(notification: string): Promise<NotificationResponse> {
    return this.http.put(this.buildUrl(this.Route.MARK_NOTIFICATION_AS_READ, { notification }));
  }

  public markAllNotificationAsRead(): Promise<SuccessResponse> {
    return this.http.post(this.Route.MARK_ALL_NOTIFICATION_AS_READ);
  }
}

export default NotificationClient;
