import axios, { AxiosError, AxiosRequestConfig } from 'axios';

type AxiosHttpError = AxiosError<{
  message?: string;
  error?: string;
  errors?: {
    form: Record<string, string[]>;
  };
}>;

interface InterceptableAxiosRequestConfig extends AxiosRequestConfig {
  _noIntercept?: boolean;
}

type FormErrorsResponse = Record<string, string[]>;

type HttpErrorResponse<T = unknown> = {
  message: string;
  code: number | string;
  errors?: {
    form: T;
  };
};

const isAxiosHttpError = (error: unknown): error is AxiosHttpError => {
  return axios.isAxiosError(error) as boolean;
};

const isCancelError = (error: any): boolean => {
  return error.code === 'ERR_CANCELED';
};

export type {
  AxiosHttpError,
  FormErrorsResponse,
  HttpErrorResponse,
  InterceptableAxiosRequestConfig,
};
export { isAxiosHttpError, isCancelError };
