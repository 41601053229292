import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { If } from 'src/components/If';
import { FullPageSpinner } from 'src/components/Spinner';
import AppUpdateDialog from 'src/features/sw-update/app-update-dialog';
import { useDecoratedPostHog } from 'src/providers/posthog';
import { ReactQueryParamsProvider } from 'src/providers/ReactQueryParamsProvider';
import { useAuth } from 'src/services/Auth';
import { useGlobalLoadingStore } from 'src/stores';

let tokenRefreshed = false;

const AuthLayout = () => {
  const { mount, dismount, refresh, isInitialLoading } = useAuth();
  const { isGlobalLoading, setGlobalLoading } = useGlobalLoadingStore();
  const postHog = useDecoratedPostHog();
  const location = useLocation();

  const [currentLocation, setCurrentLocation] = useState(location.pathname);

  useEffect(() => {
    if (!tokenRefreshed) {
      refresh().then(() => setTimeout(() => setGlobalLoading(false), 500));

      tokenRefreshed = true;
    }

    mount();
    return () => {
      dismount();
    };
  }, []);

  useEffect(() => {
    if (currentLocation !== location.pathname) {
      postHog.capture('$pageview');
      setCurrentLocation(location.pathname);
    }
  }, [location.pathname]);

  return (
    <React.Fragment>
      <If
        when={isGlobalLoading || isInitialLoading}
        else={
          <ReactQueryParamsProvider>
            <AppUpdateDialog />
            <Outlet />
          </ReactQueryParamsProvider>
        }
      >
        <FullPageSpinner />
      </If>
    </React.Fragment>
  );
};

export default AuthLayout;
