const defaultNamespaces = [
  'attributes/company',
  'attributes/request',
  'attributes/subscription',
  'attributes/subscription_product',
  'attributes/subscription_assignment',
  'attributes/subscription_cancellation',
  'attributes/user',
  'attributes/common',
  'attributes/task',
  'attributes/team',
  'attributes/team_member',

  'models/brand',
  'models/company',
  'models/notification',
  'models/request',
  'models/revision',
  'models/revision_comment',
  'models/subscription',
  'models/user',
  'models/role',
  'models/task',
  'models/team',
  'models/team_member',

  'pages/shared',
  'pages/space',

  'actions',
  'alerts',
  'auth',
  'common',
  'pagination',
  'passwords',
  'validation',
  'dates',
  'links',
] as const;

type DefaultNamespaces = (typeof defaultNamespaces)[number];

type NamespaceTranslationsResponse = Record<string, string>;

type TranslationsResponse = {
  [namespace: string | DefaultNamespaces]: NamespaceTranslationsResponse;
};

export type { DefaultNamespaces, NamespaceTranslationsResponse, TranslationsResponse };
export { defaultNamespaces };
