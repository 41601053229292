import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'src/lib/utils';

const buttonVariants = cva(
  'tw-inline-flex tw-items-center tw-justify-center tw-rounded-lg tw-font-medium tw-ring-offset-background tw-transition-all focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-bg-inactive disabled:tw-text-inactive-foreground data-[disabled=true]:tw-pointer-events-none data-[disabled=true]:tw-bg-inactive data-[disabled=true]:tw-text-inactive-foreground',
  {
    variants: {
      variant: {
        default: 'tw-bg-primary tw-text-primary-foreground hover:tw-bg-primary/90',
        destructive: 'tw-bg-destructive tw-text-destructive-foreground hover:tw-bg-destructive/80',
        destructiveLight: 'hover:tw-bg-destructive-300 hover:tw-text-destructive',
        destructiveOutline:
          'tw-border tw-border-input tw-bg-transparent hover:tw-border-destructive hover:tw-bg-orange-200 hover:tw-text-destructive',
        outline:
          'tw-border tw-bg-background tw-text-text hover:tw-bg-background hover:tw-shadow hover:tw-shadow-violet-200',
        secondary: 'tw-bg-secondary tw-text-secondary-foreground hover:tw-bg-secondary/90',
        secondaryOutline:
          'tw-border tw-border-secondary tw-bg-transparent tw-text-secondary hover:tw-bg-secondary hover:tw-text-secondary-foreground',
        ghost: 'tw-text-text hover:tw-bg-accent hover:tw-text-accent-foreground',
        link: 'tw-text-primary tw-underline-offset-4 hover:tw-underline',
        muted: 'tw-bg-neutral-200 tw-text-muted-foreground hover:tw-bg-neutral-200/90',
        sky: 'tw-bg-blue-300 tw-text-text hover:tw-bg-blue-400',
        brand: 'tw-bg-brand tw-text-brand-foreground hover:tw-bg-brand-hover',
        brandSubtle:
          'tw-bg-brand-subtle tw-text-brand-subtle-foreground hover:tw-bg-brand-subtle/70',
        brandLight: 'tw-bg-brand-400 tw-text-white',
        accent1:
          'tw-border tw-border-purple-300 tw-bg-purple-200 tw-text-text hover:tw-bg-purple-300 disabled:tw-border-inactive',
        text: 'tw-text-brand hover:tw-bg-purple-200',
        transparent: 'tw-bg-neutral-400/70 tw-text-white',
        unset: '',
      },
      size: {
        default: 'tw-px-4 tw-py-2',
        sm: 'tw-h-9 tw-px-3',
        lg: 'tw-h-11 tw-px-6',
        icon: 'tw-h-10 tw-w-10',
        iconSm: 'tw-h-7 tw-w-7',
        iconXs: 'tw-h-6 tw-w-6',

        small: 'tw-p-2',
        big: 'tw-h-11 tw-px-6',
        responsive: 'tw-px-4 tw-py-2 md:tw-h-11 md:tw-px-6',
        iconResponsive: 'tw-p-2 md:tw-h-11 md:tw-px-6',
        iconStart: 'tw-gap-2 tw-py-3 tw-pe-6 tw-ps-4',
        iconStartResponsive: 'tw-gap-2 tw-py-2 tw-pe-4 tw-ps-2 md:tw-py-3 md:tw-pe-6 md:tw-ps-4',
        iconStartSm: 'tw-gap-2 tw-py-2 tw-pe-4 tw-ps-2',
        iconStartSmResponsive: 'tw-gap-2 tw-p-2 md:tw-pe-4',
        iconEnd: 'tw-gap-2 tw-py-3 tw-pe-4 tw-ps-6',
        iconEndResponsive: 'tw-gap-2 tw-py-2 tw-pe-2 tw-ps-4 md:tw-py-3 md:tw-pe-4 md:tw-ps-6',
        iconEndSm: 'tw-gap-2 tw-py-2 tw-pe-2 tw-ps-4',
        iconEndSmResponsive: 'tw-gap-2 tw-p-2 md:tw-ps-4',
        unset: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
