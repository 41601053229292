import React, { ComponentProps } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogTitle } from 'src/components/ui/dialog';
import RepurposePlaceholderImage from 'src/assets/images/repurpose-placeholder.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/ui/button';
import { useRepurposeRequestMutation } from './use-repurpose-request-mutation';

type Props = {
  requestId?: string;
  onSubmit?: () => void;
};

const RepurposeConfirmDialog: React.FC<ComponentProps<typeof Dialog> & Props> = ({
  open,
  onOpenChange,
  requestId,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const mutateRepurposeRequest = useRepurposeRequestMutation();

  const confirm = () => {
    mutateRepurposeRequest.mutate(requestId!, {
      onSuccess: () => {
        onSubmit?.();
        onOpenChange?.(false);
      },
    });
  };

  return (
    <Dialog modal open={open} onOpenChange={onOpenChange}>
      <DialogContent size={'2lg'} className={'tw-flex tw-flex-col md:tw-flex-row md:tw-gap-6'}>
        <img
          width={483}
          height={391}
          className={'tw-w-full md:tw-w-auto'}
          src={RepurposePlaceholderImage}
        />
        <div className={'tw-flex tw-flex-col tw-justify-between tw-gap-6 tw-px-3 tw-py-4'}>
          <div className={'tw-flex tw-flex-col tw-gap-6'}>
            <DialogTitle className={'tw-text-2xl tw-font-bold tw-tracking-tight'}>
              {t('Clip your video into reels')}
            </DialogTitle>
            <DialogDescription>
              {t(
                'We will create a new request for you reels. Sit back and relax while we do everything for you',
              )}
            </DialogDescription>
          </div>
          <div className={'tw-flex tw-justify-end tw-gap-4'}>
            <Button size={'responsive'} variant={'text'} onClick={() => onOpenChange?.(false)}>
              {t('Cancel')}
            </Button>
            <Button
              size={'responsive'}
              variant={'brand'}
              onClick={confirm}
              disabled={mutateRepurposeRequest.isLoading}
            >
              {t('Proceed')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RepurposeConfirmDialog;
