const url = {
  insertArguments: (url: string, args: { [key: string]: string }): string => {
    for (const key in args) {
      const value = args[key];
      url = url.replace('{' + key + '}', value);
    }

    return url;
  },
} as const;

export { url };
