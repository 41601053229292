import { UseQueryOptions } from '@tanstack/react-query';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { HttpError } from '../Http/HttpError';
import { isCancelError } from '../Http/Http.types';

type UseQueryOptionsObject<TQueryFnData = unknown, TError = unknown> = Omit<
  UseQueryOptions<TQueryFnData, TError>,
  'queryKey' | 'queryFn'
>;

type QueryOptionsObject = Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>;

type OptionsObject = {
  auth?: boolean;
} & QueryOptionsObject;

type DefaultOptionsFn = (options?: OptionsObject) => QueryOptionsObject;

const defaultOptions: DefaultOptionsFn = (options = { auth: false }) => {
  const { auth, ...rest } = options;

  return {
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    retryDelay: 1000,
    enabled: auth ? !AuthUtils.isExpired() : true,
    retry: (count, error: HttpError) => {
      return !isCancelError(error);
    },
    ...(rest ?? {}),
  };
};

export type { UseQueryOptionsObject };
export { defaultOptions };
