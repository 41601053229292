import React from 'react';
import {
  RequestKanbanCard,
  RequestKanbanCardProps,
} from 'src/features/requests/request-kanban/request-kanban-card';
import { Draggable } from '@hello-pangea/dnd';
import { PaginatedResponse } from 'src/api/Client';
import { DraggableCardGrip } from 'src/features/requests/draggable-card-grip';
import { cn } from 'src/lib/utils';
import { useBreakpoint } from 'src/lib/hooks';
import { RequestsTableResponse } from 'src/lib/services/api/request-api';

type Props = {
  pages: PaginatedResponse<RequestsTableResponse>[];
  status: string;
  cardProps?: RequestKanbanCardProps['cardProps'];
  isDropDisabled?: boolean;
  isDragDisabled?: boolean;
  pageSize: number;
  renderCount?: number; // used to trigger list re-render
};

// using pure component to avoid some unneeded re-renders as it is advised to do so in the docs:
// https://github.com/hello-pangea/dnd/blob/main/docs/api/droppable.md#recommended-droppable--performance-optimisation
const RequestKanbanColumnList: React.FC<Props> = React.memo(
  ({ pages, status, cardProps, isDropDisabled = false, isDragDisabled = false, pageSize }) => {
    const { isMobile } = useBreakpoint();

    return (
      <>
        {pages.map(
          (group, pageIndex) =>
            group?.items.map((request, i) => (
              <Draggable
                key={`${status}-${request.id}`}
                draggableId={request.id}
                index={pageIndex * pageSize + i}
                isDragDisabled={isDragDisabled}
              >
                {(provided, snapshot) => {
                  const cardWrapperProps = isMobile ? { ...provided.dragHandleProps } : {};

                  return (
                    <div
                      ref={provided.innerRef}
                      className={'tw-min-w-0 tw-py-2'}
                      {...provided.draggableProps}
                      {...cardWrapperProps}
                    >
                      <RequestKanbanCard
                        request={request}
                        cardProps={cardProps}
                        cardTop={
                          !isMobile && (
                            <DraggableCardGrip
                              className={cn(
                                'tw-absolute tw-left-1/2 tw-top-0 tw-hidden tw-animate-in tw-fade-in group-hover:tw-block',
                                snapshot.isDragging && 'tw-block',
                                (isDropDisabled || isDragDisabled) && '!tw-hidden',
                              )}
                              iconProps={{
                                className: 'tw-h-4 tw-w-4',
                              }}
                              {...provided.dragHandleProps}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          )
                        }
                      />
                    </div>
                  );
                }}
              </Draggable>
            )),
        )}
      </>
    );
  },
);
RequestKanbanColumnList.displayName = 'RequestKanbanColumnList';

export { RequestKanbanColumnList };
