import { create } from 'zustand';
import { AuthUtils } from './Auth.utils';

const useAuthStore = create<{
  appStarted: boolean;
  isLoggedIn: boolean;
  setAppStarted: (appStarted: boolean) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
}>((set) => ({
  appStarted: AuthUtils.isExpired(),
  isLoggedIn: !AuthUtils.isExpired(),
  setAppStarted: (appStarted: boolean) => set({ appStarted }),
  setIsLoggedIn: (isLoggedIn: boolean) => set({ isLoggedIn }),
}));

export { useAuthStore };
