import React, { HTMLAttributes } from 'react';
import MagnifyingGlass from 'src/assets/images/magnifying-glass.svg';
import { cn } from 'src/lib/utils';
import { useTranslation } from 'react-i18next';

const NoRecordsFallback: React.FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn('tw-flex tw-items-center tw-justify-center tw-gap-2 tw-py-6', className)}
      {...props}
    >
      <img src={MagnifyingGlass} alt="empty" />
      <span className={'tw-font-medium tw-text-text-inactive'}>
        {t('common:no_records', 'No records to show')}
      </span>
    </div>
  );
};

export { NoRecordsFallback };
