import React from 'react';
import { PostHog, PostHogProvider as DefaultPostHogProvider, usePostHog } from 'posthog-js/react';
import { PostHogConfig } from 'posthog-js';
import { POSTHOG_API_HOST, POSTHOG_API_KEY } from 'src/env.json';

const isEnabled = () => !!POSTHOG_API_KEY;

export const customPosthogEvent = {
  klarnaFail: 'klarna_fail',
  klarnaPending: 'klarna_pending',
  klarnaSuccess: 'klarna_success',
  onboardSubscribeSuccess: 'onboard_subscribe_success',
  subscribeSuccess: 'subscribe_success',
  subscriptionCancellationDialogClosed: 'subscription_cancellation_dialog_closed',
  subscriptionCancellationDialogOpened: 'subscription_cancellation_dialog_opened',
  subscriptionCancellationFeedbackSubmitted: 'subscription_cancellation_feedback_submitted',
  subscriptionEditorChangeRequested: 'subscription_editor_change_requested',
  subscriptionOneTimeOfferApplied: 'subscription_one_time_offer_applied',
  requestRevisionRated: 'request_revision_rated',
} as const;

export const postHogOptions: Partial<PostHogConfig> = {
  api_host: POSTHOG_API_HOST,
  persistence: 'localStorage+cookie',
};

export const PostHogProvider: React.FC<React.ComponentProps<typeof DefaultPostHogProvider>> = ({
  children,
  ...props
}) => (
  <DefaultPostHogProvider apiKey={POSTHOG_API_KEY} options={postHogOptions} {...props}>
    {children}
  </DefaultPostHogProvider>
);

export const useDecoratedPostHog = (): {
  raw: PostHog;
  capture: PostHog['capture'];
  identify: PostHog['identify'];
  reset: PostHog['reset'];
  group: PostHog['group'];
  isFeatureEnabled: PostHog['isFeatureEnabled'];
} => {
  const posthog = usePostHog();

  return {
    raw: posthog,
    capture: (event, properties, options) => {
      const newProps = properties ? { custom: properties } : properties;

      if (!isEnabled()) {
        console.debug('posthog.capture', event, newProps, options);

        return;
      }

      posthog?.capture(event, newProps, options);
    },
    identify: (...args) => {
      if (!isEnabled()) {
        console.debug('posthog.identify', ...args);
        return;
      }

      posthog?.identify(...args);
    },
    reset: (...args) => {
      if (!isEnabled()) {
        console.debug('posthog.reset', ...args);
        return;
      }

      posthog?.reset(...args);
    },
    group: (...args) => {
      if (!isEnabled()) {
        console.debug('posthog.group', ...args);
        return;
      }

      posthog?.group(...args);
    },
    isFeatureEnabled: (...args) => {
      if (!isEnabled()) {
        console.debug('posthog.isFeatureEnabled', ...args);

        return true;
      }

      return posthog?.isFeatureEnabled(...args);
    },
  };
};
