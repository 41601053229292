import * as React from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import { cva, type VariantProps } from 'class-variance-authority';
import { X } from 'lucide-react';
import { Button, ButtonProps } from 'src/components/ui/button';
import { cn } from 'src/lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { t } from 'i18next';

const Sheet = SheetPrimitive.Root;

const SheetTrigger = SheetPrimitive.Trigger;

const SheetClose = SheetPrimitive.Close;

const SheetPortal = SheetPrimitive.Portal;

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      'tw-fixed tw-inset-0 tw-z-50 tw-bg-black/70 data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0',
      className,
    )}
    {...props}
    ref={ref}
  />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
  'tw-fixed tw-z-50 tw-gap-4 tw-bg-background tw-shadow-lg tw-transition tw-ease-in-out data-[state=closed]:tw-duration-300 data-[state=open]:tw-duration-500 data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out',
  {
    variants: {
      side: {
        top: 'tw-inset-x-0 tw-top-0 tw-border-b data-[state=closed]:tw-slide-out-to-top data-[state=open]:tw-slide-in-from-top',
        bottom:
          'tw-inset-x-0 tw-bottom-0 tw-border-t data-[state=closed]:tw-slide-out-to-bottom data-[state=open]:tw-slide-in-from-bottom',
        left: 'tw-inset-y-0 tw-left-0 tw-h-full tw-w-3/4 tw-border-r data-[state=closed]:tw-slide-out-to-left data-[state=open]:tw-slide-in-from-left sm:tw-max-w-sm',
        right:
          'tw-inset-y-0 tw-right-0 tw-h-full tw-w-3/4 tw-border-l data-[state=closed]:tw-slide-out-to-right data-[state=open]:tw-slide-in-from-right sm:tw-max-w-sm',
      },
    },
    defaultVariants: {
      side: 'right',
    },
  },
);

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {
  close?: boolean;
}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(({ side = 'right', className, close = false, children, ...props }, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive.Content ref={ref} className={cn(sheetVariants({ side }), className)} {...props}>
      {children}
      {close && <SheetComposedClose />}
    </SheetPrimitive.Content>
  </SheetPortal>
));
SheetContent.displayName = SheetPrimitive.Content.displayName;

const SheetHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'tw-flex tw-items-center tw-justify-between tw-border-b tw-border-border-base tw-p-6 sm:tw-text-left',
      className,
    )}
    {...props}
  />
);
SheetHeader.displayName = 'SheetHeader';

const SheetFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'tw-flex tw-flex-col-reverse tw-p-6 sm:tw-flex-row sm:tw-justify-end sm:tw-space-x-2',
      className,
    )}
    {...props}
  />
);
SheetFooter.displayName = 'SheetFooter';

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn('tw-text-2xl tw-font-bold tw-text-text-strong', className)}
    {...props}
  />
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={cn('tw-text-sm tw-text-muted-foreground', className)}
    {...props}
  />
));
SheetDescription.displayName = SheetPrimitive.Description.displayName;

const SheetComposedClose = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Close>,
  ButtonProps & {
    iconSize?: number;
  }
>(({ className, iconSize = 20, size = 'icon', variant = 'ghost', ...props }, ref) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <SheetPrimitive.Close
          ref={ref}
          asChild
          className={cn(
            'tw-absolute tw-right-4 tw-top-4 tw-size-8 tw-rounded-sm tw-text-text tw-ring-offset-background tw-transition-opacity focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 disabled:tw-pointer-events-none',
            className,
          )}
        >
          <Button ref={ref} type={'button'} variant={variant} size={size} {...props}>
            <X size={iconSize} />
            <span className="tw-sr-only">{t('actions:close', { defaultValue: 'Close' })}</span>
          </Button>
        </SheetPrimitive.Close>
      </TooltipTrigger>
      <TooltipContent>{t('actions:close')}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
));
SheetComposedClose.displayName = 'SheetComposedClose';

const SheetCloseButton = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Close>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Close>
>(({ className, ...props }, ref) => (
  <SheetClose asChild>
    <Button
      type={'button'}
      variant={'ghost'}
      size={'icon'}
      className={className}
      ref={ref}
      {...props}
    >
      <X size={24} />
      <span className="tw-sr-only">{t('actions:close', { defaultValue: 'Close' })}</span>
    </Button>
  </SheetClose>
));
SheetCloseButton.displayName = 'SheetCloseButton';

const SheetRow: React.FC<React.PropsWithChildren<{ title: React.ReactNode }>> = ({
  title,
  children,
}) => (
  <div className={'tw-flex tw-flex-col tw-gap-3 tw-border-b tw-border-border-subtle tw-py-3'}>
    {typeof title === 'string' && !!title ? (
      <div className={'tw-text-lg tw-font-bold'}>{title}</div>
    ) : (
      <>{title}</>
    )}
    <div className={'tw-break-words tw-font-medium'}>{children}</div>
  </div>
);
SheetRow.displayName = 'SheetRow';

export {
  Sheet,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
  SheetClose,
  SheetCloseButton,
  SheetComposedClose,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  SheetDescription,
  SheetRow,
};
