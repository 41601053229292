import * as React from 'react';
import { cn } from 'src/lib/utils';
import { Link, LinkProps } from 'react-router-dom';
import { appRoutes } from 'src/routes/routes.types';
import ImageAsset from 'src/helpers/ImageAsset';

type AppLogoProps = Partial<LinkProps> & React.HTMLAttributes<HTMLAnchorElement>;

const AppLogo = React.forwardRef<HTMLAnchorElement, AppLogoProps>(
  ({ className, to, ...props }, ref) => (
    <Link
      ref={ref}
      {...props}
      to={to ?? appRoutes.login}
      className={cn('tw-mb-6 tw-mt-4 tw-flex tw-w-full tw-justify-center', className)}
    >
      <img src={ImageAsset.LogoMain} alt={'beCreatives'} className={'tw-max-h-16'} />
    </Link>
  ),
);

AppLogo.displayName = 'AppLogo';

export { AppLogo };
