import React, { useEffect, useState } from 'react';
import { useRequestOutcomesQueryContext } from 'src/features/requests/request-outcome/use-request-outcomes-query-context';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { requestClient, RequestOutcomeResponse } from 'src/lib/services/api/request-api';
import { cn } from 'src/lib/utils';
import { DecoratedRequestOutcomeContextProvider } from 'src/features/requests/request-outcome/use-decorated-request-outcome-context';
import { RequestPendingOutcomeCard } from 'src/features/requests/request-page/multi-outcome';
import { DraggableCardGrip } from 'src/features/requests/draggable-card-grip';
import { useBreakpoint } from 'src/lib/hooks';
import { useMutation } from '@tanstack/react-query';

const RequestPendingOutcomesList: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  const [cardControls, setCardControls] = useState<{ [key: string]: boolean }>({});
  const { setQueryData, data: outcomes, invalidate } = useRequestOutcomesQueryContext();

  const reorderMutation = useMutation({
    mutationFn: ([sourceItemIndex, targetItemIndex]: [number, number]) => {
      if (!outcomes) {
        throw new Error('No outcomes data');
      }

      const sourceRequestId = outcomes.items[sourceItemIndex].id;
      const targetRequestId = outcomes.items[targetItemIndex].id;

      return requestClient.sort(sourceRequestId, {
        request_id: targetRequestId,
        position: sourceItemIndex > targetItemIndex ? 'before' : 'after',
      });
    },
    onMutate: ([sourceItemIndex, targetItemIndex]) => {
      setQueryData((prev) => {
        const newItems = [...prev.items];
        // move items
        const positions = newItems.map((outcome) => outcome.sort);
        const [removed] = newItems.splice(sourceItemIndex, 1);
        newItems.splice(targetItemIndex, 0, removed);
        // reindex sort value
        positions.forEach((position, index) => {
          newItems[index].sort = position;
        });

        return {
          ...prev,
          items: newItems,
        };
      });
    },
    onError: () => {
      invalidate();
    },
  });

  const beforeDrag = () => {
    if (Object.values(cardControls).every((value) => !value)) {
      return;
    }

    // before dragging started, close all cards
    const newControls: typeof cardControls = {};

    Object.keys(cardControls).forEach((key) => {
      newControls[key] = false;
    });

    setCardControls(newControls);
  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    // dropped outside the list or canceled drag
    if (!destination || result.reason === 'CANCEL') {
      return;
    }

    if (!outcomes) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    reorderMutation.mutate([source.index, destination.index]);
  };

  const openCard = (id: string, isOpen: boolean) => {
    setCardControls((prev) => ({
      ...prev,
      [id]: isOpen,
    }));
  };

  useEffect(() => {
    // if list length did not change, do not update state
    if (outcomes?.items.length === Object.keys(cardControls).length) {
      return;
    }

    // update collapsible card state
    const newControls: typeof cardControls = {};
    outcomes?.items.forEach((outcome, index) => {
      newControls[outcome.id] = index === 0; // only first card open
    });

    setCardControls(newControls);
  }, [outcomes]);

  return (
    <div
      className={cn('tw-relative tw-flex tw-grow tw-flex-col tw-gap-4 tw-p-4', className)}
      {...props}
    >
      <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={beforeDrag}>
        <Droppable droppableId={'request-pending-outcomes'}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={'tw-flex tw-h-full tw-flex-col'}
            >
              <DraggableOutcomesList
                outcomes={outcomes?.items ?? []}
                cardControls={cardControls}
                openCard={openCard}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const DraggableOutcomesList: React.FC<{
  outcomes: RequestOutcomeResponse[];
  cardControls: { [key: string]: boolean };
  openCard: (id: string, open: boolean) => void;
}> = React.memo(({ outcomes, cardControls, openCard }) => {
  const { isMobile } = useBreakpoint();

  return (
    <>
      {outcomes.map((outcome, index) => (
        <DecoratedRequestOutcomeContextProvider key={`outcome-key-${outcome.id}`} outcome={outcome}>
          <Draggable draggableId={outcome.id} index={index}>
            {(provided, snapshot) => {
              const wrapperProps = isMobile ? { ...provided.dragHandleProps } : {};

              return (
                <div
                  {...provided.draggableProps}
                  ref={provided.innerRef}
                  className={'tw-group tw-mb-4'}
                  {...wrapperProps}
                >
                  <RequestPendingOutcomeCard
                    open={cardControls[outcome.id]}
                    onOpenChange={(open) => openCard(outcome.id, open)}
                    cardTop={
                      !isMobile && (
                        <DraggableCardGrip
                          className={cn(
                            'tw-absolute tw-left-1/2 tw-top-0 tw-hidden tw-animate-in tw-fade-in group-hover:tw-block',
                            snapshot.isDragging && 'tw-block',
                          )}
                          {...provided.dragHandleProps}
                        />
                      )
                    }
                  />
                </div>
              );
            }}
          </Draggable>
        </DecoratedRequestOutcomeContextProvider>
      ))}
    </>
  );
});
DraggableOutcomesList.displayName = 'DraggableOutcomesList';

export { RequestPendingOutcomesList };
