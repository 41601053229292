import React, { forwardRef } from 'react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@radix-ui/react-collapsible';
import { Card, CardContent, CardFooter, CardHeader } from 'src/components/ui/card';
import { CollapsibleCardProps } from './collapsible-card';
import { If } from '../If';
import { Button } from './button';
import { ChevronUp } from 'lucide-react';
import { cn } from 'src/lib/utils';

const CollapsibleInnerCardTrigger: React.FC<
  Omit<React.ComponentProps<typeof CollapsibleTrigger>, 'asChild'> & { open?: boolean }
> = ({ className, children, open, ...props }) => (
  <CollapsibleTrigger {...props} asChild>
    <div className={cn('tw-flex tw-items-center tw-justify-between', className)}>
      {children}
      <Button type={'button'} size={'iconSm'} variant={'unset'}>
        <ChevronUp
          size={20}
          className={cn('tw-transition-transform', {
            'tw-rotate-180': !open,
          })}
        />
      </Button>
    </div>
  </CollapsibleTrigger>
);

const CollapsibleInnerContent: React.FC<React.ComponentProps<typeof CollapsibleContent>> = ({
  className,
  children,
  ...props
}) => (
  <CollapsibleContent className={cn('tw-px-4 tw-pb-4 tw-pt-2', className)} {...props}>
    {children}
  </CollapsibleContent>
);

const CollapsibleInnerCardContent: React.FC<React.ComponentPropsWithoutRef<typeof CardContent>> = ({
  children,
  className,
  ...props
}) => (
  <CardContent
    className={cn('tw-flex tw-flex-col tw-gap-3 tw-rounded-lg tw-bg-background tw-p-4', className)}
    {...props}
  >
    {children}
  </CardContent>
);

const CollapsibleInnerCardFooter: React.FC<React.ComponentPropsWithoutRef<typeof CardFooter>> = ({
  children,
  className,
  ...props
}) => (
  <CardFooter className={cn('tw-mt-6 tw-flex tw-flex-col tw-gap-3 tw-p-0', className)} {...props}>
    {children}
  </CardFooter>
);

const CollapsibleInnerCard = forwardRef<HTMLDivElement, CollapsibleCardProps>(
  ({ className, icon = null, title = null, children, open, onOpenChange, ...props }, ref) => (
    <Collapsible asChild={true} open={open} onOpenChange={onOpenChange}>
      <Card ref={ref} className={className} variant={'subtle'} {...props}>
        <If when={!!(icon || title)}>
          <CardHeader className={'tw-flex tw-flex-col tw-gap-4 tw-space-y-0 tw-p-4'}>
            <CollapsibleInnerCardTrigger open={open}>
              <div className={'tw-flex tw-items-center tw-gap-2'}>
                {icon}
                <h3 className={'tw-text-lg tw-font-medium tw-text-text-strong'}>{title}</h3>
              </div>
            </CollapsibleInnerCardTrigger>
          </CardHeader>
        </If>
        {children}
      </Card>
    </Collapsible>
  ),
);

CollapsibleInnerCard.displayName = 'CollapsibleInnerCard';

export {
  CollapsibleInnerCard,
  CollapsibleInnerContent,
  CollapsibleInnerCardContent,
  CollapsibleInnerCardFooter,
};
