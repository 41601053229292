import { JsonWrapper } from 'src/services/JsonWrapper';

class LocalStorage {
  public static get: <T = unknown>(key: string, json?: boolean) => T | null = (
    key,
    json = false,
  ) => {
    try {
      const data = localStorage.getItem(key);

      return json ? JsonWrapper.toJson(data) : (data as any);
    } catch (e) {
      console.error('Failed to access local storage', e);
    }

    return null;
  };

  public static set = (key: string, value: any): void => {
    try {
      const data = typeof value === 'string' ? value : JsonWrapper.toString(value);

      localStorage.setItem(key, data);
    } catch (e) {
      console.error('Failed to access local storage', e);
    }
  };

  public static remove = (key: string): void => {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error('Failed to access local storage', e);
    }
  };
}

export { LocalStorage };
