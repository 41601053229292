import React, { ComponentProps, forwardRef } from 'react';
import { Card, CardContent, CardHeader, CardProps } from 'src/components/ui/card';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'src/components/ui/collapsible';
import { If } from 'src/components/If';
import { cn } from 'src/lib/utils';
import { Separator } from 'src/components/ui/separator';
import { ChevronUp } from 'lucide-react';
import { Button } from 'src/components/ui/button';

export type CollapsibleCardProps = Pick<
  ComponentProps<typeof Collapsible>,
  'open' | 'onOpenChange'
> &
  Omit<CardProps, 'title'> & {
    icon?: React.ReactNode;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
  };

const CollapsibleCardTrigger: React.FC<
  Omit<React.ComponentProps<typeof CollapsibleTrigger>, 'asChild'> & { open?: boolean }
> = ({ className, children, open, ...props }) => (
  <CollapsibleTrigger {...props} asChild>
    <div className={cn('tw-flex tw-items-center', className)}>
      {children}
      <div className={'tw-flex tw-grow tw-items-center'}>
        <div className={'tw-mx-6 tw-grow lg:tw-mx-12'}>
          <Separator className={'tw-bg-neutral-50'} />
        </div>
        <div>
          <Button type={'button'} size={'icon'} variant={'unset'}>
            <ChevronUp
              size={20}
              className={cn('tw-transition-transform', {
                'tw-rotate-180': !open,
              })}
            />
          </Button>
        </div>
      </div>
    </div>
  </CollapsibleTrigger>
);

const CollapsibleCardContent: React.FC<
  Omit<React.ComponentProps<typeof CollapsibleContent>, 'asChild'>
> = ({ className, children, ...props }) => (
  <CollapsibleContent asChild className={className} {...props}>
    <CardContent>{children}</CardContent>
  </CollapsibleContent>
);
CollapsibleCardContent.displayName = 'CollapsibleCardContent';

const CollapsibleCard = forwardRef<HTMLDivElement, CollapsibleCardProps>(
  (
    {
      className,
      icon = null,
      title = null,
      subtitle = null,
      children,
      open,
      onOpenChange,
      ...props
    },
    ref,
  ) => (
    <Collapsible asChild={true} open={open} onOpenChange={onOpenChange}>
      <Card ref={ref} className={className} {...props}>
        <If when={!!(icon || title)}>
          <CardHeader className={'tw-p-6'}>
            <CollapsibleCardTrigger open={open}>
              <div className={'tw-flex tw-items-center tw-gap-2 tw-text-title tw-text-text-strong'}>
                {icon}

                <h2 className={'tw-flex tw-flex-wrap tw-gap-x-1 tw-text-text-strong'}>{title}</h2>
              </div>
            </CollapsibleCardTrigger>
            <If when={!!subtitle}>
              <p className={'tw-ml-8 tw-text-lg tw-font-medium tw-text-text-inactive'}>
                {subtitle}
              </p>
            </If>
          </CardHeader>
        </If>
        {children}
      </Card>
    </Collapsible>
  ),
);
CollapsibleCard.displayName = 'CollapsibleCard';

export { CollapsibleCardTrigger, CollapsibleCardContent, CollapsibleCard };
