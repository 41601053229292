import { AxiosRequestConfig } from 'axios';
import { Client, PaginatedResponse } from 'src/api/Client';
import {
  CommentResponse,
  StoreCommentRequest,
} from 'src/api/services/CommentClient/CommentClient.types';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

class CommentClient extends Client {
  public readonly route = {
    filterRequestComments: `${this.http.baseUrl}/comments/{model}/filter`,
    store: `${this.http.baseUrl}/comments/{model}`,
    delete: `${this.http.baseUrl}/comments/{model}`,
  };

  filterRequestComments = (
    model: string,
    params?: ApiQueryObject & { revision_index?: number },
    config?: AxiosRequestConfig,
  ): Promise<PaginatedResponse<CommentResponse>> => {
    return this.http.get(this.buildUrl(this.route.filterRequestComments, { model }), {
      ...(config ?? {}),
      params,
    });
  };

  store = (model: string, data: StoreCommentRequest): Promise<CommentResponse> => {
    return this.http.post(this.buildUrl(this.route.store, { model }), data);
  };

  delete = (model: string): Promise<CommentResponse> => {
    return this.http.delete(this.buildUrl(this.route.delete, { model }));
  };
}

export default CommentClient;
