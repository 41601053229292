import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertDescription, AlertTitle } from 'src/components/ui/alert';
import { XCircle } from 'lucide-react';
import { If } from 'src/components/If';
import {
  EmptySpaceFallbackHeader,
  EmptySpaceFallbackImage,
  NotFoundFallback,
  NotFoundFallbackAction,
} from 'src/features/fallback/ui';

const SpaceErrorFallback: React.FC<{ error: any; resetErrorBoundary: () => any }> = ({
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation();

  return (
    <NotFoundFallback className={'tw-mx-auto tw-max-w-[32rem] tw-items-center'}>
      <EmptySpaceFallbackImage />
      <EmptySpaceFallbackHeader>
        <Alert variant={'destructive'}>
          <XCircle size={20} />
          <If when={!!(error || {}).message}>
            <AlertTitle>{error.message}</AlertTitle>
          </If>
          <AlertDescription>
            {t('alerts:error.generic.title', {
              defaultValue: 'Oops, something went wrong, please try again later.',
            })}
          </AlertDescription>
        </Alert>
      </EmptySpaceFallbackHeader>
      <NotFoundFallbackAction
        onClick={() => {
          resetErrorBoundary();
        }}
        className={'tw-min-w-36'}
        variant={'outline'}
      >
        {t('actions:try_again', {
          defaultValue: 'Try again',
        })}
      </NotFoundFallbackAction>
    </NotFoundFallback>
  );
};

export { SpaceErrorFallback };
