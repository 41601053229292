import styled from 'styled-components';
import { Button, ButtonProps } from '@becreatives/becreatives-ui';

const TransparentButton = styled(Button).attrs<{ color: ButtonProps['color'] | string }>(
  ({ color }) => ({
    color: color || 'transparent',
  }),
)`
  &:active,
  &:focus {
    border-color: ${({ color }) => color} !important;
  }
`;

export { TransparentButton };
