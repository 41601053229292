import { Client } from 'src/api/Client';
import { AxiosRequestConfig } from 'axios';
import { url } from 'src/lib/utils';
import { SubscriptionAssignmentResponse } from 'src/api/services/Subscription';

type RequestEditorChangeRequest = {
  reason: string;
};

class SubscriptionAssignmentClient extends Client {
  readonly route = {
    requestEditorChange: `${this.http.baseUrl}/subscription-assignments/{model}/request-editor-change`,
  };

  requestEditorChange = (
    model: string,
    data: RequestEditorChangeRequest,
    config?: AxiosRequestConfig,
  ): Promise<SubscriptionAssignmentResponse> =>
    this.http.post(
      url.insertArguments(this.route.requestEditorChange, {
        model,
      }),
      data,
      config,
    );

  cancelEditorChangeRequest = (
    model: string,
    config?: AxiosRequestConfig,
  ): Promise<SubscriptionAssignmentResponse> =>
    this.http.delete(
      url.insertArguments(this.route.requestEditorChange, {
        model,
      }),
      config,
    );
}

export type { RequestEditorChangeRequest };
export { SubscriptionAssignmentClient };
