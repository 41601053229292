import { Client, PaginatedResponse } from 'src/api/Client';
import { RevisionResponse } from 'src/api/services/RevisionClient/RevisionClient.types';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

class RevisionClient extends Client {
  public readonly route = {
    filter: `${this.http.baseUrl}/{model}/filter`,
    get: `${this.http.baseUrl}/revisions/{model}`,
  };

  filter = (params: ApiQueryObject): Promise<PaginatedResponse<RevisionResponse>> => {
    return this.http.get(this.route.filter, { params });
  };

  get = (revisionId: string): Promise<RevisionResponse> => {
    return this.http.get(this.buildUrl(this.route.get, { revisionId }));
  };
}

export default RevisionClient;
