import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const customTwMerge = extendTailwindMerge({
  prefix: 'tw-', // remove this extension, use twMerge() when prefix is removed
});

const cn = (...inputs: ClassValue[]) => customTwMerge(clsx(inputs));

export { cn };
