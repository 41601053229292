import React, { useMemo } from 'react';
import { Icon, IconProps } from '@iconify/react';
import FileTypes from 'src/helpers/FileTypes';

// Look for icons in https://icon-sets.iconify.design/
import ICONS from './icons.json';

interface Props extends Partial<IconProps> {
  fileName: string;
}

export const placeholder: JSX.Element = <i className="mdi mdi-file-alert-outline large-icon" />;

const FileThumbnail: React.FC<Props> = ({ fileName, ...props }): JSX.Element => {
  const icon = useMemo<string>(() => {
    const extension = FileTypes.getExtension(fileName);

    if (extension && extension in ICONS) {
      return ICONS[extension as keyof typeof ICONS];
    }

    return ICONS.unknown;
  }, [fileName]);

  return <Icon fr={'true'} icon={icon} className={props.className} />;
};

export default FileThumbnail;
