import React from 'react';
import { Link } from 'react-router-dom';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { NotFoundView } from 'src/components/NotFoundView';
import { appRoutes } from 'src/routes/routes.types';
import { Button } from 'src/components/ui/button';

const NotFoundPage: React.FC = () => {
  const isLogged = !AuthUtils.isExpired();

  const redirect = isLogged
    ? {
        to: appRoutes.dashboard,
        text: 'Back to Dashboard',
      }
    : {
        to: appRoutes.login,
        text: 'Back to Login',
      };

  return (
    <NotFoundView>
      <div className={'tw-mt-6'}>
        <Button asChild variant={'brand'} size={'lg'}>
          <Link to={redirect.to}>{redirect.text}</Link>
        </Button>
      </div>
    </NotFoundView>
  );
};

export { NotFoundPage };
