import { Http } from './Http';
import {
  AuthClient,
  NotificationClient,
  SubscriptionClient,
  RevisionClient,
  CommentClient,
  MediaClient,
  MediaLinkClient,
  SocialAuthClient,
  SubscriptionAssignmentClient,
} from './services';

const http = new Http();

const Api = {
  auth: new AuthClient(http),
  comment: new CommentClient(http),
  revision: new RevisionClient(http),
  notification: new NotificationClient(http),
  subscription: new SubscriptionClient(http),
  media: new MediaClient(http),
  sharedMedia: new MediaLinkClient(http),
  socialAuth: new SocialAuthClient(http),
  subscriptionAssignment: new SubscriptionAssignmentClient(http),
  http,
};

export { http as Http, Api };
