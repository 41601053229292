export default class Str {
  public static readonly capitalizeFirst = (string: string): string => {
    return string[0].toUpperCase() + string.slice(1);
  };

  public static readonly slugify = (string: string): string => {
    return string
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };

  public static formatPercents = (percents: number): string => {
    return `${percents} %`;
  };

  public static readonly toPascalCase = (string: string): string => {
    string = Str.slugify(string);

    const parts = string.split('-');
    if (!parts.length) {
      return Str.capitalizeFirst(string);
    }

    return parts.map((str) => Str.capitalizeFirst(str)).join('');
  };

  public static wrapHrefs = (str: string): string => {
    const rgx = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.?[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/g,
    );

    return str.replace(rgx, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener" class="tw-text-blue tw-underline-offset-4 hover:!tw-underline">${url}</a>`;
    });
  };
}
