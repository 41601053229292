import { authQueries, UseQueryOptionsObject } from 'src/api/queries/queries';
import { defaultOptions } from 'src/api/queries/queries.utils';
import { useMakeQuery } from '../useMakeQuery';
import { profileClient, ProfileResponse } from 'src/lib/services/api/profile-api';

const useProfileQuery = (options?: UseQueryOptionsObject<ProfileResponse>) => {
  return useMakeQuery<ProfileResponse>([authQueries.profile], () => profileClient.get(), {
    staleTime: 60 * 1000,
    ...defaultOptions({
      auth: true,
      ...(options ?? {}),
      retry: (failureCount) => {
        return failureCount < 3;
      },
    }),
  });
};

export { useProfileQuery };
