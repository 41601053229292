import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/components/ui/use-toast';
import { requestClient } from 'src/lib/services/api/request-api';

export const useRepurposeRequestMutation = () => {
  const { t } = useTranslation();
  const { toast } = useToast();

  return useMutation({
    mutationFn: async (id: string) => requestClient.repurposeRequest(id),
    onSuccess: async () => {
      toast({ description: t('Request created successfully!') });
    },
    onError: (e) => {
      console.error('Failed to repurpose video', e);
      toast({
        description: 'Oops, something went wrong, please try again later.',
        variant: 'destructive',
      });
    },
  });
};
