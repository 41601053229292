class DomUtils {
  public static getFirstOfClass = (className: string): Element | undefined => {
    const found = document.getElementsByClassName(className);
    if (!found.length) {
      return undefined;
    }

    return found[0];
  };

  // eslint-disable-next-line
  public static scrollToElement = (target: Element, initialOffset = 0): void => {
    // TODO: calculations from inside a wrapper
    target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    // const container = (document.querySelector('.FullPageWrapper') ??
    //   document.querySelector('.VerticalPageContentWrapper'))!;
    //
    // const bodyRect: DOMRect = container!.getBoundingClientRect(),
    //   targetRect: DOMRect = target.getBoundingClientRect(),
    //   headerRect: DOMRect = document
    //     .getElementsByTagName('header')[0]
    //     ?.getBoundingClientRect() as DOMRect;
    //
    // let offset = initialOffset;
    // if (targetRect.top && headerRect?.height) {
    //   offset += targetRect.top - headerRect.height - bodyRect.top;
    // }

    // container.scrollTo({
    //   top: offset,
    //   behavior: 'smooth',
    // });
  };
}

export { DomUtils };
