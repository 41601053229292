import { useLocation, useNavigate } from 'react-router-dom';
import { storage } from 'src/lib/utils';
import { useEffect } from 'react';

let saved = false;
let used = false;
const key = 'hubspotSavedUtmLocation';

type UseHubspotSavedUtmLocationReturn = {
  exists: () => boolean;
  rememberSearch: (search: string) => void;
  getQueryParams: () => string | null;
};

export const useHubspotSavedUtmLocation = (): UseHubspotSavedUtmLocationReturn => {
  const rememberSearch = (search: string) => {
    if (saved) {
      return;
    }

    storage.set(key, search);
    saved = true;
  };

  const getQueryParams = () => {
    const result = storage.get<string>(key);
    storage.remove(key);
    used = true;
    return result;
  };

  const exists = () => {
    return !!storage.get(key);
  };

  return {
    rememberSearch,
    getQueryParams,
    exists,
  };
};

export const useSaveHubspotUtmLocationEffect = () => {
  const location = useLocation();
  const { rememberSearch } = useHubspotSavedUtmLocation();

  useEffect(() => {
    const searchQuery = location.search;
    const params = new URLSearchParams(searchQuery);
    const utmParams: string[] = [];
    params.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utmParams.push(`${key}=${value}`);
      }
    });

    if (!utmParams.length) {
      return;
    }

    rememberSearch(`?${utmParams.join('&')}`);
    saved = true;
  }, []);
};

export const useNavigateToHubspotUtmLocationEffect = () => {
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const hsUtmLocation = useHubspotSavedUtmLocation();

  useEffect(() => {
    if (!hsUtmLocation.exists() || used) {
      return;
    }

    let nextSearch = hsUtmLocation.getQueryParams();
    if (!nextSearch) {
      return;
    }

    const currentSearch = currentLocation.search;
    if (currentSearch) {
      nextSearch += `&${currentSearch.slice(1)}`;
    }

    navigate({ pathname: currentLocation.pathname, search: nextSearch });
  }, []);
};
