import React from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

type Props = React.PropsWithChildren;

const ReactQueryParamsProvider: React.FC<Props> = (props) => {
  return <QueryParamProvider adapter={ReactRouter6Adapter}>{props.children}</QueryParamProvider>;
};

export type { Props as ReactQueryParamsProviderProps };
export default ReactQueryParamsProvider;
