import React from 'react';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { render } from 'src/lib/utils';
import { globalQueries, UseQueryOptionsObject } from 'src/api/queries';
import { subscriptionClient } from 'src/lib/services/api/subscription-api';

type ApiResponse = Awaited<ReturnType<typeof subscriptionClient.products>>;

type ProductsQueryContextType = ReturnType<typeof useMakeQuery<ApiResponse>>;

const ProductsQueryContext = React.createContext<ProductsQueryContextType | undefined>(undefined);

const ProductsQueryContextProvider = ({
  children,
  config = {},
}: {
  children: React.ReactNode | ((query: ProductsQueryContextType) => React.ReactNode);
  config?: UseQueryOptionsObject<ApiResponse>;
}) => {
  const query = useMakeQuery<ApiResponse>(
    [globalQueries.stripeProducts],
    ({ signal }) => subscriptionClient.products({ signal }),
    {
      suspense: true,
      retry: false,
      staleTime: 24 * 60 * 60 * 1000,
      ...config,
    },
  );

  return (
    <ProductsQueryContext.Provider value={query}>
      {render(children, query)}
    </ProductsQueryContext.Provider>
  );
};

const useProductsQueryContext = () => {
  const context = React.useContext(ProductsQueryContext);

  if (!context) {
    throw new Error('useProductsQueryContext must be used within a ProductsQueryContext');
  }

  return context;
};

const useProductsQueryData = () => {
  const query = useMakeQuery<ApiResponse>(
    [globalQueries.stripeProducts],
    ({ signal }) => subscriptionClient.products({ signal }),
    {
      enabled: false,
    },
  );

  return query.data;
};

export {
  ProductsQueryContext,
  ProductsQueryContextProvider,
  useProductsQueryContext,
  useProductsQueryData,
};
