import {
  SubscriptionBillingIntervalType,
  SubscriptionPlanType,
} from 'src/lib/services/api/subscription-api';
import { LocalStorage } from 'src/services/LocalStorage';

type DefaultPlanValues = {
  plan?: SubscriptionPlanType;
  interval?: SubscriptionBillingIntervalType;
};

type DefaultPlan = DefaultPlanValues & {
  expires: number;
};

const storageKey = 'becreatives-plan';

const useDefaultPlan = () => {
  const set = (defaultPlan: DefaultPlanValues) => {
    const expires = new Date().getTime() + 24 * 60 * 60 * 1000; // expire in 24 hours
    const values = {
      ...defaultPlan,
      expires,
    };

    LocalStorage.set(storageKey, values);
  };

  const get = (): DefaultPlan | null => {
    const defaultPlan = LocalStorage.get<DefaultPlan>(storageKey, true);

    if (!defaultPlan) {
      return null;
    }

    if (defaultPlan.expires < new Date().getTime()) {
      remove();

      return null;
    }

    return defaultPlan;
  };

  const remove = () => {
    LocalStorage.remove(storageKey);
  };

  return {
    set,
    get,
    remove,
  };
};

export { useDefaultPlan };
