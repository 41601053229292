import LogoPeach from 'src/assets/images/logo-peach.png';
import LogoPeachSm from 'src/assets/images/logo-peach-sm.png';
import LogoPeachXs from 'src/assets/images/logo-peach-xs.png';
import LogoShortSm from 'src/assets/images/becreatives-short-icon.png';

export default class ImageAsset {
  public static readonly LogoMain: string = LogoPeach;
  public static readonly LogoMainSm: string = LogoPeachSm;
  public static readonly LogoMainXs: string = LogoPeachXs;
  public static readonly LogoShortSm: string = LogoShortSm;
}
