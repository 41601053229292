import React from 'react';
import clsx from 'clsx';
import MinimalMediaCard from 'src/components/MediaCard/MinimalMediaCard/MinimalMediaCard';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>>;

const RevisionMediaList: React.FC<Props> = ({ children, ...props }) => {
  const { setSelectedRevisionMedia, selectedRevisionMedia, selectedRevision } =
    useRequestRevisionsStore();

  return (
    <div
      {...props}
      className={clsx(
        'rounded-3 position-relative d-flex gap-3 w-full tw-h-36 xl:tw-h-auto xl:tw-w-32 xl:tw-flex-col',
        props.className,
      )}
    >
      <div
        className={
          'gap-2 px-2 px-md-0 py-2 tw-absolute tw-inset-0 tw-flex tw-items-end tw-overflow-x-auto xl:tw-flex-col xl:tw-items-center xl:tw-overflow-y-auto'
        }
      >
        {selectedRevision?.media?.map((m) => (
          <div
            key={m?.id}
            className={clsx('p-2 pointer', {
              'rounded-3': selectedRevisionMedia?.id === m?.id,
            })}
            style={{
              backgroundColor: selectedRevisionMedia?.id === m?.id ? '#E8F1FF' : undefined,
              border:
                selectedRevisionMedia?.id === m?.id ? '1px solid #2779C6' : '1px solid transparent',
              transition: 'all 0.25s ease-in-out',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRevisionMedia(m?.id);
            }}
          >
            <MinimalMediaCard
              displayName={true}
              canView={false}
              canDownload={true}
              media={m!}
              className={'tw-mx-auto'}
            />
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};

export type { Props as RevisionMediaListProps };
export { RevisionMediaList };
