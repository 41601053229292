import React, { forwardRef } from 'react';

const makeBxsIcon = (css: string) => {
  return forwardRef<HTMLElement, React.ComponentPropsWithoutRef<'i'>>(function BxsIcon(
    { className, ...props },
    ref,
  ) {
    const cssClasses = [css];
    if (className) {
      cssClasses.push(className);
    }

    return <i {...props} className={cssClasses.join(' ')} ref={ref} />;
  });
};

const BxsCloseIcon = makeBxsIcon('bx bxs-x-circle');

const BxsCheckCircleIcon = makeBxsIcon('bx bxs-check-circle');

const BxsCheckIcon = makeBxsIcon('bx bx-check');

const BxCloseIcon = makeBxsIcon('bx bx-x');

const BxPlusCircleIcon = makeBxsIcon('bx bx-plus-circle');

const BxPlusIcon = makeBxsIcon('bx bx-plus');

const BxHomeIcon = makeBxsIcon('bx bx-home');

const BxLoaderCircleIcon = makeBxsIcon('bx bx-loader-circle');

const BxShareIcon = makeBxsIcon('bx bx-share-alt');

const BxCloudDownloadIcon = makeBxsIcon('bx bx-cloud-download');

const BxCopyIcon = makeBxsIcon('bx bx-copy');

const BxClipboardIcon = makeBxsIcon('bx bx-clipboard');

const BxMusicIcon = makeBxsIcon('bx bx-music');

const BxLogOutIcon = makeBxsIcon('bx bx-log-out');

const BxLeftArrowCircleIcon = makeBxsIcon('bx bx-left-arrow-circle');

const BxRightArrowCircleIcon = makeBxsIcon('bx bx-right-arrow-circle');

const BxChevronUp = makeBxsIcon('bx bx-chevron-up');

const BxChevronDown = makeBxsIcon('bx bx-chevron-down');

const BxGroupIcon = makeBxsIcon('bx bx-group');

const BxListIcon = makeBxsIcon('bx bx-list-ul');

const BxDashboardIcon = makeBxsIcon('bx bxs-dashboard');

const BxMenuLeftIcon = makeBxsIcon('bx bx-menu-alt-left');

const BxCommentIcon = makeBxsIcon('bx bxs-comment');

const BxMoveIcon = makeBxsIcon('bx bx-move-vertical');

const BxErrorCircleIcon = makeBxsIcon('bx bxs-error-circle');

const BxInfoCircle = makeBxsIcon('bx bx-info-circle');

const BxFilterIcon = makeBxsIcon('bx bx-filter-alt');

export {
  BxsCloseIcon,
  BxsCheckCircleIcon,
  BxsCheckIcon,
  BxCloseIcon,
  BxPlusCircleIcon,
  BxPlusIcon,
  BxHomeIcon,
  BxLoaderCircleIcon,
  BxShareIcon,
  BxCloudDownloadIcon,
  BxCopyIcon,
  BxClipboardIcon,
  BxMusicIcon,
  BxLogOutIcon,
  BxLeftArrowCircleIcon,
  BxRightArrowCircleIcon,
  BxChevronUp,
  BxChevronDown,
  BxGroupIcon,
  BxListIcon,
  BxDashboardIcon,
  BxMenuLeftIcon,
  BxCommentIcon,
  BxMoveIcon,
  BxErrorCircleIcon,
  BxInfoCircle,
  BxFilterIcon,
};
