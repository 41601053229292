import React from 'react';
import { render } from 'src/lib/utils';
import { createSubscriptionStore } from 'src/features/subscription/subscription-form/use-subscription-form-store';
import { useDefaultPlan } from 'src/features/subscription/subscription-form/use-default-plan';
import { subscriptionBillingInterval } from 'src/lib/services/api/subscription-api';

type SubscriptionFormStore = ReturnType<typeof createSubscriptionStore>;

const SubscriptionFormStoreContext = React.createContext<SubscriptionFormStore | null>(null);

const SubscriptionFormStoreContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { get } = useDefaultPlan();

  const defaultValues = get();

  // Initialize subscription form store with pre-selected plan and interval, if it is set.
  // Unable to delete the default values from storage initially, because multiple tabs can interact with it.
  const store = React.useRef(
    createSubscriptionStore({
      plan: defaultValues?.plan,
      billing_interval: defaultValues?.interval || subscriptionBillingInterval.quarterly,
    }),
  ).current;

  return (
    <SubscriptionFormStoreContext.Provider value={store}>
      {render(children)}
    </SubscriptionFormStoreContext.Provider>
  );
};

const useSubscriptionFormStoreContext = () => {
  const context = React.useContext(SubscriptionFormStoreContext);

  if (!context) {
    throw new Error(
      'useSubscriptionFormStoreContext must be used within a SubscriptionFormStoreContext',
    );
  }

  return context;
};

export {
  SubscriptionFormStoreContext,
  SubscriptionFormStoreContextProvider,
  useSubscriptionFormStoreContext,
};
