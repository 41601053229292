import { SetStateAction, useCallback, useContext } from 'react';
import { SortingState } from '@tanstack/react-table';
import { PaginationState } from '@tanstack/table-core/src/features/Pagination';
import { TableStoreContext } from './Table.context';
import { SetPaginationFn, TableColumnFilter } from './Table.types';
import { isPaginationObject } from './Table.guards';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'src/lib/services/api-query-params';

const useTableStore = () => {
  const { state, setFilters, ...context } = useContext(TableStoreContext);

  const setSearch = useCallback((search: string) => {
    setFilters((prev) => {
      return { ...prev, search, page: DEFAULT_PAGE_INDEX };
    });
  }, []);

  const setPagination = useCallback((args: PaginationState | SetPaginationFn): void => {
    if (isPaginationObject(args)) {
      setFilters((prev) => ({ ...prev, args }));
      return;
    }

    setFilters((prev) => {
      const pagination = (args as any)({
        pageIndex: prev.page ?? DEFAULT_PAGE_INDEX,
        pageSize: prev.limit ?? DEFAULT_PAGE_SIZE,
      });

      return {
        ...prev,
        page: pagination.pageIndex,
        limit: pagination.pageSize,
      };
    });
  }, []);

  const setSorting = useCallback((args: SortingState | SetStateAction<SortingState>): void => {
    if (Array.isArray(args)) {
      setFilters((prev) => ({ ...prev, sorts: args }));
      return;
    }

    setFilters((prev) => {
      const sorts = (args as any)(prev.sorts ?? []);
      return {
        ...prev,
        sorts,
      };
    });
  }, []);

  // TODO: null values should be filterable
  const setFilter = useCallback((args: TableColumnFilter) => {
    setFilters((prev) => {
      const filters = (prev.filters ?? []).filter((filter) => filter.id !== args.id);
      const result = {
        ...prev,
        filters,
        page: DEFAULT_PAGE_INDEX,
      };

      if (!args.value) {
        return result;
      }

      result.filters.push(args);

      return result;
    });
  }, []);

  return {
    setPagination,
    setSearch,
    setSorting,
    setFilter,
    pagination: {
      pageIndex: state.filters?.page ?? DEFAULT_PAGE_INDEX,
      pageSize: state.filters?.limit ?? DEFAULT_PAGE_SIZE,
    },
    search: state.filters?.search ?? '',
    sorting: state.filters?.sorts ?? [],
    filters: state.filters?.filters ?? [],
    data: state.data,
    isSuccess: state.isSuccess,
    isLoading: state.isLoading,
    isFetching: state.isFetching,
    error: state.error,
    totalPages: state.totalPages,
    totalItems: state.totalItems,
    columnVisibility: state.columnVisibility,
    setColumnVisibility: state.setColumnVisibility,
    expanded: state.expanded,
    setExpanded: state.setExpanded,
    ...context,
  };
};

export { useTableStore };
