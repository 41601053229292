import { SortParam } from 'src/lib/services/api-query-params/api-query-params.types';
import { useApiQueryParams } from 'src/lib/services/api-query-params/use-api-query-params';
import { useTranslation } from 'react-i18next';
import { OptionObject } from 'src/components/ReactSelectInput';

type SortFormat = 'date' | 'number' | 'text';

type SortItem = {
  label: string;
  keywords?: string[];
  format?: SortFormat;
  sort: SortParam;
};

const defaultSortOrderLabels = {
  text: {
    asc: 'A to Z',
    desc: 'Z to A',
  },
  number: {
    asc: 'Lowest to highest',
    desc: 'Highest to lowest',
  },
  date: {
    asc: 'Oldest to newest',
    desc: 'Newest to oldest',
  },
};

type GetSortOrderOptionsFn = (format?: SortFormat) => OptionObject[];

const useSorts = () => {
  const { t } = useTranslation();
  const { filters, setFilters } = useApiQueryParams();

  const addSort = (value: SortParam) => {
    setFilters((prev) => ({ ...prev, sorts: [...(prev.sorts ?? []), value] }));
  };

  const updateSort = (id: string, value: SortParam) => {
    setFilters((prev) => {
      const next = prev.sorts;

      if (!next) {
        return prev;
      }

      const index = next.findIndex((s) => s.id === id);

      if (index > -1) {
        next.splice(index, 1, value);
      }

      return {
        ...prev,
        sorts: next,
      };
    });
  };

  const removeSort = (sort: SortParam) => {
    setFilters((prev) => {
      const sorts = prev.sorts?.filter((s) => s.id !== sort.id);

      return {
        ...prev,
        sorts: sorts?.length ? sorts : undefined,
      };
    });
  };

  const clearSorts = () => {
    setFilters({ sorts: undefined });
  };

  const getAvailableSorts = (sortList: SortItem[]) =>
    sortList.filter((sort) => !filters.sorts?.some((s) => s.id === sort.sort.id));

  const getSortOrderOptions: GetSortOrderOptionsFn = (format = 'text') => [
    {
      label: t(`common:sort.${format}.asc`, defaultSortOrderLabels[format].asc),
      value: false,
    },
    {
      label: t(`common:sort.${format}.asc`, defaultSortOrderLabels[format].desc),
      value: true,
    },
  ];

  return {
    sorts: filters.sorts,
    addSort,
    updateSort,
    removeSort,
    clearSorts,
    getAvailableSorts,
    getSortOrderOptions,
  };
};

export { useSorts, type SortItem, type GetSortOrderOptionsFn };
