import React from 'react';
import { ToastContainer } from 'react-toastify';
import { containerProps } from 'src/components/Toast/toast.utils';

const ReactToastProvider: React.FC = () => {
  return (
    <ToastContainer
      className={'tw-pointer-events-auto tw-z-[999999]'}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...containerProps}
    />
  );
};

export { ReactToastProvider };
