import React, { useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Column,
  Table as TableType,
} from '@tanstack/react-table';
import { TableProps } from 'src/components/Table/Table.types';
import { useTableStore } from 'src/components/Table/useTableStore';
import { If } from 'src/components/If';
import { TableColumnsVisibilityToggle } from 'src/components/Table/table-columns-visibility-toggle';
import { cn, render } from 'src/lib/utils';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { ChevronsUpDown, MoveDown, MoveUp } from 'lucide-react';
import { Skeleton } from 'src/components/ui/skeleton';
import { Button, ButtonProps } from 'src/components/ui/button';
import { TableNavigator } from 'src/components/Table/table-navigator';
import { NoRecordsFallback } from 'src/features/fallback/ui/no-records-fallback';
import { useBreakpoint } from 'src/lib/hooks';

const ComposedTable: React.FC<React.PropsWithChildren<TableProps>> = ({
  columns,
  enableColumnsHide = true,
  getRowCanExpand = () => false,
  expandedRowComponent,
  children,
  ...props
}) => {
  const store = useTableStore();

  const table = useReactTable({
    data: store.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: store.totalPages,
    manualPagination: true,
    state: {
      pagination: store.pagination,
      sorting: store.sorting,
      columnVisibility: store.columnVisibility,
      expanded: store.expanded,
    },
    onColumnVisibilityChange: store.setColumnVisibility,
    onExpandedChange: store.setExpanded,
    onPaginationChange: store.setPagination,
    onSortingChange: store.setSorting,
    manualSorting: true,
    manualExpanding: true,
    paginateExpandedRows: false,
    getRowCanExpand,
  });

  return (
    <ComposedTableWrapper>
      <ComposedTableTop>
        {children}

        {enableColumnsHide && (
          <ComposedTableColumnVisibilityToggle columns={table.getAllLeafColumns()} />
        )}
      </ComposedTableTop>

      <Table
        wrapperProps={{
          className: 'tw-rounded-b-none',
        }}
      >
        <ComposedTableHeader table={table} />

        <TableBody>
          {!store.isLoading && !table.getRowModel().rows.length ? <ComposedTableBodyEmpty /> : null}
          {store.isLoading ? (
            <TableBodySkeleton columnCount={table.getVisibleLeafColumns().length} />
          ) : (
            table.getRowModel().rows.map((row) => {
              const { className, active, ...rowProps } = props.tableRowProps?.(row) ?? {};
              const canExpand = row.getCanExpand();
              const isExpanded = row.getIsExpanded();

              return (
                <React.Fragment key={row.id}>
                  <TableRow
                    active={canExpand && isExpanded ? true : active}
                    className={cn('tw-group/row', canExpand && 'tw-peer', className)}
                    {...rowProps}
                  >
                    {row.getVisibleCells().map((cell, i) => {
                      const cellContext = cell.getContext();
                      const isLastColumn = row.getVisibleCells().length - 1 === i;
                      const visibleColumnCount = row.getVisibleCells().length;

                      return (
                        <TableCell
                          key={cell.id}
                          className={cn({
                            'tw-text-end': isLastColumn && visibleColumnCount > 1,
                          })}
                        >
                          {flexRender(cell.column.columnDef.cell, {
                            ...cellContext,
                            helpers: {
                              refetch: store.refetch,
                              setData: store.setData,
                              pagination: store.pagination,
                              setPagination: store.setPagination,
                            },
                            row: {
                              ...cellContext.row,
                              edit: store.getEditRow(cellContext.row.index),
                            },
                            rows: store.data,
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {canExpand && isExpanded ? render(expandedRowComponent, row) : null}
                </React.Fragment>
              );
            })
          )}
        </TableBody>
      </Table>

      <ComposedTableBottom>
        <TableNavigator
          totalCount={store.totalItems}
          pageSize={table.getState().pagination.pageSize}
          currentPage={table.getState().pagination.pageIndex + 1}
          onPageChange={(page) => {
            table.setPageIndex(page);
          }}
        />
      </ComposedTableBottom>
    </ComposedTableWrapper>
  );
};

const TableBodySkeleton: React.FC<{ columnCount: number }> = ({ columnCount }) => {
  return (
    <>
      {[...Array(10).keys()].map((i) => (
        <TableRow key={`skeleton-row-${i}`}>
          {[...Array(columnCount).keys()].map((j) => {
            const isLastCol = j === columnCount - 1;

            return (
              <TableCell
                key={`skeleton-row-${i}-col-${j}`}
                className={cn(isLastCol && columnCount > 1 && 'tw-flex tw-justify-end')}
              >
                <Skeleton className={cn('tw-h-5', [columnCount > 1 ? 'tw-w-24' : 'tw-w-full'])} />
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </>
  );
};

const ComposedTableWrapper: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ children, className, ...props }) => {
  // todo: wrapper height is a POC. need to rethink it
  return (
    <div
      className={cn('tw-flex tw-h-full tw-w-full tw-grow tw-flex-col', className)}
      // style={{
      //   height: 'calc(100vh - 200px)',
      //   minHeight: '650px',
      // }}
      {...props}
    >
      {children}
    </div>
  );
};

const ComposedTableTop: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={cn('tw-flex tw-flex-wrap tw-items-center tw-gap-3 tw-pb-4 sm:tw-gap-0', className)}
      {...props}
    >
      {children}
    </div>
  );
};

const ComposedTableColumnVisibilityToggle: React.FC<
  ButtonProps & { columns: Column<any, any>[] }
> = ({ columns, className, ...props }) => {
  const { isMobile } = useBreakpoint();

  // do not allow to hide first and last table columns
  const toggleableColumns = React.useMemo(() => {
    // do not allow to hide first and last table columns
    const toggleableColumns = [...columns];
    toggleableColumns.shift();
    toggleableColumns.pop();

    return toggleableColumns;
  }, [columns]);

  // Only show first column for mobile
  React.useLayoutEffect(() => {
    if (isMobile) {
      columns[0].toggleVisibility(true);
      columns.slice(1).forEach((col) => col.toggleVisibility(false));
    } else {
      columns.forEach((col) => col.toggleVisibility(true));
    }
  }, [isMobile]);

  if (!toggleableColumns.length) {
    return null;
  }

  return (
    <TableColumnsVisibilityToggle
      columns={toggleableColumns as any}
      menuTriggerProps={{
        className: cn('tw-ms-auto tw-hidden md:tw-inline-flex', className),
        ...props,
      }}
      getIsAllColumnsVisible={() => {
        return toggleableColumns.every((col) => col.getIsVisible());
      }}
      getIsSomeColumnsVisible={() => toggleableColumns.some((col) => col.getIsVisible())}
      getToggleAllColumnsVisibilityHandler={() => (checked) => {
        toggleableColumns.forEach((col) => col.toggleVisibility(checked as boolean));
      }}
    />
  );
};

const ComposedTableHeader: React.FC<
  React.HTMLAttributes<HTMLTableSectionElement> & { table: TableType<any> }
> = ({ table, className, ...props }) => {
  return (
    <TableHeader
      className={cn(
        'tw-sticky tw-top-0 tw-z-10 tw-hidden tw-bg-background md:tw-table-header-group',
        className,
      )}
      {...props}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id} variant={'unset'}>
          {headerGroup.headers.map((header, i) => {
            const isLastColumn = headerGroup.headers.length - 1 === i;
            const sortable = header.column.getCanSort();
            const isSorted = header.column.getIsSorted();
            const visibleColumnCount = table.getVisibleLeafColumns().length;

            return (
              <TableHead
                key={header.id}
                style={{
                  width: header.getSize(),
                  minWidth: header.column.columnDef.minSize,
                }}
                className={cn('tw-table-cell', {
                  'tw-text-end': isLastColumn && visibleColumnCount > 1,
                  'tw-px-2': sortable,
                })}
              >
                <If when={!header.isPlaceholder}>
                  <If
                    when={sortable}
                    else={flexRender(header.column.columnDef.header, header.getContext())}
                  >
                    <Button
                      variant={'ghost'}
                      className={cn(
                        'tw-justify-start tw-gap-1 tw-px-2',
                        isLastColumn && 'tw-justify-end',
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}

                      <If
                        when={!!isSorted}
                        else={
                          <div>
                            <ChevronsUpDown size={14} />
                          </div>
                        }
                      >
                        <div>
                          {isSorted === 'asc' && <MoveDown size={16} />}
                          {isSorted === 'desc' && <MoveUp size={16} />}
                        </div>
                      </If>
                    </Button>
                  </If>
                </If>
              </TableHead>
            );
          })}
        </TableRow>
      ))}
    </TableHeader>
  );
};

const ComposedTableBottom: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ children, className, ...props }) => {
  return (
    <div
      className={cn(
        'tw-rounded-b-lg tw-border tw-border-t-0 tw-border-neutral-200 tw-bg-background tw-p-3',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

const ComposedTableBodyEmpty: React.FC<React.HTMLAttributes<HTMLTableCellElement>> = ({
  ...props
}) => {
  return (
    <tr>
      <td colSpan={99} {...props}>
        <NoRecordsFallback />
      </td>
    </tr>
  );
};

export {
  ComposedTable,
  TableBodySkeleton,
  ComposedTableBodyEmpty,
  ComposedTableWrapper,
  ComposedTableTop,
  ComposedTableColumnVisibilityToggle,
  ComposedTableHeader,
  ComposedTableBottom,
};
