import React from 'react';
import { VariantProps } from 'class-variance-authority';
import { linkVariants } from 'src/components/ui/link';
import { cn } from 'src/lib/utils';
import { Slot } from '@radix-ui/react-slot';

export type HrefProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  VariantProps<typeof linkVariants> & { asChild?: boolean };

const Href = React.forwardRef<HTMLAnchorElement, HrefProps>(
  ({ className, underline, variant, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'a';

    return (
      <Comp
        className={cn(
          linkVariants({
            variant,
            underline,
            className,
          }),
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Href.displayName = 'Href';

export { Href };
