import { create } from 'zustand';
import { AuthUtils } from 'src/services/Auth/Auth.utils';

interface UseGlobalLoadingStore {
  isGlobalLoading: boolean;
  setGlobalLoading: (isGlobalLoading: boolean) => void;
}

const useGlobalLoadingStore = create<UseGlobalLoadingStore>((set) => ({
  isGlobalLoading: !AuthUtils.isExpired(),
  setGlobalLoading: (isGlobalLoading) => set(() => ({ isGlobalLoading })),
}));

export { useGlobalLoadingStore };
