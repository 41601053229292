const acronym = (string: string | undefined | null): string => {
  if (!string) {
    return '';
  }

  return string
    .replace(/\s+/g, ' ')
    .split(' ')
    .reduce((initials, word) => initials + word.charAt(0), '');
};

const toJson: <T = unknown>(json: string | null | undefined, fallback?: T) => T = (
  json,
  fallback,
) => {
  if (!json) {
    return fallback;
  }

  try {
    return JSON.parse(json);
  } catch (e: unknown) {
    return fallback;
  }
};

const slugify = (string: string): string => {
  return string
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

const ucfirst = (string: string): string => {
  return string[0].toUpperCase() + string.slice(1);
};

const lcfirst = (string: string): string => {
  return string[0].toLowerCase() + string.slice(1);
};

const pad = (str: string | number, n: number): string => {
  const parsed = `${str}`;
  const i = parsed.indexOf('.');

  if (!i) {
    return parsed;
  }

  return parsed.substring(0, i + 1 + n);
};

const formatPercents = (percents: number): string => {
  return `${percents} %`;
};

const embedUrlsAsLinks = (str: string): string => {
  const rgx = new RegExp(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.?[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/g,
  );

  return str.replace(rgx, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener" class="tw-text-blue tw-underline-offset-4 hover:!tw-underline">${url}</a>`;
  });
};

const is = (target: string, value: unknown): boolean => target === value;

const str = {
  acronym,
  toJson,
  slugify,
  ucfirst,
  lcfirst,
  pad,
  formatPercents,
  embedUrlsAsLinks,
  is,
};

export { str };
