import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatter } from 'src/services/i18n';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Alert, AlertIcon, AlertTitle } from 'src/components/ui/alert';
import { cn } from 'src/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  needsAttention?: string | null;
  needsReedit?: string | null;
  approved?: string | null;
  wrapper?: boolean;
} & VariantProps<typeof statusDetailsVariants>;

const statusDetailsVariants = cva('tw-m-0', {
  variants: {
    size: {
      default: 'tw-text-base',
      sm: 'tw-text-sm',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

const statusDetailsContainerVariants = cva('tw-flex tw-items-center tw-gap-1', {
  variants: {
    size: {
      default: 'tw-px-2 tw-py-1',
      sm: 'tw-px-1 tw-py-0.5',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

const RequestStatusDetails: React.FC<Props> = ({
  needsAttention,
  needsReedit,
  approved,
  size,
  className,
  wrapper = true,
  ...props
}) => {
  if (!needsAttention && !needsReedit && !approved) {
    return null;
  }

  return (
    <TooltipProvider>
      {wrapper ? (
        <div className={cn('tw-flex tw-flex-wrap tw-gap-2 md:tw-gap-3', className)} {...props}>
          <RequestStatusDetailsContent
            needsAttention={needsAttention}
            needsReedit={needsReedit}
            approved={approved}
            size={size}
          />
        </div>
      ) : (
        <RequestStatusDetailsContent
          needsAttention={needsAttention}
          needsReedit={needsReedit}
          approved={approved}
          size={size}
        />
      )}
    </TooltipProvider>
  );
};

const RequestStatusDetailsContent: React.FC<
  Pick<Props, 'needsAttention' | 'needsReedit' | 'approved' | 'size'>
> = ({ needsAttention, needsReedit, approved, size }) => {
  const { t } = useTranslation();
  const { formatDateTime } = useFormatter();

  return (
    <React.Fragment>
      {!!needsAttention && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Alert
              variant={'warning'}
              layout={'flex'}
              className={cn(statusDetailsContainerVariants({ size }))}
            >
              <div>
                <AlertIcon variant={'warning'} sizeVariant={size} />
              </div>
              <AlertTitle className={cn(statusDetailsVariants({ size }))}>
                {t('common:needs_attention', { defaultValue: 'Needs attention' })}
              </AlertTitle>
            </Alert>
          </TooltipTrigger>
          <TooltipContent>{formatDateTime(needsAttention)}</TooltipContent>
        </Tooltip>
      )}
      {!!needsReedit && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Alert
              variant={'destructive'}
              layout={'flex'}
              className={cn(statusDetailsContainerVariants({ size }))}
            >
              <div>
                <AlertIcon variant={'destructive'} sizeVariant={size} />
              </div>
              <AlertTitle className={cn(statusDetailsVariants({ size }))}>
                {t('common:needs_reedit', { defaultValue: 'Needs re-edit' })}
              </AlertTitle>
            </Alert>
          </TooltipTrigger>
          <TooltipContent>{formatDateTime(needsReedit)}</TooltipContent>
        </Tooltip>
      )}
      {!!approved && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Alert
              variant={'success'}
              layout={'flex'}
              className={cn(statusDetailsContainerVariants({ size }))}
            >
              <div>
                <AlertIcon variant={'success'} sizeVariant={size} />
              </div>
              <AlertTitle className={cn(statusDetailsVariants({ size }))}>
                {t('common:approved', { defaultValue: 'Approved' })}
              </AlertTitle>
            </Alert>
          </TooltipTrigger>
          <TooltipContent>{formatDateTime(approved)}</TooltipContent>
        </Tooltip>
      )}
    </React.Fragment>
  );
};

export { RequestStatusDetails };
