import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'src/lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { useTranslation } from 'react-i18next';

const variants = cva(
  'tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-indigo-500 tw-text-lg tw-text-text',
  {
    variants: {
      variant: {
        default: 'tw-border-solid tw-bg-indigo-300',
        additional: 'tw-border-dashed',
      },
      size: {
        default:
          'tw-h-10 tw-min-h-10 tw-w-10 tw-min-w-10 tw-font-bold desktop:tw-h-12 desktop:tw-min-h-12 desktop:tw-w-12 desktop:tw-min-w-12',
        sm: 'tw-h-9 tw-min-h-9 tw-w-9 tw-min-w-9 tw-font-bold',
        xs: 'tw-h-6 tw-min-h-6 tw-w-6 tw-min-w-6 tw-border tw-text-base tw-font-medium',
      },
    },
    defaultVariants: {
      variant: 'additional',
      size: 'default',
    },
  },
);

const RequestOutcomePriority = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof variants>
>(({ children, className, variant, size, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={cn(variants({ variant, size }), className)} ref={ref} {...props}>
            {children}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          {t('common:priority', {
            defaultValue: 'Priority',
          })}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
});

export { RequestOutcomePriority };
