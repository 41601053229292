import React from 'react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { SpaceErrorFallback } from 'src/features/fallback';
import { Skeleton } from 'src/components/ui/skeleton';
import { RequestOutcomesQueryContextProvider } from 'src/features/requests/request-outcome/use-request-outcomes-query-context';
import {
  EmptySpaceFallback,
  EmptySpaceFallbackDescription,
  EmptySpaceFallbackHeader,
  EmptySpaceFallbackImage,
  EmptySpaceFallbackTitle,
} from 'src/features/fallback/ui';
import { If } from 'src/components/If';
import { useTranslation } from 'react-i18next';
import { RequestDeliverableOutcomeCard } from 'src/features/requests/request-page/multi-outcome';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { Link } from 'src/components/ui/link';
import { appRoutes } from 'src/routes';
import { generatePath } from 'react-router-dom';
import { DecoratedRequestOutcomeContextProvider } from 'src/features/requests/request-outcome/use-decorated-request-outcome-context';
import { deliverableOutcomesFilters } from 'src/features/requests/request-outcome/filter-queries';

const RequestDeliverableOutcomesGrid: React.FC<{ requestId: string }> = ({ requestId }) => {
  const { t } = useTranslation();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }: any) => (
            <SpaceErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
          )}
          onReset={reset}
        >
          <React.Suspense fallback={<RequestDeliverablesFallback />}>
            <RequestOutcomesQueryContextProvider
              id={requestId}
              filters={deliverableOutcomesFilters}
            >
              {({ data }) => (
                <If
                  when={!!data?.items.length}
                  else={
                    <EmptySpaceFallback>
                      <EmptySpaceFallbackImage className={'tw-mx-auto'} />

                      <EmptySpaceFallbackHeader className={'tw-gap-2 tw-text-center'}>
                        <EmptySpaceFallbackTitle>
                          {t('common:empty_space', {
                            defaultValue: "There's an empty space",
                          })}
                        </EmptySpaceFallbackTitle>
                        <EmptySpaceFallbackDescription>
                          {t('alerts:info.no_pending_outcomes', {
                            defaultValue: 'You have no deliverables yet',
                          })}
                        </EmptySpaceFallbackDescription>
                      </EmptySpaceFallbackHeader>
                    </EmptySpaceFallback>
                  }
                >
                  <div
                    className={
                      'tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3 desktop:tw-grid-cols-4'
                    }
                  >
                    {data?.items.map((outcome) => (
                      <DecoratedRequestOutcomeContextProvider
                        outcome={outcome}
                        key={`outcome-deliverable-${outcome.id}`}
                      >
                        <Link
                          to={generatePath(appRoutes.requestRevisions, { requestId: outcome.id })}
                          variant={'unset'}
                        >
                          <RequestDeliverableOutcomeCard
                            className={'tw-transition-colors hover:tw-border-indigo-300'}
                          />
                        </Link>
                      </DecoratedRequestOutcomeContextProvider>
                    ))}
                  </div>
                </If>
              )}
            </RequestOutcomesQueryContextProvider>
          </React.Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

const RequestDeliverablesFallback: React.FC = () => {
  return (
    <div className={'tw-h-full tw-w-full'}>
      <div
        className={
          'tw-grid tw-h-full tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3 desktop:tw-grid-cols-4'
        }
      >
        <div>
          <AspectRatio ratio={16 / 9}>
            <Skeleton className={'tw-h-full tw-w-full tw-rounded-b-none'} />
          </AspectRatio>
          <div className={'tw-bg-neutral-100 tw-px-4 tw-py-3'}>
            <div className={'tw-mb-4'}>
              <Skeleton className={'tw-h-5'} />
            </div>
            <div className={'tw-flex tw-gap-2'}>
              <Skeleton className={'tw-h-9 tw-w-16'} />
              <Skeleton className={'tw-h-9 tw-w-9'} />
              <Skeleton className={'tw-h-9 tw-w-9'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { RequestDeliverableOutcomesGrid };
