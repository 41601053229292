import React, { lazy, PropsWithChildren } from 'react';
import { useProfile } from 'src/models';
import { AuthUtils } from 'src/services/Auth/Auth.utils';

const AuthenticatedLayout = lazy(
  () => import('src/components/ui/layouts/authenticated-layout/authenticated-layout'),
);
const FullPageWrapper = lazy(() => import('src/containers/FullPageWrapper'));

const WrapperLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const isLogged = !AuthUtils.isExpired();
  const { isOnboarded } = useProfile({ enabled: false });

  const Wrapper = isLogged && isOnboarded ? AuthenticatedLayout : FullPageWrapper;

  return <Wrapper>{children}</Wrapper>;
};

export default WrapperLayout;
