import styled, { css } from 'styled-components';
import {
  breakpointUp,
  NavLink,
  Card,
  centeredFlexbox,
  Popover,
  BaseIcon,
  ThemeColor,
} from '@becreatives/becreatives-ui';
import { DropdownToggle } from 'reactstrap';
import RevisionFallbackImageGif from 'src/assets/images/revision-fallback-image.gif';

const RevisionsContentMainSection = styled.section`
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;

  ${breakpointUp.lg} {
    grid-template-columns: 2fr 1fr;
  }
`;

const RevisionMainContentCard = styled(Card)<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  padding: 0 0.5rem;

  ${breakpointUp.md} {
    padding: 0 1.563rem;
  }

  ${({ $disabled }) =>
    $disabled
      ? css`
          * {
            pointer-events: none;
            opacity: 0.25;
          }
        `
      : []};
`;

const RevisionVideoControlRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.3rem 0;

  ${breakpointUp.md} {
    padding: 1.2rem 0;
  }
`;

const RevisionVersionControlButton = styled(DropdownToggle)<{ color?: ThemeColor | string }>`
  cursor: pointer;
  color: ${({ theme, color }) => color ?? theme.color.dark};
  font-weight: 500;
  ${centeredFlexbox};
  transition: color 250ms;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

const RevisionCursorNavLink = styled(NavLink)`
  user-select: none;
  ${centeredFlexbox};

  ${BaseIcon} {
    width: 0.7rem;
    height: 0.7rem;

    ${breakpointUp.md} {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`;

const RevisionFallbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 1rem 0;
  min-height: 50vh;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  width: 100%;

  ${breakpointUp.md} {
    padding: 2rem 0;
  }
`;

const RevisionFallbackImage = styled.img.attrs((props) => ({
  ...props,
  src: props?.src ?? RevisionFallbackImageGif,
}))`
  max-width: 100%;
`;

const RevisionVersionsPopover = styled(Popover)`
  .popover {
    --bs-popover-max-width: 367px;

    z-index: 1100 !important;
  }
`;

export {
  RevisionsContentMainSection,
  RevisionMainContentCard,
  RevisionFallbackWrapper,
  RevisionFallbackImage,
  RevisionVideoControlRow,
  RevisionCursorNavLink,
  RevisionVersionControlButton,
  RevisionVersionsPopover,
};
