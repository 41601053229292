import React from 'react';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { requestClient } from 'src/lib/services/api/request-api';
import { authQueries } from 'src/api/queries';
import { ApiQueryObject } from 'src/lib/services/api-query-params';
import { render } from 'src/lib/utils';
import { RequestOutcomesPaginatedResponse } from 'src/lib/services/api/request-api/request-client';

type RequestOutcomesQueryContextType = ReturnType<
  typeof useMakeQuery<RequestOutcomesPaginatedResponse>
>;

const RequestOutcomesQueryContext = React.createContext<
  RequestOutcomesQueryContextType | undefined
>(undefined);

const RequestOutcomesQueryContextProvider = ({
  children,
  id,
  filters = {},
}: {
  id: string;
  filters?: ApiQueryObject;
  children: React.ReactNode | ((query: RequestOutcomesQueryContextType) => React.ReactNode);
}) => {
  const query = useMakeQuery<RequestOutcomesPaginatedResponse>(
    [authQueries.requestOutcomes, id, filters],
    ({ signal }) => requestClient.outcomes(id, { signal, params: filters }),
    {
      suspense: true,
      retry: false,
      staleTime: 60 * 1000,
    },
  );

  return (
    <RequestOutcomesQueryContext.Provider value={query}>
      {render(children, query)}
    </RequestOutcomesQueryContext.Provider>
  );
};

const useRequestOutcomesQueryContext = () => {
  const context = React.useContext(RequestOutcomesQueryContext);

  if (!context) {
    throw new Error(
      'useRequestOutcomesQueryContext must be used within a RequestOutcomesQueryContext',
    );
  }

  return context;
};

export {
  RequestOutcomesQueryContext,
  RequestOutcomesQueryContextProvider,
  useRequestOutcomesQueryContext,
};
