import { MediaObject } from 'src/api/services/MediaClient';
import { ValueOfObject } from 'src/types/generics';
import { RevisionResponse } from 'src/lib/services/api/revision-api';
import { HasStatus, Submittable } from 'src/api/Interfaces';
import {
  MultipleOutcomesResponse,
  RequestAssetUrl,
  RequestExtraObject,
  RequestPriorityType,
  RequestResolutionsObject,
  RequestStatus,
  requestStatus,
  TotalLengthResponse,
} from 'src/lib/services/api/request-api/request.types';
import { VideoTemplateResponse } from 'src/lib/services/api/video-templates-api';

export const requestOutcomeStatus = {
  ...requestStatus,
  archived: 'archived',
} as const;

export type RequestOutcomeStatus = ValueOfObject<typeof requestOutcomeStatus> | string;

export const requestListTab = {
  all: 'all',
  ...requestStatus,
} as const;

export type RequestListTab = ValueOfObject<typeof requestListTab>;

export const requestFlow = {
  default: 'default',
  multiOutcome: 'multi_outcome',
  outcome: 'outcome',
  repurpose: 'repurpose',
} as const;

export interface RequestsTableResponse extends HasStatus<RequestStatus>, Submittable {
  id: string;
  title: string;
  type: string;
  flow: 'default' | 'multi_outcome' | 'outcome' | string;
  priority: RequestPriorityType;
  editor_note?: string;
  assigned_editor?: {
    id: string;
    full_name: string;
    avatar?: MediaObject;
  };
  brand?: {
    id: string;
    name: string;
  };
  reviewed: string | null;
  resolutions?: RequestResolutionsObject;
  approved_at?: string;
  requested_reedit_at?: string;
  requested_attention_at?: string;
  attention_text?: string;
  created_at: Date | string;
  updated_at: Date | string;

  outcomes_count: number;
  complete_outcomes_count: number;

  repurposable_at?: string;
  repurposed_at?: string;
}

export type RequestFlow = ValueOfObject<typeof requestFlow> | string;

export type RequestOutcomeResponse = {
  id: string;
  status: RequestOutcomeStatus | string;
  title: string;
  type: string;
  total_length?: TotalLengthResponse;
  description: string;
  is_default: boolean;
  sort: number;
  editor_note?: string;

  revisions_count?: number;
  versions_count?: number;

  requested_reedit_at: string | null;
  created_at: string;
  updated_at: string;

  created_by: string;
  creator_id: string;
  company_id: string;
  parent_id: string;
  editor: {
    id: string;
    first_name: string;
    last_name: string;
    avatar?: MediaObject;
  };
  latest_revision?: {
    id: string;
    created_at: string;
    video?: MediaObject;
  };
};

export type RequestResponse = {
  id: string;
  title: string;
  type: string;
  status: RequestStatus;
  editor_note: string;
  attention_text?: string;
  flow: RequestFlow;
  priority: RequestPriorityType;
  parent_id: string | null;

  reviewed: boolean;
  with_multiple_outcomes: boolean;

  resolutions?: RequestResolutionsObject;
  description?: {
    /** @deprecated */
    total_length?: string;
    video_request_description?: string;
  };
  total_length?: TotalLengthResponse;
  supporting_materials?: {
    assets_urls?: string[];
    music_preference?: string;
  };
  extra?: RequestExtraObject;

  reviewed_by_customer?: string;
  reviewed_by_editor?: string;
  status_changed_at?: string;
  submitted_at?: string;
  approved_at?: string;
  requested_reedit_at?: string;
  requested_attention_at?: string;
  created_at: string;

  revisions_count?: number;
  rated_revisions_count?: number;

  assets?: RequestAssetUrl[];
  editor?: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    avatar?: MediaObject;
  };
  brand?: {
    id: string;
    name: string;
    description: string;
    colors?: { color: string }[];
    created_at: string;
  };
  company?: {
    id: string;
    name: string;
  };
  media?: {
    footage: MediaObject[];
  };

  revisions: RevisionResponse[];

  assigned_editor?: {
    id: string;
    first_name: string;
    last_name: string;
  };

  video_template?: VideoTemplateResponse;

  multiple_outcomes: MultipleOutcomesResponse | null;

  repurposable_at?: string;
  repurposed_at?: string;
};

export type UpdatePriorityRequest = {
  priority: RequestPriorityType;
};

export interface ChangeStatusRequest {
  status: RequestStatus;
}

export type RequestRevisionPreviewResponse = {
  id: string;
  company_id: string;
  title: string;
  status: RequestStatus;
  type: string;
  main_revision_video?: MediaObject;
  repurposable_at?: string;
  created_at: string;
  updated_at: string;
};
