import './assets/scss/theme.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import React from 'react';
import { IntlProvider } from 'react-intl';
import ModalLoaderProvider from 'src/components/ModalLoader/Provider';
import { ConfirmContextProvider, ConfirmDialog } from 'src/components/ConfirmModal';

import { AppRoutes } from 'src/routes/app-routes';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <ModalLoaderProvider>
        <ConfirmContextProvider>
          <IntlProvider locale={'en'}>
            <AppRoutes />
            <ConfirmDialog />
          </IntlProvider>
        </ConfirmContextProvider>
      </ModalLoaderProvider>
    </React.Fragment>
  );
};

export default App;
