import { Client } from 'src/api/Client';
import {
  CompleteMultipartUploadRequest,
  GenerateMultipartUploadRequest,
  GenerateMultipartUploadResponse,
  MediaTemporaryUrlsResponse,
} from './MediaClient.types';
import { AxiosRequestConfig } from 'axios';

class MediaClient extends Client {
  public readonly route = {
    getTemporaryUrls: `${this.http.baseUrl}/media/{model}`,
    generateMultipartUpload: `${this.http.baseUrl}/media/{model}`,
    completeMultipartUpload: `${this.http.baseUrl}/media/{model}`,
    share: `${this.http.baseUrl}/media/{model}/share`,
  };

  getTemporaryUrls: (
    model: string,
    config?: AxiosRequestConfig,
  ) => Promise<MediaTemporaryUrlsResponse> = (model, config) => {
    return this.http.get(this.buildUrl(this.route.getTemporaryUrls, { model }), config);
  };

  generateMultipartUpload = (
    model: string,
    data: GenerateMultipartUploadRequest,
  ): Promise<GenerateMultipartUploadResponse> => {
    return this.http.post(this.buildUrl(this.route.generateMultipartUpload, { model }), data);
  };

  completeMultipartUpload = (
    model: string,
    data: CompleteMultipartUploadRequest,
    controller?: AbortController,
  ): Promise<void> => {
    return this.http.put(this.buildUrl(this.route.completeMultipartUpload, { model }), data, {
      signal: controller?.signal,
    });
  };

  share = (model: string): Promise<string> =>
    this.http.post(this.buildUrl(this.route.share, { model }));
}

export default MediaClient;
