import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { defaultNamespaces } from 'src/api/services/LocalizationClient';
import { locale } from './i18n.types';
import { LaravelFormatter } from './laravelFormatter';
import { setValidationMessages } from '../yup';

import { API_URL } from 'src/env.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(LaravelFormatter)
  .init({
    fallbackLng: [locale.en],
    debug: false,
    postProcess: [LaravelFormatter.name],

    react: {
      useSuspense: true,
    },

    ns: defaultNamespaces,
    defaultNS: 'pages/space',

    backend: {
      loadPath: `${API_URL}/localization/translations/{{lng}}/{{ns}}`,
      allowMultiLoading: false,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    lng: locale.en,

    missingKeyHandler: (languages, namespace, key) => {
      console.warn('Missing key translation key', {
        languages,
        namespace,
        key,
      });
    },
  })
  .then((t) => {
    const fieldsNamespaces = defaultNamespaces.filter((ns) => ns.startsWith('attributes'));

    setValidationMessages({
      mixed: {
        required: ({ path }) => {
          return t('required', {
            ns: 'validation',
            replace: {
              attribute: t(path, {
                ns: fieldsNamespaces,
              }),
            },
          });
        },
      },

      string: {
        email: t('email', {
          ns: 'validation',
          replace: { attribute: t('email', { ns: 'attributes/user' }) },
        })!,
        min: ({ min, path }) =>
          t('min.string', {
            ns: 'validation',
            replace: {
              attribute: t(path, { ns: fieldsNamespaces }),
              min,
            },
          })!,
        max: ({ max, path }) => {
          return t('max.string', {
            ns: 'validation',
            replace: {
              attribute: t(path, { ns: fieldsNamespaces }),
              max,
            },
          })!;
        },
      },
    });
  });

export default i18n;
