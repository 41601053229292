import React from 'react';
import { toast, ToastContent, Id, ToastOptions } from 'react-toastify';
import { Message, MessageProps } from './Message';
import { ToastContentProps } from 'react-toastify/dist/types';

const notify: <TData = unknown, TProps extends MessageProps = MessageProps>(
  content: ToastContent<TData>,
  options?: ToastOptions & {
    title?: string;
    component?:
      | React.ComponentType<TProps>
      | React.ComponentClass<TProps>
      | React.FunctionComponent<TProps>;
    componentProps?: Omit<TProps, keyof ToastContentProps<TData>>;
  },
) => Id = (content, options = {}) => {
  if (!options?.type) {
    options['type'] = 'success';
  }

  const toastId = options?.toastId ? options.toastId : JSON.stringify(content);

  const Component = options?.component ? options.component : Message;

  return toast(
    (props) => (
      <Component title={options?.title} {...(props as any)} {...(options?.componentProps ?? {})}>
        {content}
      </Component>
    ),
    { ...options, toastId },
  );
};

export { notify };
