import { Client } from 'src/api/Client';
import { ProviderType } from './SocialAuthClient.types';

class SocialAuthClient extends Client {
  public readonly route = {
    authenticate: `${this.http.baseUrl}/social/auth/{provider}`,
  };

  authenticate = (provider: ProviderType, data: any): Promise<[]> => {
    return this.http.post(this.buildUrl(this.route.authenticate, { provider }), data);
  };
}

export default SocialAuthClient;
