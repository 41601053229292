import { DynamicObject } from '../types';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

export { getObjectKeys } from './getObjectKeys';
export { default as compareStringsDesc } from './compareStringsDesc';

export * from './InputFields';
export * from './DateAndTime';
export * from './DomUtils';

export const passwordRegExp = RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
);

export const iterateRecursive = (
  iterable: DynamicObject<any> | string[] | string,
  callback: (entry: any) => void,
): void => {
  if (isObject(iterable)) {
    for (const key in iterable) {
      iterateRecursive((iterable as DynamicObject<any>)[key], callback);
    }
  } else if (isArray(iterable)) {
    (iterable as any[]).forEach((entry) => {
      iterateRecursive(entry, callback);
    });
  } else {
    callback(iterable);
  }
};

export const buildUrl = (url: string, params: { [key: string]: string }): string => {
  for (const key in params) {
    const value = params[key];
    url = url.replace('{' + key + '}', value);
  }

  return url;
};

export const forcePositiveNumber = (num: number): number => (num > 0 ? num : 0);

const arrFromInt = (int: number): number[] => Array.from(Array(int).keys());

export { arrFromInt };
