type Action = 'identify' | 'render' | 'initChangelog' | 'closeChangelog';

declare global {
  interface Window {
    Canny: (action: Action, options?: Record<string, any>) => void;
  }
}

const isLoaded = () => typeof window?.Canny === 'function';

type LoadOptions = {
  domain?: string;
  subdomain?: string;
};

const load = async (options?: LoadOptions) => {
  if (isLoaded()) {
    return window.Canny;
  }

  const { domain, subdomain } = options || {};

  const script = document.createElement('script');

  script.id = 'canny-sdk';
  script.type = 'text/javascript';
  script.async = true;

  const url = new URL('https://canny.io/sdk.js');

  if (subdomain) {
    url.hostname = `${subdomain}.canny.io`;
  }

  if (domain) {
    url.hostname = domain;
  }

  script.src = url.toString();

  return new Promise<typeof window.Canny>((resolve, reject) => {
    script.onload = () => {
      resolve(window.Canny);
    };

    script.onerror = (err) => {
      reject(err);
    };

    document.head.append(script);
  });
};

type CustomFields = {
  customFields?: Record<string, any>;
};

type IdentifyOptions = {
  appID: string;
  user: {
    id: string;
    name: string;
    email: string;

    avatarURL?: string;
    created?: string;

    companies?: ({
      id: string;
      name: string;
      monthlySpend?: number;
      created?: string;
    } & CustomFields)[];
  } & CustomFields;
};

const identify = (options: IdentifyOptions) => {
  window.Canny?.('identify', options);
};

type RenderOptions = {
  boardToken: string;
  basePath?: null | string;
  theme?: 'auto' | 'light' | 'dark';
  ssoToken?: string;
  onLoadCallback?: () => void;
};

const render = (options: RenderOptions) => {
  window.Canny?.('render', options);
};

type ChangeLogOptions = {
  appID: string;
  align: 'top' | 'bottom' | 'left' | 'right';
  position: 'top' | 'bottom' | 'left' | 'right';
  labelIDs?: string[];
};

const initChangelog = (options: ChangeLogOptions) => {
  window.Canny?.('initChangelog', options);
};

const closeChangelog = () => {
  window.Canny?.('closeChangelog');
};

const destroy = () => {
  window.Canny = undefined as any;
  document.getElementById('canny-sdk')?.remove();
};

export { load, isLoaded, identify, render, initChangelog, closeChangelog, destroy };
