import { createStore } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import {
  subscriptionBillingInterval,
  SubscriptionBillingIntervalType,
  SubscriptionPlanType,
  PaymentMethodType,
  paymentMethod,
  PromotionCodeResponse,
  SubscriptionFormValues,
} from 'src/lib/services/api/subscription-api';

type SubscriptionValues = Omit<SubscriptionFormValues, 'plan'> & {
  plan: SubscriptionPlanType | undefined;
};

type StepperStateValues = {
  step: number;
  completedStep: number;
};

type StepperState = StepperStateValues & {
  setStep: (step: number) => void;
  setCompletedStep: (completedStep: number) => void;
};

type PendingSubscriptionValues = {
  isPending: boolean;
  error?: string;
};

type PendingSubscriptionState = PendingSubscriptionValues & {
  setPending: (isPending: boolean) => void;
  setError: (error?: string) => void;
};

type SubscriptionStoreState = SubscriptionValues & {
  setPaymentMethod: (paymentMethod: PaymentMethodType) => void;
  setCoupon: (coupon: string) => void;
  setPlan: (plan: SubscriptionPlanType) => void;
  setBillingInterval: (billingInterval: SubscriptionBillingIntervalType) => void;
  setPromotionCode: (promotionCode: PromotionCodeResponse | undefined) => void;
  getFormValues: () => SubscriptionValues;
  reset: () => void;
};

type SubscriptionStoreValues = SubscriptionValues & StepperStateValues & PendingSubscriptionValues;

type SubscriptionStore = SubscriptionStoreState & StepperState & PendingSubscriptionState;

type SubscriptionStoreProps = SubscriptionFormValues;

const defaultValues = {
  step: 0,
  completedStep: -1,
  payment_method: paymentMethod.card,
  coupon: '',
  plan: undefined,
  billing_interval: subscriptionBillingInterval.quarterly,
  promotion_code: undefined,
  isPending: false,
  error: undefined,
};

const subscriptionStorageKey = 'becreatives-subscription-storage';

const createSubscriptionStore = (props?: Partial<SubscriptionStoreProps>) => {
  return createStore<SubscriptionStore>()(
    persist(
      (set, get) => ({
        ...defaultValues,
        ...props,

        setStep: (step: number) => set({ step }),
        setCompletedStep: (completedStep: number) => set({ completedStep }),
        setPaymentMethod: (paymentMethod) => set({ payment_method: paymentMethod }),
        setCoupon: (coupon) => set({ coupon }),
        setPlan: (plan) => set({ plan }),
        setBillingInterval: (billingInterval) => set({ billing_interval: billingInterval }),
        setPromotionCode: (promotionCode) => set({ promotion_code: promotionCode }),
        setPending: (isPending) => set({ isPending }),
        setError: (error) => set({ error }),

        getFormValues: () => ({
          payment_method: get().payment_method,
          coupon: get().coupon,
          plan: get().plan,
          billing_interval: get().billing_interval,
          promotion_code: get().promotion_code,
        }),

        reset: () => set({ ...defaultValues }),
      }),
      {
        name: subscriptionStorageKey,
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  );
};

export type { SubscriptionStore, SubscriptionStoreValues };
export { createSubscriptionStore, subscriptionStorageKey };
