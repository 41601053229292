import { useVideoPlayToggle } from 'src/features/video-player/video-player';
import { useVideoPlayerProgress } from 'src/features/video-player/use-video-player-progress';

const videoPlayerKeyboardShortcuts = {
  nextFrame: 'ArrowRight',
  previousFrame: 'ArrowLeft',
  togglePlay: 'Space',
} as const;

export const useVideoPlayerKeyboardEvents = () => {
  const { togglePlay } = useVideoPlayToggle();
  const { seekPreviousFrame, seekNextFrame } = useVideoPlayerProgress();

  const handleKeyDown = ({ target, code }: KeyboardEvent): void => {
    if (['INPUT', 'TEXTAREA'].includes((target as Element)?.tagName)) {
      return;
    }

    switch (code) {
      case videoPlayerKeyboardShortcuts.nextFrame:
        seekNextFrame();
        break;
      case videoPlayerKeyboardShortcuts.previousFrame:
        seekPreviousFrame();
        break;
      case videoPlayerKeyboardShortcuts.togglePlay:
        togglePlay();
        break;
      default:
        break;
    }
  };

  return {
    handleKeyDown,
  };
};
