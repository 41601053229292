import { PaginationObject } from 'src/components/Table/Table.types';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

const isPaginationObject = (arg: any): arg is PaginationObject => {
  return 'page' in arg || 'limit' in arg;
};

const isTableFiltersObject = (arg: any): arg is ApiQueryObject => {
  return (
    arg &&
    typeof arg === 'object' &&
    ('filters' in arg || 'page' in arg || 'limit' in arg || 'search' in arg || 'sorts' in arg)
  );
};

export { isPaginationObject, isTableFiltersObject };
