import { useEffect } from 'react';
import { Broadcast } from 'src/broadcast';
import { authQueries, useRequestsCountsQuery } from 'src/api/queries';
import { getObjectKeys } from 'src/helpers/getObjectKeys';
import { useQueryClient } from '@tanstack/react-query';
import { RequestStatus } from 'src/lib/services/api/request-api';

let hookInitialized = false;

const useRequestsCounts = (...params: Parameters<typeof useRequestsCountsQuery>) => {
  const client = useQueryClient();

  const { data: counts, ...query } = useRequestsCountsQuery(...params);

  const totalDrafts = counts?.draft.total.length ?? 0,
    newDrafts = counts?.draft.new.length ?? 0,
    totalQueued = counts?.queued.total.length ?? 0,
    newQueued = counts?.queued.new.length ?? 0,
    totalEditing = counts?.currently_editing.total.length ?? 0,
    newEditing = counts?.currently_editing.new.length ?? 0,
    totalDelivered = counts?.delivered.total.length ?? 0,
    newDelivered = counts?.delivered.new.length ?? 0,
    totalComplete = counts?.complete.total.length ?? 0,
    newComplete = counts?.complete.new.length ?? 0,
    totalNew = newDrafts + newQueued + newEditing + newDelivered + newComplete;

  const add = (id: string, isDraft = false) => {
    query.setQueryData((prev) => {
      if (!prev) {
        return prev;
      }

      if (isDraft) {
        prev.draft.total.push(id);
      } else {
        prev.queued.total.push(id);
      }

      return prev;
    });
  };

  const update = (id: string, status: RequestStatus) => {
    query.setQueryData((prev) => {
      if (!prev) {
        return prev;
      }

      const foundInCounts = prev[status].total.find((r) => r === id);
      if (!foundInCounts) {
        remove(id);
        prev[status].total.push(id);
      }

      return prev;
    });
  };

  const remove = (id: string) => {
    query.setQueryData((prev) => {
      if (!prev) {
        return prev;
      }

      const statuses = getObjectKeys(prev);
      for (let i = 0; i < statuses.length; i++) {
        const status = statuses[i];
        const found = prev[status].total.find((r) => r === id);

        if (found) {
          prev[status].total = prev[status].total.filter((r) => r !== found);
          prev[status].new = prev[status].new.filter((r) => r !== found);

          break;
        }
      }

      return prev;
    });
  };

  const review = (id: string, status?: RequestStatus) => {
    query.setQueryData((prev) => {
      if (!prev) {
        return prev;
      }

      if (status) {
        prev[status].new = prev[status].new.filter((r) => r !== id);

        return prev;
      }

      const statuses = getObjectKeys(prev);
      for (let i = 0; i < statuses.length; i++) {
        const key = statuses[i];
        const oldCount = prev[key].new.length;
        if (!oldCount) {
          continue;
        }

        prev[key].new = prev[key].new.filter((r) => r !== id);
        const newCount = prev[key].new.length;
        if (oldCount > newCount) {
          break;
        }
      }

      return prev;
    });
  };

  useEffect(() => {
    if (hookInitialized) {
      return;
    }

    Broadcast.user.requestUpdatedByEditor.setListener(({ id }) => {
      client.invalidateQueries({ queryKey: [authQueries.request, id] });
      client.invalidateQueries({ queryKey: [authQueries.requestOutcomes, id] });
      client.invalidateQueries({ queryKey: [authQueries.profile] });
      query.refetch();
    });

    hookInitialized = true;
  }, []);

  return {
    counts,
    query,
    totalDrafts,
    newDrafts,
    totalQueued,
    newQueued,
    totalEditing,
    newEditing,
    totalDelivered,
    newDelivered,
    totalComplete,
    newComplete,
    totalNew,
    review,
    add,
    update,
    remove,
  };
};

export { useRequestsCounts };
