import { ArrayParam, JsonParam, NumberParam, StringParam } from 'use-query-params';
import { ApiQueryObject } from './api-query-params.types';

export const makeApiQueryParamsConfig = () => ({
  page: NumberParam,
  limit: NumberParam,
  search: StringParam,
  sorts: JsonParam,
  filters: JsonParam,
  include: ArrayParam,
});

export type ApiQueryParamsConfig = ReturnType<typeof makeApiQueryParamsConfig>;

const resolveQueryParams = (id?: string) => {
  return id
    ? {
        [id]: JsonParam,
      }
    : makeApiQueryParamsConfig();
};

const createQueryParams = <Params extends ApiQueryObject>(params: Params): Params => ({
  ...params,
});

const mergeQueryParams = <Params extends ApiQueryObject, Values extends ApiQueryObject>(
  first: Params,
  values: Values,
): Params & Values => {
  const params = { ...first };
  if (values.search) {
    params.search = values.search;
  }

  if (values.page) {
    params.page = values.page;
  }

  if (values.limit) {
    params.limit = values.limit;
  }

  if (values.sorts?.length) {
    params.sorts = (() => {
      const ids = values.sorts.map((sort) => sort.id);
      const updatedSorts = params.sorts?.filter((sort) => !ids.includes(sort.id)) || [];
      return [...updatedSorts, ...values.sorts];
    })();
  }

  if (values.filters?.length) {
    params.filters = (() => {
      const ids = values.filters.map((filter) => filter.id);
      const updatedFilters = params.filters?.filter((filter) => !ids.includes(filter.id)) || [];
      return [...updatedFilters, ...values.filters];
    })();
  }

  return params as Params & Values;
};

export { resolveQueryParams, createQueryParams, mergeQueryParams };
