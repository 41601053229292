import { useState } from 'react';

/**
 * use import from 'src/lib/state-utils/use-toggle' instead
 * @deprecated
 * @param initialValue
 */
const useToggle = (initialValue?: boolean) => {
  const [value, setValue] = useState(!!initialValue);

  const toggle = () => setValue((prev) => !prev);

  return [value, setValue, toggle] as const;
};

export { useToggle };
