import { useMakeQuery } from '../useMakeQuery';
import { authQueries, UseQueryOptionsObject } from '../queries';
import { defaultOptions } from '../queries.utils';
import { requestClient, RequestResponse } from 'src/lib/services/api/request-api';

const useRequestQuery = (requestId: string, options?: UseQueryOptionsObject<RequestResponse>) => {
  return useMakeQuery<
    RequestResponse,
    Partial<Pick<RequestResponse, 'status' | 'reviewed' | 'approved_at' | 'requested_reedit_at'>>
  >([authQueries.request, requestId], () => requestClient.get(requestId), {
    staleTime: 60 * 1000,
    ...defaultOptions({
      auth: true,
      ...(options ?? {}),
    }),
  });
};

export { useRequestQuery };
