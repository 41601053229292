import React from 'react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { SpaceErrorFallback } from 'src/features/fallback';
import { Skeleton } from 'src/components/ui/skeleton';
import { RequestOutcomesQueryContextProvider } from 'src/features/requests/request-outcome/use-request-outcomes-query-context';
import { RequestPendingOutcomesList } from 'src/features/requests/request-page/multi-outcome';
import { RequestArchivedOutcomesList } from 'src/features/requests/request-page/multi-outcome/pending-outcomes/request-archived-outcomes-list';
import { If } from 'src/components/If';
import { RequestArchivedOutcomesContainer } from 'src/features/requests/request-page/multi-outcome/pending-outcomes/request-archived-outcomes-container';
import {
  archivedOutcomesFilters,
  pendingOutcomesFilters,
} from 'src/features/requests/request-outcome/filter-queries';
import { NoRecordsFallback } from 'src/features/fallback/ui/no-records-fallback';

const RequestPendingOutcomesContainer: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { requestId: string }
> = ({ className, requestId, ...props }) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }: any) => (
            <SpaceErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
          )}
          onReset={reset}
        >
          <React.Suspense fallback={<Skeleton className={'tw-h-full tw-w-full'} />}>
            <RequestOutcomesQueryContextProvider id={requestId} filters={pendingOutcomesFilters}>
              {({ data: pendingOutcomes }) => (
                <If when={!!pendingOutcomes?.items.length} else={<NoRecordsFallback />}>
                  <RequestPendingOutcomesList className={className} {...props} />
                </If>
              )}
            </RequestOutcomesQueryContextProvider>
          </React.Suspense>

          <React.Suspense>
            <RequestOutcomesQueryContextProvider id={requestId} filters={archivedOutcomesFilters}>
              {({ data }) => (
                <If when={!!data?.items.length}>
                  <RequestArchivedOutcomesContainer>
                    <RequestArchivedOutcomesList className={className} {...props} />
                  </RequestArchivedOutcomesContainer>
                </If>
              )}
            </RequestOutcomesQueryContextProvider>
          </React.Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export { RequestPendingOutcomesContainer };
