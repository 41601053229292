import { ValueOfObject } from './generics';

const cookieKey = {
  setCompanyInformationReminder: 'set_company_information',
  subscribeReminder: 'subscribe_reminder',
} as const;

type CookieKey = ValueOfObject<typeof cookieKey>;

interface ErrorObject<T extends Record<string, string[]> = Record<string, string[]>> {
  code: number;
  message: string;
  errors?: {
    form: T;
  };
}

export type { CookieKey, ErrorObject };
export { cookieKey };
