import { useCookies } from 'react-cookie';
import { CookieKey } from 'src/types/global';

const useUnsetVisitedPageCookie = () => {
  const [, , removeCookie] = useCookies();

  return (key: CookieKey) => {
    removeCookie(key);
  };
};

export { useUnsetVisitedPageCookie };
