import { FilterParam } from 'src/lib/services/api-query-params/api-query-params.types';
import { useApiQueryParams } from 'src/lib/services/api-query-params/use-api-query-params';
import xor from 'lodash/xor';
import isEmpty from 'lodash/isEmpty';
import getObjectEntries from 'src/helpers/getObjectEntries';

type FilterGroupItemFilter = {
  label: string;
  updater: FilterUpdater;
  filter: FilterParam;
};

interface FilterGroup {
  label: string;
}

interface FilterGroupMultipleFilters extends FilterGroup {
  filters: Array<FilterGroupItemFilter>;
  filter?: never;
}

interface FilterGroupSingleFilter extends FilterGroup {
  filters?: never;
  filter: FilterGroupItemFilter;
}

type FilterGroups = Record<string, FilterGroupMultipleFilters | FilterGroupSingleFilter>;

type FilterUpdater = (filter: FilterParam, checked: boolean) => void;

const useFilters = () => {
  const { filters, getFilter, setFilter, setFilters } = useApiQueryParams();

  const isFilterChecked = (filter: FilterParam) => {
    // If value is defined, check if value is present in the filter.
    // If value is not defined, the filter may not have a value set, so we check if the filter exists.
    const foundFilter = filters.filters?.find((f) => f.id === filter.id);

    if (!foundFilter) {
      return false;
    }

    if (foundFilter.operator !== filter.operator) {
      return false;
    }

    if (Array.isArray(foundFilter.value)) {
      if (Array.isArray(filter.value)) {
        // check that array values are the same, regardless of order
        return isEmpty(xor(foundFilter.value, filter.value));
      } else {
        // check that the value is in the array
        return foundFilter.value.includes(filter.value);
      }
    }

    return foundFilter.value === filter.value;
  };

  // Used to concat or remove filter array values, instead of simply replacing value.
  const toggleArrayFilter: FilterUpdater = (filter, checked) => {
    const filterValue = getFilter(filter.id) ?? [];

    if (checked) {
      setFilter({ ...filter, value: [...filterValue, filter.value] });
      return;
    }

    setFilters((queryParams) => {
      if (!queryParams.filters) {
        return queryParams;
      }

      const filters: FilterParam<string[]>[] = [...queryParams.filters];

      const index = filters.findIndex((f) => f.id === filter.id);

      if (index === -1 || !filters[index].value) {
        return queryParams;
      }

      // If it is the last filter array value, we can remove whole filter.
      // Otherwise, remove the value from the array.
      if (Array.isArray(filters[index].value) && filters[index].value!.length === 1) {
        filters.splice(index, 1);
      } else {
        filters[index].value!.splice(filters[index].value!.indexOf(filter.value), 1);
      }

      return {
        ...queryParams,
        filters: filters.length ? filters : undefined,
      };
    });
  };

  const toggleSingleFilter: FilterUpdater = (filter, checked) => {
    if (checked) {
      setFilter(filter);
      return;
    }

    setFilters((queryParams) => {
      if (!queryParams.filters) {
        return queryParams;
      }

      const filters: FilterParam<string>[] = [...queryParams.filters];

      const index = filters.findIndex((f) => f.id === filter.id);

      if (index === -1) {
        return queryParams;
      }

      filters.splice(index, 1);

      return {
        ...queryParams,
        filters: filters.length ? filters : undefined,
      };
    });
  };

  const clearFilters = () => {
    setFilters((prev) => {
      return { ...prev, filters: undefined };
    });
  };

  const getFilterCounts = (filterGroups: FilterGroups): number[] =>
    getObjectEntries(filterGroups).map(([, group]) => {
      return group.filter
        ? Number(isFilterChecked(group.filter.filter))
        : group.filters.reduce((acc, { filter }) => {
            if (isFilterChecked(filter)) {
              acc += 1;
            }

            return acc;
          }, 0);
    });

  return {
    filters: filters.filters,
    isFilterChecked,
    toggleArrayFilter,
    toggleSingleFilter,
    clearFilters,
    getFilterCounts,
  };
};

export { useFilters, type FilterUpdater, type FilterGroups };
