import { useLocation, useNavigate } from 'react-router-dom';
import { appRoutes } from 'src/routes/routes.types';
import { useVerifyEmailRedirectMiddleware } from './use-verify-email-redirect-middleware';
import { useOnboardedRedirectMiddleware } from 'src/routes/RedirectMiddleware/use-onboarded-redirect-middleware';
import { useCompanyInfoSetRedirectMiddleware } from 'src/routes/RedirectMiddleware/use-company-info-set-redirect-middleware';
import { usePreLoginAuthLocation } from 'src/routes/use-pre-login-auth-location';

const useRedirectMiddleware = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getLocation } = usePreLoginAuthLocation();
  // Disabling redirects
  // const verifyEmailRedirect = useVerifyEmailRedirectMiddleware();
  // const onboardRedirect = useOnboardedRedirectMiddleware();
  // const setCompanyInfoRedirect = useCompanyInfoSetRedirectMiddleware();

  return async (fallback?: string | false) => {
    const fns = [
      // verifyEmailRedirect,
      // onboardRedirect,
      // setCompanyInfoRedirect,
      async () => {
        // Navigate to intended location or dashboard
        navigate(getLocation(location.state?.from) ?? appRoutes.dashboard, {
          replace: true,
        });

        return true;
      },
    ];

    for (let i = 0; i < fns.length; i++) {
      if (await fns[i]()) {
        break;
      }
    }
  };
};

export { useRedirectMiddleware };
