import React from 'react';
import { useReactTable, getCoreRowModel, flexRender, Row, Column } from '@tanstack/react-table';
import { TableProps } from 'src/components/Table/Table.types';
import { useTableStore } from 'src/components/Table/useTableStore';
import { cn, render } from 'src/lib/utils';
import { Table, TableBody, TableCell, TableRow } from 'src/components/ui/table';
import { GripHorizontal, Loader2 } from 'lucide-react';
import { TableNavigator } from 'src/components/Table/table-navigator';
import {
  DragDropContext,
  Draggable,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';
import {
  ComposedTableBodyEmpty,
  ComposedTableBottom,
  ComposedTableHeader,
  ComposedTableTop,
  ComposedTableWrapper,
  TableBodySkeleton,
} from 'src/components/Table/composed-table';
import { requestClient } from 'src/lib/services/api/request-api';
import { TableColumnsVisibilityToggle } from 'src/components/Table/table-columns-visibility-toggle';
import { useBreakpoint } from 'src/lib/hooks';
import { useMutation } from '@tanstack/react-query';

const ComposedRequestsTable: React.FC<
  React.PropsWithChildren<
    Omit<TableProps, 'enableColumnsHide'> & {
      currentTab?: string;
    }
  >
> = ({
  columns,
  getRowCanExpand = () => false,
  expandedRowComponent,
  currentTab,
  children,
  ...props
}) => {
  const store = useTableStore();

  const reorderMutation = useMutation({
    mutationFn: ([sourceItemIndex, targetItemIndex, sourceRequestId, targetRequestId]: [
      number,
      number,
      string,
      string,
    ]) => {
      // mutationFn runs after onMutate, so we pass request ids gotten from original list via arguments
      return requestClient.sort(sourceRequestId, {
        request_id: targetRequestId,
        position: sourceItemIndex > targetItemIndex ? 'before' : 'after',
      });
    },
    onMutate: ([sourceIndex, targetIndex]) => {
      store.reorder(sourceIndex, targetIndex);
    },
    onError: (error, [sourceIndex, targetIndex]) => {
      store.reorder(targetIndex, sourceIndex);
    },
    onSettled: () => {
      store.refetch();
    },
  });

  /**
   * As long as the requests table tab is not "all", we are able to perform an optimistic update,
   * and we don't need wait for the refetch.
   * When showing "all" requests, because the requests are "grouped" (sorted) by status first,
   * we have to do a refetch to show correct order of requests.
   */
  const showReorderMutationLoader = currentTab === 'all' && reorderMutation.isLoading;

  const table = useReactTable({
    data: store.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: store.totalPages,
    manualPagination: true,
    state: {
      pagination: store.pagination,
      sorting: store.sorting,
      columnVisibility: store.columnVisibility,
      expanded: store.expanded,
    },
    onColumnVisibilityChange: store.setColumnVisibility,
    onExpandedChange: store.setExpanded,
    onPaginationChange: store.setPagination,
    onSortingChange: store.setSorting,
    manualSorting: true,
    manualExpanding: true,
    paginateExpandedRows: false,
    getRowCanExpand,
  });

  async function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    // dropped outside the list or canceled drag
    if (!destination || result.reason === 'CANCEL') {
      return;
    }

    if (destination.index !== source.index) {
      reorderMutation.mutate([
        source.index,
        destination.index,
        store.data[source.index].id,
        store.data[destination.index].id,
      ]);
    }
  }

  return (
    <ComposedTableWrapper>
      <ComposedTableTop className={'tw-flex-nowrap tw-p-0 sm:tw-gap-4 md:tw-pb-4'}>
        {children}

        <RequestsTableColumnToggle columns={table.getAllLeafColumns()} />
      </ComposedTableTop>

      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeCapture={() => {
          // collapse all expanded rows
          store.setExpanded({});
        }}
      >
        <Table
          wrapperProps={{
            className: 'tw-rounded-b-none',
          }}
          className={'tw-relative'}
        >
          <ComposedTableHeader table={table} />

          <Droppable droppableId={'requests-droppable'}>
            {(provided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {!store.isLoading && !table.getRowModel().rows.length ? (
                  <ComposedTableBodyEmpty />
                ) : null}
                {store.isLoading ? (
                  <TableBodySkeleton columnCount={table.getVisibleLeafColumns().length} />
                ) : (
                  <>
                    <ComposedRequestsTableRows
                      rows={table.getRowModel().rows}
                      tableRowProps={props.tableRowProps}
                      expandedRowComponent={expandedRowComponent}
                    />
                    {showReorderMutationLoader && (
                      <tr
                        className={
                          'tw-absolute tw-inset-0 tw-z-10 tw-animate-pulse tw-bg-neutral-200/70'
                        }
                      >
                        <td colSpan={99} className={'tw-block tw-h-full tw-w-full !tw-border-0'}>
                          <div
                            className={
                              'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center'
                            }
                          >
                            <Loader2 className={'tw-size-12 tw-animate-spin tw-text-brand'} />
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>

      <ComposedTableBottom>
        <TableNavigator
          totalCount={store.totalItems}
          pageSize={table.getState().pagination.pageSize}
          currentPage={table.getState().pagination.pageIndex + 1}
          onPageChange={(page) => {
            table.setPageIndex(page);
          }}
        />
      </ComposedTableBottom>
    </ComposedTableWrapper>
  );
};

const ComposedRequestsTableRows: React.FC<{
  rows: Row<any>[];
  tableRowProps: TableProps['tableRowProps'];
  expandedRowComponent: TableProps['expandedRowComponent'];
}> = React.memo(({ rows, tableRowProps, expandedRowComponent }) => {
  const store = useTableStore();
  const { isMobile } = useBreakpoint();

  const isDragDisabled = !!store.sorting.length;

  function getDraggableStyle(
    style: React.CSSProperties | undefined,
    snapshot: DraggableStateSnapshot,
  ) {
    if (snapshot.isDropAnimating && snapshot.dropAnimation) {
      // adjust translate position to account for table row spacing
      const { moveTo } = snapshot.dropAnimation;
      // minus table border-spacing-3 (0.75rem = 12px) times 2
      const translate = `translate(${moveTo.x}px, ${moveTo.y - 24}px)`;

      return {
        ...style,
        display: 'table', // needed to support draggable table row
        transform: translate,
      };
    }

    if (snapshot.isDragging) {
      return {
        ...style,
        display: 'table',
      };
    }

    return style;
  }

  return (
    <>
      {rows.map((row) => {
        const { className, active, ...rowProps } = tableRowProps?.(row) ?? {};
        const canExpand = row.getCanExpand();
        const isExpanded = row.getIsExpanded();

        return (
          <React.Fragment key={row.id}>
            <Draggable draggableId={row.id} index={row.index} isDragDisabled={isDragDisabled}>
              {(provided, snapshot) => (
                <TableRow
                  active={canExpand && isExpanded ? true : active}
                  className={cn('tw-group/row', canExpand && 'tw-peer', className)}
                  ref={provided.innerRef}
                  {...rowProps}
                  {...provided.draggableProps}
                  style={getDraggableStyle(provided.draggableProps.style, snapshot)}
                >
                  {row.getVisibleCells().map((cell, i) => {
                    const cellContext = cell.getContext();
                    const isLastColumn = row.getVisibleCells().length - 1 === i;
                    const visibleColumnCount = row.getVisibleCells().length;
                    // on mobile, whole table cell is draggable. Render a separate drag handle component on bigger screens
                    const cellProps = isMobile ? { ...provided.dragHandleProps } : {};

                    return (
                      <TableCell
                        key={cell.id}
                        className={cn('tw-relative', {
                          'tw-text-end': isLastColumn && visibleColumnCount > 1,
                        })}
                        {...cellProps}
                      >
                        {!isMobile && i === 0 && (
                          <div
                            className={cn(
                              'tw-absolute tw-left-0.5 tw-top-1/2 tw-hidden -tw-translate-y-1/2 tw-cursor-grab group-hover/row:tw-block',
                              snapshot.isDragging && 'tw-block',
                              isDragDisabled && '!tw-hidden',
                            )}
                            {...provided.dragHandleProps}
                          >
                            <GripHorizontal
                              size={16}
                              className={'tw-rotate-90 tw-text-neutral-400'}
                            />
                          </div>
                        )}
                        {flexRender(cell.column.columnDef.cell, {
                          ...cellContext,
                          helpers: {
                            refetch: store.refetch,
                            setData: store.setData,
                            pagination: store.pagination,
                            setPagination: store.setPagination,
                          },
                          row: {
                            ...cellContext.row,
                            edit: store.getEditRow(cellContext.row.index),
                          },
                          rows: store.data,
                        })}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )}
            </Draggable>
            {canExpand && isExpanded ? render(expandedRowComponent, row) : null}
          </React.Fragment>
        );
      })}
    </>
  );
});
ComposedRequestsTableRows.displayName = 'ComposedRequestsTableRows';

const RequestsTableColumnToggle: React.FC<{ columns: Column<any, any>[] }> = ({ columns }) => {
  const { isMobile } = useBreakpoint();

  const toggleableColumns = React.useMemo(() => {
    // do not allow to hide first and last table columns
    const toggleableColumns = [...columns];
    toggleableColumns.shift();
    toggleableColumns.pop();

    return toggleableColumns;
  }, [columns]);

  // Only show first column for mobile
  React.useLayoutEffect(() => {
    if (isMobile) {
      columns[0].toggleVisibility(true);
      columns.slice(1).forEach((col) => col.toggleVisibility(false));
    } else {
      columns.forEach((col) => col.toggleVisibility(true));
    }
  }, [isMobile]);

  if (!toggleableColumns.length) {
    return null;
  }

  return (
    <TableColumnsVisibilityToggle
      columns={toggleableColumns as any}
      menuTriggerProps={{
        className: 'tw-ms-auto tw-hidden md:tw-inline-flex',
      }}
      getIsAllColumnsVisible={() => {
        return toggleableColumns.every((col) => col.getIsVisible());
      }}
      getIsSomeColumnsVisible={() => toggleableColumns.some((col) => col.getIsVisible())}
      getToggleAllColumnsVisibilityHandler={() => (checked) => {
        toggleableColumns.forEach((col) => col.toggleVisibility(checked as boolean));
      }}
    />
  );
};

export { ComposedRequestsTable };
