import React, { useLayoutEffect } from 'react';
import {
  FileCardsGrid,
  RequestDetailsCard,
  RequestDetailsCardContent,
  RequestExtrasCard,
  RequestExtrasCardContent,
  RequestInformationCard,
  RequestInformationCardContent,
  RequestMediaSourceCard,
  RequestMediaSourceCardContent,
  RequestPageContainer,
  RequestPageContentMain,
} from 'src/pages/request/request-page/request-default-page';
import { cn } from 'src/lib/utils';
import { Skeleton } from 'src/components/ui/skeleton';
import { useTranslation } from 'react-i18next';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { If } from 'src/components/If';
import { useRequestId } from 'src/features/requests/use-request-id';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { RequestDeliverableOutcomesGrid } from 'src/features/requests/request-page/multi-outcome';
import { RequestPendingOutcomesContainer } from 'src/features/requests/request-page/multi-outcome';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { useBreakpoint } from 'src/lib/hooks';
import { Card, CardContent, CardHeader } from 'src/components/ui/card';

const RequestMultiOutcomeSkeleton: React.FC<React.ComponentProps<typeof Skeleton>> = ({
  className,
  ...props
}) => {
  return (
    <RequestPageContainer {...props} className={cn('tw-col-span-12', className)}>
      <Skeleton className="tw-h-10 tw-w-1/3 tw-rounded-md" />
      <RequestPageContentMain className={'tw-grid-cols-12'}>
        <Skeleton className="tw-col-span-12 tw-h-full md:tw-col-span-8 lg:tw-col-span-9" />
        <Skeleton className="tw-col-span-12 tw-h-full md:tw-col-span-4 lg:tw-col-span-3" />
      </RequestPageContentMain>
    </RequestPageContainer>
  );
};

const MainContentTabsList: React.FC<
  React.ComponentProps<typeof TabsList> & {
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
    activeTab: string;
  }
> = ({ setActiveTab, activeTab, ...props }) => {
  const { t } = useTranslation();
  const { activeBreakpoint, upTo } = useBreakpoint();

  useLayoutEffect(() => {
    if (activeTab !== 'pending_outcomes' || upTo('laptop')) {
      return;
    }

    setActiveTab('deliverables');
  }, [activeTab, activeBreakpoint]);

  return (
    <TabsList className={'tw-h-auto'} {...props}>
      <TabsTrigger className={'tw-rounded-lg tw-px-4 tw-py-3'} value={'deliverables'}>
        {t('common:deliverables', {
          defaultValue: 'Deliverables',
        })}
      </TabsTrigger>

      <TabsTrigger
        className={'tw-rounded-lg tw-px-4 tw-py-3 laptop:tw-hidden'}
        value={'pending_outcomes'}
      >
        {t('common:pending_outcomes', {
          defaultValue: 'Pending outcomes',
        })}
      </TabsTrigger>

      <TabsTrigger className={'tw-rounded-lg tw-px-4 tw-py-3'} value={'summary'}>
        {t('common:summary', {
          defaultValue: 'Summary',
        })}
      </TabsTrigger>
    </TabsList>
  );
};

const RequestMultiOutcomePageMainContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => {
  const requestId = useRequestId();
  const [activeTab, setActiveTab] = useQueryParam<string>(
    'tab',
    withDefault(StringParam, 'deliverables'),
    { updateType: 'replaceIn' },
  );
  const request = useDecoratedRequestContext();
  const { t } = useTranslation();

  return (
    <div ref={ref} {...props}>
      <Tabs
        onValueChange={(value) => setActiveTab(value)}
        defaultValue={activeTab}
        value={activeTab}
        className={'tw-flex tw-h-full tw-flex-col tw-gap-2'}
      >
        <MainContentTabsList
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          className={
            'tw-justify-start tw-gap-2 tw-overflow-x-auto tw-overflow-y-clip tw-border-0 tw-bg-transparent'
          }
        />

        <TabsContent className={'tw-relative tw-mt-0 tw-grow'} value={'summary'}>
          <div className={'tw-absolute tw-inset-0 tw-flex tw-flex-col tw-gap-4'}>
            <ScrollArea>
              <div className={'tw-flex tw-flex-col tw-gap-4 tw-px-1 tw-pb-1'}>
                <RequestInformationCard>
                  <RequestInformationCardContent />
                </RequestInformationCard>

                <RequestExtrasCard>
                  <RequestExtrasCardContent />
                </RequestExtrasCard>

                <RequestMediaSourceCard>
                  <RequestMediaSourceCardContent>
                    <If when={request.hasMedia} else={t('common:unset', { defaultValue: '-' })}>
                      <FileCardsGrid
                        className={
                          'tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 desktop:tw-grid-cols-4'
                        }
                        files={request.media}
                      />
                    </If>
                  </RequestMediaSourceCardContent>
                </RequestMediaSourceCard>

                <RequestDetailsCard>
                  <RequestDetailsCardContent />
                </RequestDetailsCard>
              </div>
            </ScrollArea>
          </div>
        </TabsContent>

        <TabsContent className={'tw-relative tw-mt-0 tw-grow'} value={'deliverables'}>
          <div
            className={
              'tw-absolute tw-inset-0 tw-flex tw-flex-col tw-gap-4 tw-overflow-auto tw-rounded-lg tw-border tw-bg-white tw-p-4 md:tw-p-4'
            }
          >
            <RequestDeliverableOutcomesGrid requestId={requestId} />
          </div>
        </TabsContent>

        <TabsContent
          className={'tw-relative tw-mt-0 tw-grow laptop:tw-hidden'}
          value={'pending_outcomes'}
        >
          <div
            className={
              'tw-absolute tw-inset-0 tw-flex tw-flex-col tw-gap-2 tw-overflow-auto tw-rounded-lg tw-bg-white md:tw-gap-4'
            }
          >
            <ScrollArea viewportProps={{ className: '[&>div]:!tw-block' }}>
              <RequestPendingOutcomesContainer
                requestId={requestId}
                className={'tw-flex tw-grow tw-flex-col'}
              />
            </ScrollArea>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
});
RequestMultiOutcomePageMainContent.displayName = 'RequestDefaultPageMainContent';

const RequestMultiOutcomePageSideContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
  const { t } = useTranslation();
  const requestId = useRequestId();

  return (
    <div ref={ref} className={cn('tw-flex tw-grow tw-flex-col tw-gap-2', className)} {...props}>
      <div className={'tw-relative tw-h-full tw-w-full'}>
        <Card
          className={'tw-flex tw-h-full tw-w-full tw-flex-col tw-bg-background'}
          variant={'noShadow'}
        >
          <CardHeader>
            <div className={'tw-flex tw-items-center tw-justify-between'}>
              <div className={'tw-text-title-sm'}>
                {t('pages/shared:request.pending_outcomes', 'Pending outcomes')}
              </div>
            </div>
          </CardHeader>
          <CardContent className={'tw-relative tw-h-full tw-w-full'}>
            <div className={'tw-absolute tw-inset-0 tw-flex tw-flex-col'}>
              <ScrollArea viewportProps={{ className: '[&>div]:!tw-block' }}>
                <RequestPendingOutcomesContainer
                  requestId={requestId}
                  className={'tw-hidden tw-grow-0 tw-flex-col tw-px-6 tw-py-0 lg:tw-flex'}
                />
              </ScrollArea>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
});
RequestMultiOutcomePageSideContent.displayName = 'RequestMultiOutcomePageMainContent';

export {
  RequestMultiOutcomeSkeleton,
  RequestMultiOutcomePageMainContent,
  RequestMultiOutcomePageSideContent,
};
