import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FormMediaObject, UploadInputProps } from '../S3UploadInput.types';
import { Text, customColors, NavLink, PopoverProps } from '@becreatives/becreatives-ui';
import {
  FileUploadIcon,
  FileUploadPopover,
  FileUploadPopoverBody,
} from '@becreatives/becreatives-ui';
import { resolveValidationRule } from '../../Form.validator';
import { useField } from 'formik';
import { useGenerateMultipartUpload } from '../useGenerateMultipartUpload';
import { HttpError } from 'src/api/Http/HttpError';

type Props = UploadInputProps & {
  popoverProps: PopoverProps;
};

const DropzonePopoverInput: React.FC<Props> = ({
  name,
  model,
  collection_name,
  minSize,
  maxSize,
  maxItems,
  popoverProps,
  children,
  ...props
}) => {
  const { generateUpload } = useGenerateMultipartUpload();

  const [{ value: uploadedFiles }, , { setError, setTouched }] = useField<FormMediaObject[]>(name);

  const validate = (files: FileList): void => {
    const errors: any[] = [];

    resolveValidationRule(maxItems, (limit) => `Can't upload more than ${limit} files.`).validate(
      (limit, error) => {
        if (files.length + uploadedFiles.length > limit) {
          errors.push(error);
        }
      },
    );

    resolveValidationRule(
      maxSize,
      (size) => `Can't upload larger than ${size} bytes files.`,
    ).validate((size, error) => {
      if (Array.from(files).find((file) => file.size > size)) {
        errors.push(error);
      }
    });

    resolveValidationRule(
      minSize,
      (size) => `Can't upload smaller than ${size} bytes files.`,
    ).validate((size, error) => {
      if (Array.from(files).find((file) => file.size < size)) {
        errors.push(error);
      }
    });

    if (errors.length) {
      throw new Error(errors.join('\n'));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (files) => {
      validate(files as any);

      if (popoverProps?.isOpen && popoverProps?.toggle) {
        (popoverProps as any).toggle();
      }

      await generateUpload(model, files, collection_name)
        .catch((e: HttpError) => setError(e.message))
        .finally(() => setTouched(true));
    },
  });

  return (
    <>
      {children}
      <FileUploadPopover {...popoverProps}>
        <FileUploadPopoverBody {...getRootProps()}>
          <input {...getInputProps()} accept={props.accept} />

          <div className={'d-flex flex-column align-items-center mb-md-4'}>
            <FileUploadIcon className={'mb-2'} color={'secondary'} />
            <Text color={customColors.doveGray}>Upload a file</Text>
          </div>

          <Text color={customColors.silverChalice} className={'text-center'}>
            Click to{' '}
            <NavLink
              active={true}
              color={'secondary'}
              className={'d-inline text-decoration-underline'}
            >
              browse
            </NavLink>{' '}
            or drag & drop the file here
          </Text>
        </FileUploadPopoverBody>
      </FileUploadPopover>
    </>
  );
};

export { DropzonePopoverInput };
