import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { cn } from 'src/lib/utils';
import { Star } from 'lucide-react';
import { Dialog, DialogClose, DialogComposedClose } from 'src/components/ui/dialog';
import { Label } from 'src/components/ui/label';
import { useTranslation } from 'react-i18next';
import { revisionClient } from 'src/lib/services/api/revision-api';
import { useToast } from 'src/components/ui/use-toast';

export const rateRevisionDialogPortalTarget = 'rate-revision-dialog-portal';

const RateRevisionComposedDialogPortal: React.FC<
  React.ComponentProps<typeof DialogPrimitive.Portal>
> = (props) => {
  return (
    <DialogPrimitive.Portal
      {...props}
      container={document.getElementById(rateRevisionDialogPortalTarget)}
    />
  );
};

const RateRevisionComposedDialogContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn(
      'tw-absolute tw-inset-0 tw-z-[1001] tw-flex tw-flex-col tw-bg-white/80',
      className,
    )}
    {...props}
    ref={ref}
  />
));
RateRevisionComposedDialogContent.displayName = 'RateRevisionComposedDialogContent';

const maxRating = 5;

const RateRevisionComposedDialog: React.FC<
  React.ComponentProps<typeof Dialog> & {
    className?: string;
    revision: string;
    onSuccess?: (rating: number) => void;
  }
> = ({ children, modal = false, className, revision, onSuccess, ...props }) => {
  const { t } = useTranslation();

  const { toast } = useToast();

  return (
    <Dialog {...props} modal={modal}>
      {children}

      <RateRevisionComposedDialogPortal>
        <RateRevisionComposedDialogContent className={className}>
          <div className={'tw-flex tw-grow tw-items-center tw-justify-center'}>
            <div className={'tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4'}>
              <Label className={'tw-text-title tw-font-semibold sm:tw-text-heading-md'}>
                {t('forms.rate_revision.field.rating.label', {
                  defaultValue: 'Rate your first video edit!',
                })}
              </Label>
              <div className={'tw-flex tw-flex-row-reverse tw-gap-2'}>
                {Array.from({ length: maxRating }, (_, i) => (
                  <DialogClose asChild key={i}>
                    <Star
                      onClick={() => {
                        const rating = maxRating - i;

                        revisionClient
                          .rate(revision, { rating })
                          .then(() => {
                            toast({
                              description: t('alerts:success.feedback_submitted', {
                                defaultValue: 'Thank you for your feedback!',
                              }),
                            });

                            onSuccess?.(rating);
                          })
                          .catch((err) => console.log('Failed to rate revision', err));
                      }}
                      className={
                        'tw-peer tw-size-9 tw-cursor-pointer tw-stroke-1 tw-transition-colors hover:tw-fill-purple-300 hover:tw-stroke-purple-500 peer-hover:tw-fill-purple-300 peer-hover:tw-stroke-purple-500'
                      }
                    />
                  </DialogClose>
                ))}
              </div>
            </div>
          </div>

          <DialogComposedClose />
        </RateRevisionComposedDialogContent>
      </RateRevisionComposedDialogPortal>
    </Dialog>
  );
};

export { RateRevisionComposedDialog };
