import {
  Options,
  useRequestFormStore,
} from 'src/features/requests/request-form/use-request-form-store';
import { useConfirm } from 'src/components/ConfirmModal';
import { useShallow } from 'zustand/react/shallow';
import { LocalStorage } from 'src/services/LocalStorage';

const useOpenRequestModal = () => {
  const { isMinimized, setCurrentRequestId, openModal, reset } = useRequestFormStore(
    useShallow(({ isMinimized, setCurrentRequestId, openModal, reset }) => ({
      isMinimized,
      setCurrentRequestId,
      openModal,
      reset,
    })),
  );
  const { confirm } = useConfirm();
  const openRequestModal = async (requestId?: string, options?: Options) => {
    if (!isMinimized) {
      LocalStorage.set('formValues', null);
      setCurrentRequestId(requestId);
      openModal(options);
      return;
    }

    const confirmed = await confirm('', {
      modal_header: {
        content: 'Draft request in progress',
      },
      modal_body: {
        content:
          'Looks like you already have a request form open. Opening a new one will discard your draft and any changes you’ve made won’t be saved.',
      },
      confirm: {
        text: 'Discard draft',
      },
      cancel: {
        text: 'Keep editing',
      },
    });

    // modal closed - cancel action and do nothing
    if (confirmed === null) {
      return;
    }

    if (confirmed) {
      reset();
      LocalStorage.set('formValues', null);
      setCurrentRequestId(requestId);
      openModal(options);
      return;
    }

    openModal();
  };

  return { openRequestModal };
};

export { useOpenRequestModal };
