import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { cookies } from './cookies';

type Props = React.PropsWithChildren;

const ReactCookiesProvider: React.FC<Props> = (props) => {
  return <CookiesProvider cookies={cookies}>{props.children}</CookiesProvider>;
};

export type { Props as ReactCookiesProviderProps };
export default ReactCookiesProvider;
