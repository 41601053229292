import { Client } from 'src/api/Client';
import {
  CancelSubscriptionRequest,
  CreateStripeCustomerRequest,
  CreateStripeCustomerResponse,
  CreateSubscriptionRequest,
  PromotionCodeResponse,
  StripeIntentResponse,
  StripePaymentIntentRequest,
  StripeProductsResponse,
  ValidatePromotionCodeRequest,
} from './SubscriptionClient.types';
import { AxiosRequestConfig } from 'axios';
import { url } from 'src/lib/utils';
import { SubscriptionObject } from 'src/lib/services/api/subscription-api';

class SubscriptionClient extends Client {
  public readonly Route = {
    FETCH_STRIPE_PRODUCTS: `${this.http.baseUrl}/stripe/products`,
    CREATE_STRIPE_CUSTOMER: `${this.http.baseUrl}/stripe/create-stripe-customer`,
    VALIDATE_PROMOTION_CODE: `${this.http.baseUrl}/stripe/validate-promotion-code`,
    CREATE_STRIPE_INTENT: `${this.http.baseUrl}/stripe/create-stripe-intent`,
    CREATE_PAYMENT: `${this.http.baseUrl}/stripe/create-payment`,
    ABORT_PAYMENT: `${this.http.baseUrl}/stripe/abort-payment`,
    CREATE_SUBSCRIPTION: `${this.http.baseUrl}/stripe/create-subscription`,
    cancel: `${this.http.baseUrl}/subscriptions/{model}/cancel`,
    reactivate: `${this.http.baseUrl}/subscriptions/{model}/reactivate`,
  };

  public fetchStripeProducts = (): Promise<StripeProductsResponse> => {
    return this.http.get(this.Route.FETCH_STRIPE_PRODUCTS);
  };

  public createStripeCustomer = (
    request: CreateStripeCustomerRequest,
  ): Promise<CreateStripeCustomerResponse> => {
    return this.http.post(this.Route.CREATE_STRIPE_CUSTOMER, request);
  };

  public createStripeIntent = (
    request: StripePaymentIntentRequest,
  ): Promise<StripeIntentResponse> => {
    return this.http.post(this.Route.CREATE_STRIPE_INTENT, request);
  };

  public createPayment = (request: StripePaymentIntentRequest): Promise<StripeIntentResponse> => {
    return this.http.post(this.Route.CREATE_PAYMENT, request);
  };

  public abortPayment = (paymentId: string): Promise<void> => {
    return this.http.post(this.Route.ABORT_PAYMENT, { payment_id: paymentId });
  };

  public validatePromotionCode = (
    request: ValidatePromotionCodeRequest,
  ): Promise<PromotionCodeResponse> => {
    return this.http.post(this.Route.VALIDATE_PROMOTION_CODE, request);
  };

  public createSubscription = (request: CreateSubscriptionRequest): Promise<SubscriptionObject> => {
    return this.http.post(this.Route.CREATE_SUBSCRIPTION, request);
  };

  cancel = (
    model: string,
    data: CancelSubscriptionRequest,
    config?: AxiosRequestConfig,
  ): Promise<SubscriptionObject> =>
    this.http.delete(url.insertArguments(this.Route.cancel, { model }), {
      data,
      ...(config ?? {}),
    });

  reactivate = (model: string, config?: AxiosRequestConfig): Promise<SubscriptionObject> =>
    this.http.post(url.insertArguments(this.Route.reactivate, { model }), {}, config);
}

export default SubscriptionClient;
